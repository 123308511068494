"use strict";
/**
 * This is a file to store constants that are shared by multiple packages but
 * that don't necessary need to be environment variables.
 *
 * IMPORTANT: The constants in this file must all be purely static values that
 * do not reference anything, even other constants. Otherwise the definition of
 * "constant" starts to drift and this file becomes harder to tree-shake
 * properly (especially important on the web).
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ERRORS = exports.APP_UI_ICONS = exports.AUDIO_FILE_FORMATS = exports.VIDEO_FILE_FORMATS = exports.IMAGE_FILE_FORMATS = exports.FILE_FORMATS = exports.WATERMELON_SYNC_TABLE_NAMES = exports.APP_MENU_LINK_LOCATIONS = exports.INTEGRATION_PROVIDER_METADATA = exports.DISPLAY_MODES = exports.BANNER_SIZES = exports.SEARCH_INDEX_OBJECT_TYPES = exports.CONTENT_TYPES = exports.SUBSCRIPTION_PROVIDERS = exports.INTEGRATION_PROVIDERS = exports.DEFAULT_LOCALE = exports.LOCALES = exports.SPLASH_SCREEN_FOREGROUND_IMAGE_RADIUS_MAX_DP = exports.GOOGLE_SITE_VERIFICATION_TOKEN_PATTERN = exports.CACHE_CONTROL_FOREVER = exports.APP_DESCRIPTION_MAX_LENGTH = exports.APP_TAGLINE_MAX_LENGTH = exports.APP_ICON_NATIVE_CLIP_PERCENT = exports.PROFILE_MAX_COUNT = exports.ANONYMOUS_USER_ID = exports.PROGRESS_VERSION = exports.PROGRESS_KEY_PREFIX = exports.TREEFORT_TENANT_ID = exports.LEGAL_DOC_TYPES = void 0;
exports.LEGAL_DOC_TYPES = [
    "privacyPolicy",
    "termsOfService",
    "cookiePolicy",
];
// The ID of the "Treefort" tenant where administrators live. We've ensured that
// this is constant accross all environments for convenience
exports.TREEFORT_TENANT_ID = "4a709518-a20f-4eba-a4fd-3d186b877bfe";
// These are used to prefix keys for progress data saved in the settings system
exports.PROGRESS_KEY_PREFIX = "progress";
exports.PROGRESS_VERSION = 2;
exports.ANONYMOUS_USER_ID = "anonymous";
// The maximum number of profiles that tenants may allow users to create.
// Tenants may choose a maximum below this value (see the profiles feature in
// the app manifest), but they cannot choose a maximum above this value. Note
// that this restricts the number of profiles that can be created _in addition_
// to the default/null "profile" (so 4 here means 4 profiles + the default/null
// "profile", or 5 total icons shown on the profiles page in the app).
exports.PROFILE_MAX_COUNT = 4;
// These numbers represent the clipping that various operating systems
// automatically apply to app icon imagery. Each value represents the percentage
// of the image that will be clipped along either its width or height (clipping
// is uniform along both dimensions). For example, a value of 0.2 means a clip
// of 20%, turning a 100px*50px image into a 80px*40px image.
exports.APP_ICON_NATIVE_CLIP_PERCENT = {
    ANDROID_ADAPTIVE: 0.389,
    ANDROID_PWA: 0.2,
    ANDROID_LEGACY: 0.084,
    ANDROID_LEGACY_ROUND: 0.07,
    IOS: 0,
    IOS_PWA: 0,
};
exports.APP_TAGLINE_MAX_LENGTH = 30;
exports.APP_DESCRIPTION_MAX_LENGTH = 4000;
exports.CACHE_CONTROL_FOREVER = "public, max-age=31536000, immutable";
exports.GOOGLE_SITE_VERIFICATION_TOKEN_PATTERN = "^[0-9a-zA-Z_\\-]+$";
// Android masks the foreground image in a circle with a radius of 96dp, so we
// have to do some work to make sure that our image fits within that.
// https://developer.android.com/develop/ui/views/launch/splash-screen
exports.SPLASH_SCREEN_FOREGROUND_IMAGE_RADIUS_MAX_DP = 96;
// All locales supported by the app. Locales will be shown in this order when
// presented to users.
exports.LOCALES = ["en_US", "de_DE", "es_MX", "fr_FR", "pt_BR"];
exports.DEFAULT_LOCALE = "en_US";
exports.INTEGRATION_PROVIDERS = [
    "appStore",
    "firebaseMessaging",
    "giftUp",
    "googleAnalytics",
    "metaPixel",
    "playStore",
    "stripe",
    "vimeo",
    "recombee",
    "groupMembership",
    "webhooks",
    "kafka",
    "singular",
    "webPayment",
    "googleSiteVerification"
];
exports.SUBSCRIPTION_PROVIDERS = [
    "appStore",
    "playStore",
    "stripe",
    "groupMembership",
    "webPayment"
];
exports.CONTENT_TYPES = [
    "book",
    "ebook",
    "podcast",
    "video",
    "videoSeries",
    "webEmbed",
    "album",
];
exports.SEARCH_INDEX_OBJECT_TYPES = [
    // Content types
    "book",
    "ebook",
    "podcast",
    "video",
    "videoSeries",
    "webEmbed",
    "album",
    // Other
    "albumTrack",
    "page",
    "collection",
    "contributor",
    "podcastEpisode"
];
exports.BANNER_SIZES = [
    { value: "large", label: "Large", heightAsFractionOfViewport: 0.65, maxHeight: 700 },
    { value: "medium", label: "Medium", heightAsFractionOfViewport: 0.5, maxHeight: 600 },
    { value: "small", label: "Small", heightAsFractionOfViewport: 0.4, maxHeight: 500 },
    { value: "fitToScreenWidth", label: "Fit to screen width" }
];
exports.DISPLAY_MODES = [
    { value: "auto", label: "Auto" },
    { value: "light", label: "Light" },
    { value: "dark", label: "Dark" }
];
exports.INTEGRATION_PROVIDER_METADATA = {
    appStore: { title: "App Store", deletable: false },
    firebaseMessaging: { title: "Firebase", deletable: false },
    giftUp: { title: "Gift Up", deletable: true },
    googleAnalytics: { title: "Google Analytics", deletable: false },
    metaPixel: { title: "Meta Pixel", deletable: true },
    playStore: { title: "Play Store", deletable: false },
    stripe: { title: "Stripe", deletable: false },
    vimeo: { title: "Vimeo", deletable: true },
    recombee: { title: "Recombee", deletable: false },
    groupMembership: { title: "Group Membership", deletable: false },
    webhooks: { title: "Webhooks", deletable: false },
    kafka: { title: "Kafka", deletable: true },
    singular: { title: "Singular", deletable: true },
    webPayment: { title: "Web Payment", deletable: false },
    googleSiteVerification: { title: "Google Site Verification", deletable: true }
};
exports.APP_MENU_LINK_LOCATIONS = [
    { id: "desktopFooter", title: "Desktop footer" },
    { id: "desktopMenu", title: "Desktop menu" },
    { id: "desktopAbout", title: "Desktop about page" },
    { id: "mobileMenu", title: "Mobile menu" },
    { id: "mobileAbout", title: "Mobile about page" },
];
exports.WATERMELON_SYNC_TABLE_NAMES = ["Highlight", "Progress"];
exports.FILE_FORMATS = [
    {
        name: "png",
        extensions: ["png"],
        contentTypes: ["image/png"],
    },
    {
        name: "jpeg",
        extensions: ["jpg", "jpeg"],
        contentTypes: ["image/jpg", "image/jpeg"],
    },
    // NOTE: This really should have been split into two separate formats, mp4 for
    // video and m4a for audio. If we ever get a chance to rectify this we
    // should...
    {
        name: "mp4",
        extensions: ["mp4", "m4a"],
        contentTypes: ["video/mp4", "audio/mp4"],
    },
    {
        name: "ogv",
        extensions: ["ogv"],
        contentTypes: ["video/ogg"],
    },
    {
        name: "oga",
        extensions: ["oga", "ogg"],
        contentTypes: ["audio/ogg"],
    },
    {
        name: "hls",
        extensions: ["m3u8"],
        contentTypes: ["application/x-mpegurl", "application/vnd.apple.mpegurl"],
    },
    {
        name: "mov",
        extensions: ["mov"],
        contentTypes: ["video/quicktime"],
    },
    {
        name: "m4v",
        extensions: ["m4v"],
        contentTypes: ["video/x-m4v"],
    },
    {
        name: "mp3",
        extensions: ["mp3"],
        contentTypes: ["audio/mpeg"],
    },
    {
        name: "flac",
        extensions: ["flac"],
        contentTypes: ["audio/flac"],
    },
];
exports.IMAGE_FILE_FORMATS = ["png", "jpeg"];
exports.VIDEO_FILE_FORMATS = ["mp4", "hls", "mov", "m4v", "ogv"];
exports.AUDIO_FILE_FORMATS = ["mp4", "hls", "mp3", "flac", "oga"];
// All of the user interface icons that can be customized in the app
exports.APP_UI_ICONS = [
    {
        title: "Back",
        description: "Shown in the app header when a user can navigate back.",
        manifestField: "back",
        appField: "iconBack",
        defaultValue: '<svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M42.8404 51.7384L78 88.872L71.7465 95.4767L30.3333 51.7384L71.7465 8.00005L78 14.6047L42.8404 51.7384Z" fill="black"/></svg>',
    },
    {
        title: "Close",
        description: "Shown in situations when a user can close or remove an element.",
        manifestField: "close",
        appField: "iconClose",
        defaultValue: '<svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M59.077 52L86.6667 79.5897L79.5898 86.6667L52.0001 59.077L24.4104 86.6667L17.3334 79.5897L44.9231 52L17.3334 24.4103L24.4104 17.3334L52.0001 44.9231L79.5898 17.3334L86.6667 24.4103L59.077 52Z" fill="black"/></svg>',
    },
    {
        title: "Menu",
        description: "Shown in the app header when a user can open the app menu.",
        manifestField: "menu",
        appField: "iconMenu",
        defaultValue: '<svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M51.5833 97C26.96 97 7 76.8535 7 52C7 27.1465 26.96 7 51.5833 7C76.2067 7 96.1667 27.1465 96.1667 52C96.1667 76.8535 76.2067 97 51.5833 97ZM29.3496 80.152C35.6606 85.2446 43.5034 88.013 51.5833 88C60.3663 88 68.4046 84.796 74.6195 79.486C71.7159 76.4789 68.2444 74.09 64.4101 72.4606C60.5759 70.8313 56.4569 69.9946 52.2967 70C47.9836 69.995 43.7166 70.8948 39.7664 72.6425C35.8161 74.3901 32.2689 76.9474 29.3496 80.152ZM23.1213 73.69C26.8684 69.6763 31.3901 66.4797 36.4078 64.2972C41.4255 62.1147 46.8329 60.9926 52.2967 61C57.5649 60.9931 62.7825 62.0366 67.65 64.0707C72.5176 66.1048 76.939 69.0894 80.6606 72.853C84.4773 67.4311 86.7312 61.0479 87.1723 54.4116C87.6134 47.7753 86.2244 41.1455 83.1594 35.2575C80.0945 29.3696 75.4734 24.4538 69.8089 21.0555C64.1443 17.6572 57.6578 15.9093 51.0689 16.0056C44.48 16.102 38.0463 18.0389 32.4817 21.6014C26.9171 25.1639 22.439 30.2128 19.5444 36.1879C16.6497 42.163 15.4516 48.8307 16.083 55.4513C16.7144 62.0718 19.1506 68.3819 23.1213 73.69ZM51.5833 56.5C46.8536 56.5 42.3177 54.6036 38.9733 51.2279C35.6289 47.8523 33.75 43.2739 33.75 38.5C33.75 33.7261 35.6289 29.1477 38.9733 25.7721C42.3177 22.3964 46.8536 20.5 51.5833 20.5C56.313 20.5 60.849 22.3964 64.1934 25.7721C67.5378 29.1477 69.4167 33.7261 69.4167 38.5C69.4167 43.2739 67.5378 47.8523 64.1934 51.2279C60.849 54.6036 56.313 56.5 51.5833 56.5ZM51.5833 47.5C53.9482 47.5 56.2162 46.5518 57.8884 44.864C59.5606 43.1761 60.5 40.887 60.5 38.5C60.5 36.1131 59.5606 33.8239 57.8884 32.136C56.2162 30.4482 53.9482 29.5 51.5833 29.5C49.2185 29.5 46.9505 30.4482 45.2783 32.136C43.6061 33.8239 42.6667 36.1131 42.6667 38.5C42.6667 40.887 43.6061 43.1761 45.2783 44.864C46.9505 46.5518 49.2185 47.5 51.5833 47.5Z" fill="black"/></svg>',
    },
    {
        title: "Search",
        description: "Shown in the app header when a user can search.",
        manifestField: "search",
        appField: "iconSearch",
        defaultValue: '<svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M74.5545 69.1251L91 85.5668L85.5668 91L69.1251 74.5545C63.0075 79.4587 55.3982 82.1261 47.5575 82.1149C28.4817 82.1149 13 66.6332 13 47.5575C13 28.4817 28.4817 13 47.5575 13C66.6332 13 82.1149 28.4817 82.1149 47.5575C82.1261 55.3982 79.4587 63.0075 74.5545 69.1251ZM66.852 66.2761C71.7251 61.2648 74.4465 54.5474 74.4355 47.5575C74.4355 32.7054 62.4056 20.6794 47.5575 20.6794C32.7054 20.6794 20.6794 32.7054 20.6794 47.5575C20.6794 62.4056 32.7054 74.4355 47.5575 74.4355C54.5474 74.4465 61.2648 71.7251 66.2761 66.852L66.852 66.2761V66.2761Z" fill="black" stroke="black" stroke-width="0.25"/></svg>',
    },
    {
        title: "Share",
        description: "Shown when a user can open the share dialog.",
        manifestField: "share",
        appField: "iconShare",
        defaultValue: '<svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M56.3517 18.2546V57.6666H47.685V18.2546L39.3773 26.5666L33.25 20.4393L52.0183 1.66663L70.7867 20.435L64.6593 26.5623L56.3517 18.2633V18.2546Z" fill="black"/><path d="M25.5 48.5V85.5H78.5V48.5H71V40H82C84.7614 40 87 42.2386 87 45V89C87 91.7614 84.7614 94 82 94H22C19.2386 94 17 91.7614 17 89V45C17 42.2386 19.2386 40 22 40H33V48.5H25.5Z" fill="black"/></svg>',
    },
    {
        title: "Shop",
        description: "Shown when a user can open the shop.",
        manifestField: "shop",
        appField: "iconShop",
        defaultValue: '<svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_6345_7630)"><path d="M52 4.33337C54.8453 4.33337 57.6628 4.8938 60.2915 5.98265C62.9202 7.0715 65.3087 8.66746 67.3206 10.6794C69.3326 12.6913 70.9285 15.0798 72.0174 17.7086C73.1062 20.3373 73.6667 23.1547 73.6667 26V34.6667H86.6667C87.8159 34.6667 88.9181 35.1233 89.7308 35.9359C90.5435 36.7486 91 37.8508 91 39V91C91 92.1493 90.5435 93.2515 89.7308 94.0642C88.9181 94.8768 87.8159 95.3334 86.6667 95.3334H17.3333C16.1841 95.3334 15.0819 94.8768 14.2692 94.0642C13.4565 93.2515 13 92.1493 13 91V39C13 37.8508 13.4565 36.7486 14.2692 35.9359C15.0819 35.1233 16.1841 34.6667 17.3333 34.6667H30.3333V26C30.3333 20.2537 32.6161 14.7427 36.6794 10.6794C40.7426 6.61611 46.2536 4.33337 52 4.33337V4.33337ZM73.6667 47.6667H65V52C65.0012 53.1045 65.4241 54.1668 66.1823 54.97C66.9405 55.7731 67.9768 56.2564 69.0794 56.3211C70.182 56.3859 71.2676 56.0271 72.1146 55.3182C72.9616 54.6093 73.5059 53.6038 73.6363 52.507L73.6667 52V47.6667ZM39 47.6667H30.3333V52C30.3346 53.1045 30.7575 54.1668 31.5157 54.97C32.2739 55.7731 33.3101 56.2564 34.4127 56.3211C35.5153 56.3859 36.601 56.0271 37.4479 55.3182C38.2949 54.6093 38.8392 53.6038 38.9697 52.507L39 52V47.6667ZM52 13C48.6841 12.9999 45.4934 14.2668 43.0809 16.5416C40.6683 18.8164 39.2162 21.9272 39.0217 25.2374L39 26V34.6667H65V26C65.0002 22.6841 63.7333 19.4935 61.4584 17.0809C59.1836 14.6683 56.0729 13.2162 52.7627 13.0217L52 13Z" fill="black"/></g><defs><clipPath id="clip0_6345_7630"><rect width="104" height="104" fill="white"/></clipPath></defs></svg>',
    },
];
// All Treefort errors
exports.ERRORS = {
    /**
     * General errors
     */
    TF0000: {
        title: "Unknown error",
        message: "We encountered an unknown error. If this persists please contact us.",
        properties: {},
    },
    /**
     * App manifest errors
     */
    TF4101: {
        title: "Broken content links",
        message: "Your app contains links to content items that are not published. Please publish the content items or remove the links.",
        properties: {
            content: {
                type: "array",
                items: {
                    type: "object",
                    properties: {
                        id: { type: "number" },
                        title: { type: "string" },
                    },
                    required: ["id", "title"],
                },
            },
        },
    },
    TF4102: {
        title: "Broken collection links",
        message: "Your app contains links to collections that are not published. Please publish the collections or remove the links.",
        properties: {
            collections: {
                type: "array",
                items: {
                    type: "object",
                    properties: {
                        id: { type: "number" },
                        title: { type: "string" },
                    },
                    required: ["id", "title"],
                },
            },
        },
    },
    TF4103: {
        title: "Missing tabs",
        message: "Please add at least one tab to your app before publishing.",
        properties: {},
    },
    TF4104: {
        title: "Recombee integration required",
        message: "Please setup a Recombee integration before enabling the Content Page Recommendations setting.",
        properties: {},
    },
    TF4105: {
        title: "Library without users",
        message: "You have disabled user accounts but included a library tab in your app. The library feature requires user accounts. Please remove the library tab or enable user accounts.",
        properties: {},
    },
    TF4106: {
        title: "Tabs with reserved terms",
        message: "Some of your tab names conflict with terms that are reserved by Treefort.",
        properties: { terms: { type: "array", items: { type: "string" } } },
    },
    TF4107: {
        title: "Pages with reserved terms",
        message: "Some of your page names conflict with terms that are reserved by Treefort.",
        properties: { terms: { type: "array", items: { type: "string" } } },
    },
    TF4108: {
        title: "Custom paths with reserved terms",
        message: "Some of your custom paths conflict with terms that are reserved by Treefort.",
        properties: { terms: { type: "array", items: { type: "string" } } },
    },
    TF4109: {
        title: "Too many pages",
        message: "You have exceeded the maximum number of pages. Contact us if the current limit is insufficient.",
        properties: { limit: { type: "number" }, pageCount: { type: "number" } },
    },
    TF4110: {
        title: "Too many modules",
        message: "You have exceeded the maximum number of modules per page. Contact us if the current limit is insufficient.",
        properties: {
            limit: { type: "number" },
            pages: {
                type: "array",
                items: {
                    type: "object",
                    properties: {
                        id: { type: "number" },
                        title: { type: "string" },
                        moduleCount: { type: "number" },
                    },
                    required: ["id", "title", "moduleCount"],
                },
            },
        },
    },
    TF4111: {
        title: "Not enough profile images",
        message: "For the profiles feature to be enabled you must upload at least 5 profile images for your users to choose from.",
        properties: {},
    },
    TF4112: {
        title: "Invalid tab icon",
        message: "The SVG image is incorrectly formatted.",
        properties: {
            tabId: { type: "number" },
            tabTitle: { type: "string" },
        },
    },
    TF4113: {
        title: "Invalid user interface icon",
        message: "The SVG image for a user interface icon is incorrectly formatted.",
        properties: {
            iconName: { type: "string" },
        },
    },
    /**
     * User errors
     */
    // NOTE: If the message property exists in our i18n config then it can be
    // shown in the app with our getTreefortMessageFromError function. Keep i18n
    // in mind when editing the error messages below or adding new errors.
    TF4201: {
        title: "Duplicate profile name",
        message: "Profile names must be unique.",
        properties: { duplicateName: { type: "string" } },
    },
    TF4202: {
        title: "Too many profiles",
        message: "You have exceeded the maximum number of profiles.",
        properties: { limit: { type: "number" } },
    },
    TF4203: {
        title: "Invalid locale",
        message: "The requested locale is not available. Please contact the app owner.",
        properties: {},
    },
    TF4204: {
        title: "Invalid promo code",
        message: "Invalid code.",
        properties: {}
    }
};
