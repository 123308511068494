import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useAuth } from "@treefort/lib/auth-provider"
import icons from "@treefort/tokens/app/icons"

import { AsyncButton } from "../../../components/async-button"
import Spacer from "../../../components/spacer"
import Text from "../../../components/text"
import { useAsyncViewPropsForQueries } from "../../../hooks/use-async-view-props-for-queries"
import { useNavigate } from "../../../hooks/use-navigate"
import { useRoute } from "../../../hooks/use-route"
import useUserInfo from "../../../hooks/use-user-info"
import authenticator from "../../../lib/authenticator"
import { unsetQueryParam } from "../../../lib/location"
import { toast } from "../../../lib/toaster"
import { sendVerificationEmail, verifyEmail } from "../../../lib/verify-email"
import MenuLayout from "../../layouts/menu"

const USER_DATA_POLL_INTERVAL = 2000

function VerifyEmailScreen(): JSX.Element {
  const { params } = useRoute()
  const [state, setState] = useState<
    "idle" | "sendingEmail" | "verifyingEmail" | "emailVerified"
  >(params.code ? "verifyingEmail" : "idle")
  const [pollUserInfo, setPollUserInfo] = useState<boolean>(true)
  const auth = useAuth()
  const unauthenticated = !auth.loading && auth.user === null
  const userInfo = useUserInfo({
    refetchInterval: pollUserInfo ? USER_DATA_POLL_INTERVAL : undefined,
  })
  const navigate = useNavigate()
  const refetchUserInfo = userInfo.refetch

  const viewAccount = useCallback(() => navigate("/menu/account"), [navigate])

  const { t } = useTranslation()

  // Finish the verification process if the user was sent here from a
  // verification email (indicated by the presence of a code parameter
  // in the query string)
  useEffect(() => {
    if (params.code) {
      // Drop the code parameter immediately so we don't try to verify it more
      // than once
      unsetQueryParam("code")

      // Verify the email. On success we refetch user info to confirm the email
      // has been verified. On failure we notify the user that something went
      // wrong.
      verifyEmail(params.code)
        .then(() => {
          setState("emailVerified")
          toast.success(t("Your email has been verified. Thank you!"))
        })
        .catch(() => {
          setState("idle")
          toast.error(
            t("Something went wrong. Please request a new verification email."),
          )
        })
    }
  }, [params.code, refetchUserInfo, t])

  // Watch the userInfo for changes to their verification status, notifying them
  // via toast if their email is successfully verified.
  useEffect(() => {
    // Stop showing a loading state for the verification request once the email
    // is verified
    if (userInfo.data?.emailVerified) {
      setState("emailVerified")
      setPollUserInfo(false)
    }
  }, [userInfo.data])

  const asyncViewProps = useAsyncViewPropsForQueries(userInfo, {
    forceLoading: state === "verifyingEmail",
  })

  if (state === "emailVerified" && unauthenticated) {
    return (
      <MenuLayout asyncViewProps={asyncViewProps}>
        <Text color="secondary" textStyle="body">
          {t("Your email has been verified. Thank you!")}
        </Text>
        <Spacer size="medium" />
        <AsyncButton id="email-verified-sign-in" onPress={authenticator.login}>
          {t("Sign In")}
        </AsyncButton>
      </MenuLayout>
    )
  }

  if (state === "emailVerified" && userInfo.isSuccess) {
    return (
      <MenuLayout asyncViewProps={asyncViewProps}>
        <Text color="secondary" textStyle="body">
          {t("Your email {{email}} has been verified. Thank you!", {
            email: userInfo.data.email,
          })}
        </Text>
        <Spacer size="medium" />
        <AsyncButton id="email-verified-view-account" onPress={viewAccount}>
          {t("View account")}
        </AsyncButton>
      </MenuLayout>
    )
  }

  if (state !== "emailVerified" && userInfo.isSuccess) {
    return (
      <MenuLayout asyncViewProps={asyncViewProps}>
        <Text color="secondary" textStyle="body">
          {t(
            "We sent an email to {{email}} that includes instructions for verifying your email",
            { email: userInfo.data?.email },
          )}
        </Text>
        <Spacer size="small" />
        <Text color="secondary" textStyle="body">
          {t(
            "If you can't find an email from us, tap the button below and we'll send another.",
          )}
        </Text>
        <Spacer size="large" />
        <AsyncButton
          id="email-unverified-resend-link"
          icon={icons.mailCheck}
          onPress={async () => {
            if (state !== "sendingEmail") {
              setState("sendingEmail")
              try {
                await sendVerificationEmail()
                toast.success(
                  t("Email sent! It should arrive in your inbox shortly."),
                )
              } catch (error) {
                toast.error(
                  t("Failed to send verification email. Please try again."),
                )
              } finally {
                setState("idle")
              }
            }
          }}
          type="primary"
        >
          {t("Re-send Verification Email")}
        </AsyncButton>
      </MenuLayout>
    )
  }

  if (state !== "emailVerified" && unauthenticated) {
    return (
      <MenuLayout asyncViewProps={asyncViewProps}>
        <Text color="secondary" textStyle="body">
          {t(
            "We sent an email to the address you signed up with that includes instructions for verifying your email. Please check your inbox.",
          )}
        </Text>
      </MenuLayout>
    )
  }

  return <MenuLayout asyncViewProps={{ state: "loading" }} />
}

export default VerifyEmailScreen
