import { debug as appDebug } from "../../../lib/logging"
import { getClient } from "../../db"
import { SourceAwareModel } from "../../models/source-aware-model"
import { syncManager } from "../../sync"
import { Finder } from "./finder"

const debug = appDebug.extend("sync:behaviors:deleter")

export class Deleter<TModel extends SourceAwareModel> {
  private finder: Finder<TModel>

  constructor(finder: Finder<TModel>) {
    this.finder = finder
  }

  async delete(id: string) {
    await getClient().write(async () => {
      const model = await this.finder.find(id)

      if (model) {
        debug("Marking model deleted", { id })
        await model.markAsDeleted()
      } else {
        debug("No matching model found for id", { id })
      }
    })

    await syncManager.requestSync({ syncType: "periodic" })
  }
}
