import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import {
  DimensionValue,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleSheet,
  ViewProps,
} from "react-native"
import { ScrollView } from "react-native-gesture-handler"

import styled from "styled-components/native"

import { useWillUnmount } from "@treefort/lib/use-will-unmount"
import rawTokens from "@treefort/tokens/app"

import { getBannerItemHeight } from "./banner-item"
import Box from "./box"
import Touchable from "./touchable"

// How long we disable autoplay after the user interacts with the banner (in
// milliseconds)
const DISABLE_AUTOPLAY_AFTER_INTERACTION_DURATION = 30000

// How long we show each slide in autoplay mode
const AUTOPLAY_SLIDE_DURATION = 10000

const scrollOffsets = new Map<
  string,
  { x: number; y: number; animated: false }
>()

const { scrollViewStyle } = StyleSheet.create({
  scrollViewStyle: {
    // Use a dark background for the scroll view. This color will show up when
    // the user over-scrolls to the left or right.
    backgroundColor: rawTokens.colors.background.primary.dark,
  },
})

const Container = styled(Box)`
  position: relative;
  overflow: hidden;
`

const ScrollIndicatorView = styled.View<{ insetBottom: number }>`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: ${(props) => props.insetBottom}px;
  padding-horizontal: ${(props) =>
    props.theme.bannerModule.scrollIndicator.container.paddingHorizontal}px;
  height: ${(props) =>
    props.theme.bannerModule.scrollIndicator.container.height}px;
`

const PillContainer = styled.View`
  height: 100%;
  min-width: ${(props) =>
    props.theme.bannerModule.scrollIndicator.pill.minWidth}px;
  flex-grow: 1;
  flex-basis: 0;
  max-width: ${(props) =>
    props.theme.bannerModule.scrollIndicator.pill.maxWidth}px;
`

const PillTouchable = styled(Touchable)`
  height: 100%;
  width: 100%;
  justify-content: center;
  padding-horizontal: ${(props) =>
    props.theme.bannerModule.scrollIndicator.pill.marginHorizontal}px;
`

const PillBorder = styled.View`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: ${(props) =>
    props.theme.bannerModule.scrollIndicator.pill.borderRadius}px;
`

const Pill = styled.View<{ active: boolean }>`
  border-radius: ${(props) =>
    props.theme.bannerModule.scrollIndicator.pill.borderRadius}px;
  height: ${(props) => props.theme.bannerModule.scrollIndicator.pill.height}px;
  background-color: ${(props) =>
    props.theme.bannerModule.scrollIndicator.pill.backgroundColor[
      props.active ? "active" : "inactive"
    ]};
`

export const getBannerHeight = getBannerItemHeight

export default function Banner({
  renderItems,
  autoplay,
  width,
  scrollIndicatorInsetBottom = 0,
  height,
  scrollRestorationKey,
  paddingTop,
  ...rest
}: {
  renderItems: (itemWidth: number) => ReactNode[]
  autoplay?: boolean
  width?: number
  scrollIndicatorInsetBottom?: number
  height: DimensionValue
  scrollRestorationKey?: string
  paddingTop?: number
} & ViewProps): JSX.Element {
  const autoplayInterval = useRef<unknown>()
  const suspendAutoplayTimeout = useRef<unknown>()
  const [intrinsicWidth, setIntrinsicWidth] = useState<number | undefined>()
  const itemWidth = width || intrinsicWidth
  const items = itemWidth ? renderItems(itemWidth) : []
  const itemCount = items?.length
  const [currentIndex, setCurrentIndex] = useState(0)
  const scrollViewEl = useRef<ScrollView>(null)
  const [autoplaySuspended, setAutoplaySuspended] = useState(false)
  const contentContainerStyle = useMemo(
    () => ({
      height:
        paddingTop && typeof height === "number" ? height - paddingTop : height,
      width: itemWidth && itemWidth * itemCount,
    }),
    [height, itemWidth, itemCount, paddingTop],
  )
  const willUnmount = useWillUnmount()

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      if (!willUnmount.current) {
        setIntrinsicWidth(event.nativeEvent.layout.width)
      }
    },
    [willUnmount],
  )

  // Disable autoplay as soon as the user starts interacting with the scroll
  // view
  const suspendAutoplay = useCallback(() => {
    if (autoplay) {
      setAutoplaySuspended(true)
    }
  }, [autoplay])

  // Set a timer to resume autoplay as soon as the user stops interacting
  // with the scroll view
  const setResumeAutoplayTimeout = useCallback(() => {
    if (autoplay) {
      clearTimeout(suspendAutoplayTimeout.current as number)
      // Note that this timeout is cleared when the component unmounts by a
      // useEffect call below so it's safe to update state after the timeout is
      // complete.
      suspendAutoplayTimeout.current = setTimeout(
        () => setAutoplaySuspended(false),
        DISABLE_AUTOPLAY_AFTER_INTERACTION_DURATION,
      )
    }
  }, [autoplay])

  const onScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (itemWidth) {
        // Keep track of the current index
        const offset = event.nativeEvent.contentOffset.x
        const index = Math.floor((offset + itemWidth / 2) / itemWidth)
        setCurrentIndex(index)

        // Save the scroll offset any time we settle on a new item
        if (scrollRestorationKey && offset % itemWidth === 0) {
          scrollOffsets.set(scrollRestorationKey, {
            x: event.nativeEvent.contentOffset.x,
            y: 0,
            animated: false,
          })
        }
      }
    },
    [itemWidth, scrollRestorationKey],
  )

  // Scroll to an item when the user presses a pill
  const onPressPill = useCallback(
    (index: number) => {
      if (itemWidth) {
        scrollViewEl.current?.scrollTo({
          x: itemWidth * index,
          y: 0,
        })
        suspendAutoplay()
        setResumeAutoplayTimeout()
      }
    },
    [itemWidth, setResumeAutoplayTimeout, suspendAutoplay],
  )

  // Autoplay through slides
  useEffect(() => {
    if (autoplay && !autoplaySuspended && itemCount > 1 && itemWidth) {
      clearInterval(autoplayInterval.current as number)
      autoplayInterval.current = setInterval(() => {
        const nextInterval =
          currentIndex + 1 === itemCount ? 0 : currentIndex + 1
        scrollViewEl.current?.scrollTo({
          x: itemWidth * nextInterval,
          y: 0,
        })
      }, AUTOPLAY_SLIDE_DURATION)
      return () => clearInterval(autoplayInterval.current as number)
    }
  }, [autoplay, itemCount, autoplaySuspended, itemWidth, currentIndex])

  // Clear the timeout from the onResponderRelease callback
  useEffect(() => {
    return () => clearTimeout(suspendAutoplayTimeout.current as number)
  }, [])

  // Restore the previous scroll offset
  useEffect(() => {
    if (scrollRestorationKey) {
      scrollViewEl.current?.scrollTo(scrollOffsets.get(scrollRestorationKey))
    }
  }, [scrollRestorationKey])

  return (
    <Container
      {...rest}
      paddingTop={paddingTop}
      onLayout={width ? undefined : onLayout}
    >
      {itemCount > 1 ? (
        <>
          <ScrollView
            ref={scrollViewEl}
            contentContainerStyle={contentContainerStyle}
            scrollEventThrottle={200}
            decelerationRate="normal"
            pagingEnabled={true}
            onResponderGrant={suspendAutoplay}
            onResponderRelease={setResumeAutoplayTimeout}
            onScroll={onScroll}
            horizontal
            showsHorizontalScrollIndicator={false}
            style={scrollViewStyle}
          >
            {items}
          </ScrollView>
          <ScrollIndicatorView
            insetBottom={scrollIndicatorInsetBottom}
            pointerEvents="box-none"
          >
            {items.map((_item, index) => (
              <PillContainer key={index}>
                <PillTouchable id={null} onPress={() => onPressPill(index)}>
                  <PillBorder>
                    <Pill active={index == currentIndex} />
                  </PillBorder>
                </PillTouchable>
              </PillContainer>
            ))}
          </ScrollIndicatorView>
        </>
      ) : (
        items[0]
      )}
    </Container>
  )
}
