import React from "react"

import { MediaEpubSection } from "@treefort/api-spec"

import {
  findTableOfContentsSection,
  getTableOfContentsOptions,
} from "../lib/epub"
import { kebabCase } from "../lib/kebab-case"
import Select from "./select"

export function EbookTableOfContentsSelect({
  label,
  open,
  onClose,
  onChange,
  sectionId,
  tableOfContents,
}: {
  label: string
  open: boolean
  onClose: () => void
  onChange: (section: MediaEpubSection) => void
  sectionId: string | undefined
  tableOfContents: MediaEpubSection[]
}): JSX.Element | null {
  return (
    <Select
      id={kebabCase(`ebook-toc-select-${label}`)}
      label={label}
      open={open}
      onClose={onClose}
      value={sectionId}
      onChange={(sectionId: string) => {
        const section = findTableOfContentsSection(tableOfContents, sectionId)
        if (section) {
          onChange(section)
        }
      }}
      options={getTableOfContentsOptions(tableOfContents)}
    />
  )
}
