import React, { ReactNode, useCallback } from "react"
import {
  Pressable,
  PressableProps,
  PressableStateCallbackType,
  StyleSheet,
  ViewStyle,
} from "react-native"

import analytics from "../../lib/analytics"
import { useTokens } from "../tokens-provider"
import { TouchablePropsExtra } from "./types"

const { cursorPointer, cursorDefault, userSelectNone, opacityTransition } =
  StyleSheet.create({
    cursorPointer: {
      cursor: "pointer",
    } as ViewStyle,
    cursorDefault: {
      cursor: "default",
      // HACK the cursor value "default" is only available on the web and hence
      // not in react-native's ViewStyle types.
    } as unknown as ViewStyle,
    userSelectNone: {
      userSelect: "none",
    } as ViewStyle,
    opacityTransition: {
      transitionProperty: "opacity",
      transitionDuration: "0.15s",
    } as ViewStyle,
  })

export type TouchableProps = PressableProps & TouchablePropsExtra

function TouchableHighlight(props: TouchableProps) {
  const getStyle = useCallback(
    (state: PressableStateCallbackType) => {
      return [
        props.style,
        state.pressed && props.underlayColor
          ? { backgroundColor: props.underlayColor }
          : undefined,
      ]
    },
    [props.style, props.underlayColor],
  )
  return <Pressable {...props} style={getStyle} />
}

function TouchableOpacity({ activeOpacity = 0.2, ...props }: TouchableProps) {
  const getStyle = useCallback(
    (state: PressableStateCallbackType) => {
      return [
        props.style,
        opacityTransition,
        state.pressed && activeOpacity !== undefined
          ? { opacity: activeOpacity }
          : undefined,
      ]
    },
    [props.style, activeOpacity],
  )
  return <Pressable {...props} style={getStyle} />
}

function TouchableHighlightDefaultUnderlayColor(
  props: Omit<Parameters<typeof TouchableHighlight>[0], "underlayColor"> & {
    children?: ReactNode
  },
): JSX.Element {
  const { tokens } = useTokens()
  return (
    <TouchableHighlight
      {...props}
      underlayColor={tokens.colors.feedback.underlay}
    />
  )
}

/**
 * A handy-dandy wrapper around react-native-web's touchable elements that
 * allows easy feedback selection.
 *
 * The react-native-gesture-handler Touchable components do technically
 * support the web, but the selling point of the library ("native-driven gesture
 * management") is moot and there are additional bugs to contend with:
 * - https://github.com/software-mansion/react-native-gesture-handler/issues/607
 * - https://github.com/software-mansion/react-native-gesture-handler/issues/1236
 * - the component doesn't work well with taps on a trackpad (although it
 *   handles clicks just fine)
 *
 * TL;DR just stick with react-native-web's Touchables which work just
 * fine.
 */
export default function Touchable({
  id,
  style,
  children,
  feedback = "none",
  underlayColor,
  activeOpacity,
  cursor = "pointer",
  // These props are used on native but ignored on the web
  containerStyle: _containerStyle,
  innerStyle: _innerStyle,
  ...props
}: { id: string } & TouchableProps): JSX.Element {
  const styles = [
    cursor === "pointer" && !props.disabled ? cursorPointer : cursorDefault,
    userSelectNone,
    style,
  ]
  switch (feedback) {
    case "none":
    case "ripple-or-none":
      return (
        <Pressable
          {...props}
          onPress={(e) => {
            // Log analytics tf_on_press
            analytics.logPress(id)

            if (props.onPress) {
              props.onPress(e)
            }
          }}
          style={styles}
        >
          {children}
        </Pressable>
      )
    case "ripple-or-highlight":
    case "highlight":
      return React.createElement(
        underlayColor
          ? TouchableHighlight
          : TouchableHighlightDefaultUnderlayColor,
        {
          ...props,
          id,
          onPress: (e) => {
            // Log analytics tf_on_press
            analytics.logPress(id)

            if (props.onPress) {
              props.onPress(e)
            }
          },
          style: styles,
          underlayColor,
          activeOpacity,
        },
        children,
      )
    case "ripple-or-opacity":
    case "opacity":
      return (
        <TouchableOpacity
          {...props}
          id={id}
          onPress={(e) => {
            analytics.logPress(id)

            if (props.onPress) {
              props.onPress(e)
            }
          }}
          style={styles}
          activeOpacity={activeOpacity}
        >
          {children}
        </TouchableOpacity>
      )
  }
}
