import React from "react"

import { AppModuleBanner } from "@treefort/api-spec"

import {
  AppHeaderPresentation,
  AppHeaderState,
} from "../../../../components/app-header/types"
import {
  BannerModule,
  getBannerHeight,
} from "../../../../components/modules/banner"
import { ListViewModule, ItemArgs } from "../list-view-module"

type Item = AppModuleBanner["config"]

export class BannerListViewModule
  implements ListViewModule<AppModuleBanner, Item>
{
  readonly appModule: AppModuleBanner
  readonly appHeaderPresentation: AppHeaderPresentation
  readonly appHeaderState: AppHeaderState | undefined
  readonly gapSize = 0
  readonly maxWidth = undefined

  constructor(appModule: AppModuleBanner) {
    this.appModule = appModule
    this.appHeaderPresentation =
      appModule.config.size === "fitToScreenWidth" ? "opaque" : "transparent"
    this.appHeaderState =
      appModule.config.size === "fitToScreenWidth" ? "collapsed" : undefined
  }

  getItems = () => [this.appModule.config]

  renderItem = (args: ItemArgs<Item>) => (
    <BannerModule
      {...args.item}
      height={this.getItemSize(args)}
      scrollRestorationKey={this.getItemKey(args)}
      paddingTop={
        this.appModule.config.size === "fitToScreenWidth"
          ? args.safeAreaInsets.top
          : 0
      }
    />
  )

  getItemKey = ({ pageKey, indexInPage }: ItemArgs<Item>) =>
    pageKey + indexInPage + this.appModule.id

  getItemSize = (args: ItemArgs<Item>) =>
    getBannerHeight({
      tokens: args.tokens,
      viewportHeight: args.displayHeight - args.safeAreaInsets.top,
      viewportWidth: args.displayWidth,
      size: this.appModule.config.size,
      items: this.appModule.config.items,
    }) + args.safeAreaInsets.top
}
