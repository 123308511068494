import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"
import { ScrollView } from "react-native-gesture-handler"

import styled from "styled-components/native"

import { useAuth } from "@treefort/lib/auth-provider"
import { ResolvedTokens } from "@treefort/tokens/app"

import config from "../../config"
import { useEntitlements } from "../../hooks/use-entitlements"
import { stringKeyLookup } from "../../lib/i18n/string-key-lookup"
import { getAbsoluteLineHeight } from "../../lib/text-style"
import CenteredContent from "../centered-content"
import { Heading } from "../heading"
import Row from "../row"
import Spacer from "../spacer"
import { Tokens, useTokens } from "../tokens-provider"
import Touchable from "../touchable"

export const LIBRARY_TABS = [
  "inProgress",
  "notStarted",
  "downloaded",
  "purchased",
  "finished",
] as const

export type LibraryTab = (typeof LIBRARY_TABS)[number]

const Container = styled.View`
  position: relative;
`

const Tab = styled(Touchable)`
  position: relative;
`

const TabUnderline = styled.View<{ current: boolean }>`
  position: absolute;
  height: ${({ theme }) => theme.library.tabs.underlineHeight}px;
  width: 100%;
  background-color: ${({ current, theme }) =>
    current ? theme.colors.text.primary : "transparent"};
  bottom: 0;
`

// The line that goes under all the tabs to separate them from the page content
const TabsSeparator = styled.View`
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.border.primary};
  bottom: 0;
`

// This is necessary to allow the CenteredContent component to correctly align
// the tabs when they don't overflow the horizontal scroll view.
const { contentContainerStyle } = StyleSheet.create({
  contentContainerStyle: { flexGrow: 1, alignItems: "center" },
})

export function getLibraryTabsHeight(tokens: ResolvedTokens<Tokens>): number {
  return (
    getAbsoluteLineHeight(
      tokens.library.tabs.textStyle[
        tokens.library.presentation as "wide" | "narrow"
      ],
      tokens,
    ) + tokens.spacing.medium
  )
}

export function LibraryTabs({
  currentTab,
  onChange,
}: {
  currentTab: LibraryTab
  onChange: (tab: LibraryTab) => void
}): JSX.Element | null {
  const auth = useAuth()
  const entitlements = useEntitlements()
  const { tokens } = useTokens()
  const presentation = tokens.library.presentation as "wide" | "narrow"
  const { t } = useTranslation()

  const libraryTabs = LIBRARY_TABS.filter((tab) => {
    switch (tab) {
      case "downloaded":
        return config.DOWNLOADS_SUPPORTED
      case "purchased":
        return entitlements.data?.length
      default:
        return true
    }
  })

  return auth.user ? (
    <Container>
      <TabsSeparator />
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={contentContainerStyle}
      >
        <CenteredContent>
          <Row>
            {libraryTabs.map((tab, index) => (
              <React.Fragment key={tab}>
                {index !== 0 || presentation === "wide" ? (
                  <Spacer
                    horizontal
                    size={presentation === "wide" ? "xlarge" : "large"}
                  />
                ) : null}
                <Tab
                  id={`library-tab-${tab}`}
                  onPress={() => {
                    if (currentTab !== tab) {
                      onChange(tab)
                    }
                  }}
                >
                  <Heading
                    textStyle={tokens.library.tabs.textStyle[presentation]}
                    level={2}
                    color={
                      currentTab !== tab
                        ? tokens.colors.text.secondary
                        : undefined
                    }
                  >
                    {t(stringKeyLookup.libraryTabTitles[tab])}
                  </Heading>
                  <Spacer size="medium" />
                  <TabUnderline current={currentTab === tab} />
                </Tab>
              </React.Fragment>
            ))}
          </Row>
        </CenteredContent>
      </ScrollView>
    </Container>
  ) : null
}
