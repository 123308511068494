import React from "react"
import { StyleProp, ViewStyle } from "react-native"

import { AppModuleBanner } from "@treefort/api-spec"
import { BANNER_SIZES } from "@treefort/constants"
import { clamp } from "@treefort/lib/clamp"

import { kebabCase } from "../lib/kebab-case"
import { getAbsoluteLineHeight } from "../lib/text-style"
import { AppLink } from "./app-link"
import BannerImageContainer from "./banner-image-container"
import { BoxPadding } from "./box"
import { Heading } from "./heading"
import Spacer from "./spacer"
import Text from "./text"
import { ResolvedTokens } from "./tokens-provider"
import UnobtrusiveButtonView from "./unobtrusive-button-view"

export const getBannerItemHeight = ({
  tokens,
  viewportHeight,
  viewportWidth,
  size,
  items,
}: {
  tokens: ResolvedTokens
  viewportHeight: number
} & (
  | {
      size?: Exclude<AppModuleBanner["config"]["size"], "fitToScreenWidth">
      items?: AppModuleBanner["config"]["items"]
      viewportWidth?: number
    }
  | {
      size: "fitToScreenWidth"
      items: AppModuleBanner["config"]["items"]
      viewportWidth: number
    }
)): number => {
  // All the banner sizes except fitToScreenWidth have maxHeight and
  // heightAsFractionOfViewport properties
  const staticBannerSizes = BANNER_SIZES.flatMap((size) =>
    size.value === "fitToScreenWidth" ? [] : size,
  )

  // Not all banner items have image media saved in the db
  const imageSizes = items?.flatMap((item) =>
    item.imageMedia?.original.height && item.imageMedia.original.width
      ? {
          height: item.imageMedia.original.height,
          width: item.imageMedia.original.width,
        }
      : [],
  )

  if (size === "fitToScreenWidth" && imageSizes?.length === items.length) {
    const bannerHeight = Math.max(
      ...imageSizes.map(({ height, width }, i) => {
        const factor = viewportWidth / width
        const scaledHeight = factor * height

        // Determine the minimum height necessary to support the banner text for
        // the current item.
        const minHeight =
          tokens.spacing.medium +
          (items[i].secondaryTitle
            ? getAbsoluteLineHeight("headingXSmall", tokens)
            : 0) +
          (items[i].title
            ? tokens.spacing.tiny +
              getAbsoluteLineHeight("headingXLarge", tokens) * 2
            : 0) +
          (items[i].description
            ? tokens.spacing.xsmall +
              getAbsoluteLineHeight("caption", tokens) * 3
            : 0) +
          (items[i].actionLink?.title
            ? tokens.spacing.small + getAbsoluteLineHeight("caption", tokens)
            : 0) +
          tokens.spacing.medium

        return Math.max(minHeight, scaledHeight)
      }),
    )

    return bannerHeight
  } else {
    const bannerSize =
      staticBannerSizes.find((s) => s.value === size) || staticBannerSizes[0]

    // Determine the minimum height necessary to support the longest possible
    // banner text.
    const minHeight =
      tokens.spacing.jumbo +
      getAbsoluteLineHeight("headingXSmall", tokens) +
      tokens.spacing.tiny +
      getAbsoluteLineHeight("headingXLarge", tokens) * 2 +
      tokens.spacing.xsmall +
      getAbsoluteLineHeight("caption", tokens) * 3 +
      tokens.spacing.small +
      getAbsoluteLineHeight("caption", tokens) +
      tokens.spacing.jumbo

    return clamp(
      viewportHeight * bannerSize.heightAsFractionOfViewport,
      minHeight,
      Math.max(bannerSize.maxHeight, minHeight),
    )
  }
}

export default function BannerItem({
  bannerItem,
  height,
  gradientColor,
  style,
  touchableArea = "content",
  gradientCoverage = 1,
  gradientOffset = 0,
  paddingBottom = "medium",
  alwaysShowGradient,
}: {
  bannerItem: AppModuleBanner["config"]["items"][0]
  height: number | string
  gradientColor: string
  touchableArea?: "view" | "content"
  gradientCoverage?: number
  gradientOffset?: number
  style?: StyleProp<ViewStyle>
  paddingBottom?: BoxPadding
  alwaysShowGradient?: boolean
}): JSX.Element {
  const content =
    bannerItem.title ||
    bannerItem.secondaryTitle ||
    bannerItem.description ||
    bannerItem.actionLink?.title ? (
      <>
        {bannerItem.secondaryTitle ? (
          <Text
            shadow
            textStyle="headingXSmall"
            color="translucent"
            numberOfLines={1}
          >
            {bannerItem.secondaryTitle}
          </Text>
        ) : null}
        {bannerItem.title ? (
          <>
            <Spacer size="tiny" />
            <Heading
              shadow
              level={3}
              numberOfLines={2}
              textStyle="headingXLarge"
              color="highContrast"
              maxWidth="title"
            >
              {bannerItem.title}
            </Heading>
          </>
        ) : null}
        {bannerItem.description ? (
          <>
            <Spacer size="xsmall" />
            <Text
              shadow
              numberOfLines={3}
              textStyle="caption"
              maxWidth="description"
              color="translucent"
            >
              {bannerItem.description}
            </Text>
          </>
        ) : null}
        {bannerItem.actionLink?.title ? (
          <>
            <Spacer size={bannerItem.description ? "small" : "xsmall"} />
            <UnobtrusiveButtonView>
              {bannerItem.actionLink.title}
            </UnobtrusiveButtonView>
          </>
        ) : null}
      </>
    ) : null
  const output = (
    <BannerImageContainer
      style={
        // If we're wrapping the whole view in a link then we'll apply the
        // styles to the link instead
        !(touchableArea === "view" && bannerItem.actionLink) ? style : undefined
      }
      height={height}
      gradientCoverage={gradientCoverage}
      gradientOffset={gradientOffset}
      source={{
        uri: bannerItem.imageUrl,
      }}
      paddingBottom={paddingBottom}
      gradientColor={gradientColor}
      alwaysShowGradient={alwaysShowGradient}
    >
      {touchableArea === "content" && bannerItem.actionLink ? (
        <AppLink
          id={kebabCase(
            `banner-item-navigate-to-${bannerItem.title || "untitled"}`,
          )}
          to={bannerItem.actionLink.link}
          aria-label={bannerItem.title || bannerItem.actionLink.title || ""}
        >
          {content}
        </AppLink>
      ) : (
        content
      )}
    </BannerImageContainer>
  )
  return touchableArea === "view" && bannerItem.actionLink ? (
    <AppLink
      id={kebabCase(`banner-item-navigate-to-${bannerItem.actionLink.title}`)}
      to={bannerItem.actionLink.link}
      style={style}
      aria-label={bannerItem.title || bannerItem.actionLink.title || ""}
    >
      {output}
    </AppLink>
  ) : (
    output
  )
}
