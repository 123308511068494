import React, { ReactNode, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import styled from "styled-components/native"

import useAppManifest from "../../hooks/use-app-manifest"
import { useAppTab } from "../../hooks/use-app-tab"
import { useNavigate } from "../../hooks/use-navigate"
import { usePageMetadata } from "../../hooks/use-page-metadata"
import { getMenuTitle } from "../../lib/get-menu-title"
import { kebabCase } from "../../lib/kebab-case"
import {
  getPathFromTab,
  SEARCH_TAB,
  MENU_TAB,
  isLibraryTab,
} from "../../navigation/routes"
import { i18nKey } from "../../types/i18next"
import { DesktopHeaderTab } from "../desktop-header-tab"
import HtmlHead from "../html-head"
import Row from "../row"
import Spacer from "../spacer"
import TenantLogo from "../tenant-logo"
import { DisplayMode } from "../tokens-provider"
import Touchable from "../touchable"
import AnimatedItem from "./animated-item"
import Container from "./container"
import { AppHeaderPresentation, AppHeaderState } from "./types"

const { titleContainerStyle } = StyleSheet.create({
  titleContainerStyle: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
})

const LeftNav = styled.View`
  position: absolute;
  left: ${(props) => props.theme.spacing.pagePaddingHorizontal}px;
  z-index: 1;
`

const RightNav = styled.View`
  position: absolute;
  right: ${(props) => props.theme.spacing.pagePaddingHorizontal}px;
  z-index: 1;
`

const StyledTenantLogo = styled(TenantLogo)`
  width: ${(props) => props.theme.appHeader.logoWidth}px;
  height: ${(props) => props.theme.appHeader.logoHeight}px;
`

export default function AppHeaderDesktop({
  children,
  childrenHeight,
  dropShadow,
  presentation,
  state,
  displayMode,
  backgroundColor,
}: {
  children?: ReactNode
  childrenHeight?: number
  dropShadow?: boolean
  presentation?: AppHeaderPresentation
  state?: AppHeaderState
  displayMode?: DisplayMode
  backgroundColor?: string
}): JSX.Element {
  const manifest = useAppManifest()
  const tab = useAppTab()
  const navigate = useNavigate()
  const pageMetadata = usePageMetadata()
  const goHome = useCallback(() => navigate("/"), [navigate])

  const libraryTab = manifest.tabs.find(isLibraryTab)
  const shopUrl = manifest.externalLinks?.shop

  const { t } = useTranslation()

  return (
    <>
      <HtmlHead pageMetadata={pageMetadata} />
      <Container
        extraChildren={children}
        extraChildrenHeight={childrenHeight}
        dropShadow={dropShadow}
        presentation={presentation}
        state={state}
        displayMode={displayMode}
        backgroundColor={backgroundColor}
      >
        {({ scrollInterpolation, heightInterpolation, presentation }) => (
          <>
            <LeftNav>
              <AnimatedItem
                presentation={presentation}
                opacity={
                  presentation === "transparent"
                    ? scrollInterpolation
                    : undefined
                }
              >
                {({ color }) => (
                  <Row>
                    {manifest.tabs
                      .filter((tab) => !isLibraryTab(tab))
                      .map((tabItem, index) => (
                        <React.Fragment key={tabItem.id}>
                          {index !== 0 ? (
                            <Spacer size="jumbo" horizontal />
                          ) : null}
                          <DesktopHeaderTab
                            id={kebabCase(
                              `desktop-header-tab-${tabItem.title}`,
                            )}
                            to={{ type: "path", path: getPathFromTab(tabItem) }}
                            color={color}
                            active={tabItem.id === tab.id}
                          >
                            {t(tabItem.title as i18nKey)}
                          </DesktopHeaderTab>
                        </React.Fragment>
                      ))}
                  </Row>
                )}
              </AnimatedItem>
            </LeftNav>
            <AnimatedItem
              presentation={presentation}
              opacity={
                presentation === "transparent" ? scrollInterpolation : undefined
              }
              scale={heightInterpolation.interpolate({
                inputRange: [0, 1],
                outputRange: [1, 0.8],
              })}
              containerStyle={titleContainerStyle}
            >
              {() => (
                <Touchable id="main-logo-home" onPress={goHome}>
                  <StyledTenantLogo />
                </Touchable>
              )}
            </AnimatedItem>
            <RightNav>
              <AnimatedItem
                presentation={presentation}
                opacity={
                  presentation === "transparent"
                    ? scrollInterpolation
                    : undefined
                }
              >
                {({ color }) => (
                  <Row>
                    <DesktopHeaderTab
                      id={"desktop-header-tab-search"}
                      to={{ type: "path", path: getPathFromTab(SEARCH_TAB) }}
                      color={color}
                      icon={manifest.icons.search}
                      active={tab.id === SEARCH_TAB.id}
                    >
                      {t(SEARCH_TAB.title)}
                    </DesktopHeaderTab>
                    <Spacer size="jumbo" horizontal />
                    {shopUrl ? (
                      <>
                        <DesktopHeaderTab
                          id={"desktop-header-tab-shop"}
                          color={color}
                          icon={manifest.icons.shop}
                          to={{ type: "url", url: shopUrl }}
                        >
                          {t("Shop")}
                        </DesktopHeaderTab>
                        <Spacer size="jumbo" horizontal />
                      </>
                    ) : null}
                    {libraryTab ? (
                      <>
                        <DesktopHeaderTab
                          id={"desktop-header-tab-library"}
                          to={{
                            type: "path",
                            path: getPathFromTab(libraryTab),
                          }}
                          color={color}
                          icon={libraryTab.icon}
                          active={libraryTab.id === tab.id}
                        >
                          {t(libraryTab.title as i18nKey)}
                        </DesktopHeaderTab>
                        <Spacer size="jumbo" horizontal />
                      </>
                    ) : null}
                    <DesktopHeaderTab
                      id={kebabCase(
                        `desktop-header-tab-${getMenuTitle(manifest)}`,
                      )}
                      to={{ type: "path", path: getPathFromTab(MENU_TAB) }}
                      color={color}
                      icon={manifest.icons.menu}
                      active={MENU_TAB.id === tab.id}
                    >
                      {t(getMenuTitle(manifest))}
                    </DesktopHeaderTab>
                  </Row>
                )}
              </AnimatedItem>
            </RightNav>
          </>
        )}
      </Container>
    </>
  )
}
