import React from "react"
import { useTranslation } from "react-i18next"

import { DISPLAY_MODES } from "@treefort/constants"

import { useDisplayModeSetting } from "../hooks/use-display-mode-setting"
import { kebabCase } from "../lib/kebab-case"
import { Button } from "./button"
import SettingsFieldset from "./settings-fieldset"
import SettingsRow from "./settings-row"

const flex = { flex: 1 }

export default function SettingsDisplayMode(): JSX.Element {
  const [displayMode, setDisplayMode] = useDisplayModeSetting()
  const { t } = useTranslation()

  return (
    <SettingsFieldset title={t("Display mode")}>
      <SettingsRow gap="small">
        {DISPLAY_MODES.map(({ value, label }) => (
          <Button
            id={kebabCase(`settings-display-mode-${value}-${label}`)}
            key={value}
            type={value === displayMode ? "primary" : "secondary"}
            onPress={() => setDisplayMode(value)}
            style={flex}
            containerStyle={flex}
          >
            {t(label)}
          </Button>
        ))}
      </SettingsRow>
    </SettingsFieldset>
  )
}
