import { Platform } from "react-native"

import i18next, { t } from "i18next"

import {
  SubscriptionPlanBenefit,
  StripeProrationAvailableResponse,
  StripeProrationPreviewResponse,
  Money,
  SubscriptionPlanInterval,
  UserSubscription,
  Duration,
  MediaImageFile,
} from "@treefort/api-spec"
import { DisplayableError } from "@treefort/lib/displayable-error"
import { getMessageFromAxiosError } from "@treefort/lib/errors"

import { SignUpOptionsData } from "../hooks/use-sign-up-options-data"
import api from "./api"
import { CheckoutSession, initiateCheckout } from "./checkout"
import { CheckoutCodeValidationResult } from "./checkout-codes"
import confirm from "./confirm"
import { getTrialButtonText } from "./free-trial-i18n"
import { logError } from "./logging"
import { openStripeBillingPortal } from "./stripe"
import { AvailableSubscriptionPlan } from "./subscription-plans"
import { subscriptionWillRenew } from "./subscriptions"

export type SignUpOption = {
  title: string
  current: boolean
  highlighted?: boolean
  description?: string
  benefits?: SubscriptionPlanBenefit[]
  items?: {
    title: string
    artworkMedia?: MediaImageFile
  }[]
  badgeText?: string
  statusText?: string
  terms?: {
    price: Money
    interval: SubscriptionPlanInterval
    intervalCount: number
    trialDuration?: Duration
    priceDisplayInterval?: SubscriptionPlanInterval
  }
  button?: {
    type: "primary" | "secondary"
    label: string
    isLoading: (checkoutSession: CheckoutSession | null | undefined) => boolean
    onPress: () => Promise<void>
  }
}

const isPaidSubscriptionPlan = (
  plan?: AvailableSubscriptionPlan,
): plan is Exclude<
  AvailableSubscriptionPlan,
  { provider: "groupMembership" }
> => Boolean(plan && plan.provider !== "groupMembership")

/**
 * Determine which billing intervals should be visible based on the checkout
 * screen state
 */
export function getBillingIntervalsToShow({
  signUpOptionsData,
  currentSubscriptionPlan,
  highlightSubscriptionPlanId,
}: {
  signUpOptionsData: SignUpOptionsData
  currentSubscriptionPlan: AvailableSubscriptionPlan | undefined
  highlightSubscriptionPlanId: number | undefined
}) {
  const plans = signUpOptionsData.recommendedSubscriptionPlans
    .concat(
      ...(currentSubscriptionPlan &&
      !signUpOptionsData.recommendedSubscriptionPlans.find(
        (plan) => plan.id === currentSubscriptionPlan.id,
      )
        ? [currentSubscriptionPlan]
        : []),
    )
    .filter(isPaidSubscriptionPlan)
  if (!plans.length) {
    return "all"
  }
  const isSingleMonthlyPlan =
    plans.filter((plan) => plan.interval === "month").length === 1
  const isSingleYearlyPlan =
    plans.filter((plan) => plan.interval === "year").length === 1
  const isSingleBillingInterval =
    plans?.length && plans?.every((plan) => plan.interval === plans[0].interval)
  if (isSingleBillingInterval || isSingleMonthlyPlan || isSingleYearlyPlan) {
    return "all"
  } else {
    const highlightedPlanInterval = plans.find((plan) =>
      highlightSubscriptionPlanId
        ? plan.id === highlightSubscriptionPlanId
        : plan.highlighted,
    )?.interval
    if (highlightedPlanInterval) {
      return highlightedPlanInterval
    } else {
      const firstPlanInterval = plans[0]?.interval
      return firstPlanInterval || "month"
    }
  }
}

export function getSignUpOptions({
  authenticated,
  subscription,
  signUpOptionsData: {
    // Available subscription plans filtered down to those that give the user
    // access to a particular peice of content
    recommendedSubscriptionPlans,
    availableSubscriptionPlans,
    freeTrialEligibilities,
  },
  promoCode,
  isUpgrading,
  highlightSubscriptionPlanId,
  setStripePreviewModalState,
  billingInterval,
  contentId,
  recommId,
  checkoutCodeValidationResult,
}: {
  billingInterval: "month" | "year" | "all"
  authenticated: boolean
  subscription: UserSubscription | undefined
  signUpOptionsData: SignUpOptionsData
  promoCode?: string
  setStripePreviewModalState: React.Dispatch<
    React.SetStateAction<
      | { open: false }
      | {
          open: true
          action: () => Promise<void>
          preview: StripeProrationAvailableResponse
          title: string
        }
    >
  >
  isUpgrading?: boolean
  highlightSubscriptionPlanId?: number
  contentId?: number
  recommId?: string
  checkoutCodeValidationResult: CheckoutCodeValidationResult | undefined
}): SignUpOption[] {
  const currentPlanId = subscriptionWillRenew(subscription)
    ? subscription.willRenewWithSubscriptionPlanId ||
      subscription.subscriptionPlanId
    : undefined

  /**
   * Group membership
   */

  const groupMembership =
    checkoutCodeValidationResult?.type === "validGroupMembershipCode"
      ? checkoutCodeValidationResult
      : undefined
  const groupMembershipPlan =
    groupMembership?.subscriptionPlanId &&
    availableSubscriptionPlans.find(
      (plan) =>
        plan.id === groupMembership.subscriptionPlanId &&
        plan.provider === "groupMembership",
    )

  if (groupMembershipPlan && Platform.OS === "web") {
    const groupMembershipIsCurrent = groupMembershipPlan?.id === currentPlanId

    return [
      {
        title: groupMembershipPlan.name,
        description: groupMembershipPlan.description,
        current: groupMembershipIsCurrent,
        ...(groupMembershipIsCurrent
          ? {
              statusText: t("Current plan"),
            }
          : {
              highlighted: true,
              button: {
                type: "primary",
                label: t("Sign Up"),
                onPress: () =>
                  initiateCheckout({
                    type: "groupMembership",
                    membershipCode: groupMembership.groupMembershipCode,
                    subscriptionPlanId: groupMembership.subscriptionPlanId,
                    contentId,
                    recommId,
                  }),
                isLoading: (checkoutSession) =>
                  checkoutSession?.type === "groupMembership",
              },
            }),
      },
    ]
  }

  /**
   * Coupon code
   */

  if (checkoutCodeValidationResult?.type === "validCouponCode") {
    const { coupon, couponCode } = checkoutCodeValidationResult.data

    const onPress = () =>
      initiateCheckout({ type: "coupon", coupon, couponCode })
    const isLoading = (checkoutSession?: CheckoutSession | null) =>
      checkoutSession?.type === "coupon"

    switch (coupon.type) {
      case "entitlement": {
        const additionalItemCount =
          coupon.redemptionPreview.totalItemCount -
          coupon.redemptionPreview.previewItems.length
        const additionalItemsDisplay =
          additionalItemCount > 0
            ? [{ title: t("+{{count}} more", { count: additionalItemCount }) }]
            : []
        return [
          {
            title: coupon.redemptionPreview.title,
            description: coupon.redemptionPreview.description,
            highlighted: true,
            current: false,
            items: [
              ...coupon.redemptionPreview.previewItems,
              ...additionalItemsDisplay,
            ],
            button: {
              type: "primary",
              label: t("Redeem"),
              onPress,
              isLoading,
            },
          },
        ]
      }
      case "subscriptionPlan": {
        const current = coupon.redeems.subscriptionPlanId === currentPlanId
        return [
          {
            title: coupon.redemptionPreview.title,
            description: coupon.redemptionPreview.description,
            current,
            ...(current
              ? {
                  statusText: t("Current plan"),
                }
              : {
                  highlighted: true,
                  button: {
                    type: "primary",
                    label: t("Sign Up"),
                    onPress,
                    isLoading,
                  },
                }),
          },
        ]
      }
    }
  }

  /**
   * Paid plans
   */

  const paidPlans = recommendedSubscriptionPlans.flatMap((plan) =>
    isPaidSubscriptionPlan(plan) &&
    (plan.interval === billingInterval || billingInterval === "all")
      ? plan
      : [],
  )

  const signUpOptions: SignUpOption[] = paidPlans.map((plan) => {
    const current = plan.id === currentPlanId
    const canceledWithTimeLeft =
      subscription?.subscriptionPlanId === plan.id &&
      subscription.status === "canceledWithTimeLeft"
    const offerFreeTrial =
      freeTrialEligibilities.some(
        (eligibility) => eligibility.eligible && eligibility.planId === plan.id,
      ) && plan.trialDuration
        ? true
        : false

    const startingAt =
      subscription?.willRenewWithSubscriptionPlanId === plan.id &&
      subscription.willRenewWithSubscriptionPlanId !==
        subscription.subscriptionPlanId &&
      subscription.currentPeriodEndsAt
        ? new Date(subscription.currentPeriodEndsAt)
        : undefined
    const action = !current
      ? async () => {
          if (canceledWithTimeLeft) {
            await initiateCheckout({
              type: "paidPlanResubscription",
              plan,
              promoCode,
              contentId,
              recommId,
            })
          } else {
            if (
              !authenticated ||
              Platform.OS === "android" ||
              Platform.OS === "ios" ||
              plan.provider === "webPayment"
            ) {
              initiateCheckout({
                type: "paidPlan",
                plan,
                isFreeTrial: offerFreeTrial,
                promoCode,
                contentId,
                recommId,
              })
            } else {
              try {
                // For Stripe plans on the web we handle showing the user a
                // preview of the purchase ourselves
                const preview = await api.post<StripeProrationPreviewResponse>(
                  "/integrations/stripe/proration-preview",
                  {
                    subscriptionPlanId: plan.id,
                    prorationDate: new Date().toISOString(),
                    promoCode,
                  },
                )
                if (preview.data.status === "noActiveSubscription") {
                  await initiateCheckout({
                    type: "paidPlan",
                    plan,
                    isFreeTrial: offerFreeTrial,
                    promoCode,
                    contentId,
                    recommId,
                  })
                } else if (
                  preview.data.status === "subscriptionCanceledWithTimeLeft"
                ) {
                  await initiateCheckout({
                    type: "paidPlanResubscription",
                    plan,
                    promoCode,
                    contentId,
                    recommId,
                  })
                } else if (
                  preview.data.status === "paymentMethodUpdateRequired"
                ) {
                  if (
                    await confirm({
                      message: t(
                        "Before continuing you need to add or update your payment information. Would you like to open the Billing Portal?",
                      ),
                      title: t("Payment Method Required"),
                      confirmLabel: t("Ok"),
                      cancelLabel: t("Cancel"),
                    })
                  ) {
                    await openStripeBillingPortal({ i18n: i18next })
                  }
                } else {
                  const prorationDate = preview.data.prorationDate
                  const stripeSubscriptionId = preview.data.stripeSubscriptionId
                  setStripePreviewModalState({
                    open: true,
                    action: async () => {
                      await initiateCheckout({
                        type: "paidPlanWithProrationPreview",
                        plan,
                        prorationDate: new Date(prorationDate),
                        stripeSubscriptionId,
                        promoCode,
                        contentId,
                        recommId,
                      })
                    },
                    preview: preview.data,
                    title: t("Switch to {{planName}}", {
                      planName: plan.name,
                    }),
                  })
                }
              } catch (e) {
                const message = getMessageFromAxiosError(e)
                logError(
                  new DisplayableError(
                    message &&
                    message.toLowerCase().startsWith("invalid promo code")
                      ? t("Invalid code.")
                      : t("An error occurred. Please try again."),
                    e,
                  ),
                )
              }
            }
          }
        }
      : undefined

    const highlighted = highlightSubscriptionPlanId
      ? highlightSubscriptionPlanId === plan.id
      : !current && plan.highlighted

    // This is true if the user has an active subscription to the current plan
    // but either:
    // - Their subscription is marked for cancellation
    // - Their subscription will renew with a different plan
    const wasSubscribed =
      subscription?.subscriptionPlanId === plan.id &&
      !subscription.deactivatedAt &&
      (subscription.canceledAt ||
        (subscription.willRenewWithSubscriptionPlanId &&
          subscription.willRenewWithSubscriptionPlanId !==
            subscription.subscriptionPlanId))

    return {
      title: plan.name,
      current,
      description: plan.description,
      terms: {
        price: plan.price,
        interval: plan.interval,
        intervalCount: plan.intervalCount,
        trialDuration: offerFreeTrial ? plan.trialDuration : undefined,
        priceDisplayInterval: plan.priceDisplayInterval,
      },
      badgeText: plan.badgeText ?? undefined,
      benefits: plan.benefits,
      highlighted,
      statusText: startingAt
        ? t("Starting on {{date}}", { date: startingAt })
        : current
          ? t("Current plan")
          : undefined,
      button: action
        ? {
            type: highlighted ? "primary" : "secondary",
            label: isUpgrading
              ? t("Upgrade")
              : wasSubscribed
                ? t("Resubscribe")
                : offerFreeTrial && plan.trialDuration
                  ? getTrialButtonText({
                      duration: plan.trialDuration,
                      i18n: i18next,
                    })
                  : t("Subscribe"),
            onPress: action,
            isLoading: (checkoutSession: CheckoutSession | null | undefined) =>
              (checkoutSession?.type === "paidPlan" ||
                checkoutSession?.type === "paidPlanResubscription" ||
                checkoutSession?.type === "paidPlanWithProrationPreview") &&
              checkoutSession.plan.id === plan.id,
          }
        : undefined,
    }
  })

  // If no option ended up being highlighted under our normal logic and we're
  // upgrading then highlight the first available plan
  if (
    isUpgrading &&
    signUpOptions.length &&
    signUpOptions.every((option) => !option.highlighted)
  ) {
    signUpOptions[0].highlighted = true
  }

  // If no option ended up having a primary button then show a primary button
  // for all of them (otherwise there's no clear call to action)
  if (signUpOptions.every((option) => option.button?.type !== "primary")) {
    signUpOptions.forEach((option) => {
      if (option.button) {
        option.button.type = "primary"
      }
    })
  }

  return signUpOptions
}
