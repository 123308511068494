"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.idToKey = exports.keyToId = void 0;
function keyToId({ collection, key, userId, profileId, }) {
    // We're managing ids of key-value Watermelon records manually because we want to
    // treat it as a true key-value store per-tenant+user+profile across all
    // devices - there should be no possibility of two devices creating the same
    // records for the same content but generate different record IDs.
    //
    // This composite ID does not include tenant ID because:
    // 1. User ID is a globally-unique value already
    // 2. The device doesn't generally know about tenant (watermelon records are
    //    sent without tenant ID; it's filtered API-side)
    return `${collection}:${userId}:${profileId !== null && profileId !== void 0 ? profileId : ""}:${key}`;
}
exports.keyToId = keyToId;
function idToKey(watermelonId) {
    return watermelonId.split(":").slice(-1)[0];
}
exports.idToKey = idToKey;
