import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { Animated, StyleSheet, Platform } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"

import styled from "styled-components/native"

import rawTokens from "@treefort/tokens/app"

import useAppManifest from "../hooks/use-app-manifest"
import { useLockBodyScroll } from "../hooks/use-lock-body-scroll"
import { useOpenAnimation } from "../hooks/use-open-animation"
import { kebabCase } from "../lib/kebab-case"
import styleObjectToString from "../lib/style-object-to-string"
import Box from "./box"
import { Heading } from "./heading"
import IconButton from "./icon-button"
import { KeyboardAvoidingView } from "./keyboard-avoiding-view"
import { Portal } from "./portal"
import { NavigationBar } from "./system-bars/navigation-bar"
import { ResolvedTokens, useTokens } from "./tokens-provider"
import Touchable from "./touchable"

type BackgroundColor = keyof typeof rawTokens.colors.background

type AlignmentHorizontal = "left" | "center" | "right"

type AlignmentVertical = "top" | "center" | "bottom"

export type ModalType = "floating" | "fullscreen" | "sheet" | "roundedSheet"

export const MODAL_HEADER_HEIGHT_PX = 60
const MODAL_ANIMATION_VERTICAL_PX = 30

const flexAlignment = {
  top: "flex-start",
  center: "center",
  bottom: "flex-end",
  left: "flex-start",
  right: "flex-end",
}

function getBorderRadiusStyles(modalType: ModalType, theme: ResolvedTokens) {
  switch (modalType) {
    case "floating":
      return `border-radius: ${theme.borderRadius.roundedLarge}px`
    case "roundedSheet":
      return `${styleObjectToString({
        borderTopRightRadius: theme.borderRadius.roundedLarge,
        borderTopLeftRadius: theme.borderRadius.roundedLarge,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      })}`
    default:
      return `border-radius: 0`
  }
}

/**
 * Fills the screen and provides a canvas for the modal.
 */
const Container = styled(Animated.View)<{
  backgroundColor?: BackgroundColor
  touchable?: boolean
}>`
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme.colors.background[props.backgroundColor]
      : undefined};
  position: ${Platform.OS === "web" ? "fixed" : "absolute"};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: ${(props) => (props.touchable ? "auto" : "box-none")};
`

/**
 * Renders the semi-opaque overlay behind the modal.
 */
const Scrim = styled.View<{ show: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) =>
    props.show ? props.theme.colors.background.scrim : "transparent"};
  pointer-events: box-none;
`

/**
 * Used to ensure proper spacing between the modal and the edges of the screen.
 */
const ModalBuffer = styled(Box)<{
  floatingAlignmentHorizontal: AlignmentHorizontal
  floatingAlignmentVertical: AlignmentVertical
  type: ModalType
}>`
  height: 100%;
  width: 100%;
  align-items: ${(props) =>
    props.type === "floating"
      ? flexAlignment[props.floatingAlignmentHorizontal]
      : "center"};
  justify-content: ${(props) =>
    props.type === "floating"
      ? flexAlignment[props.floatingAlignmentVertical]
      : "flex-end"};
`

/**
 * Use a container for the box shadow since shadows are clipped by `overflow:
 * hidden` on iOS
 */
const ModalBodyContainer = styled(Animated.View)<{
  type: ModalType
  maxWidth?: string | number
  showBoxShadow?: boolean
  openState: "open" | "opening" | "closing"
  backgroundColor: BackgroundColor
}>`
  background-color: ${(props) =>
    // Not specifying a background color on elements with a shadow generates a
    // warning on iOS
    props.theme.colors.background[props.backgroundColor]};
  ${({ theme, type }) => getBorderRadiusStyles(type, theme)};
  max-width: ${(props) =>
    props.type === "floating"
      ? typeof props.maxWidth === "number"
        ? `${props.maxWidth}px`
        : props.maxWidth
      : "100%"};
  ${({ theme, showBoxShadow, openState }) =>
    !showBoxShadow ||
    // Showing the shadow on Android during the close animation
    // causes an odd-looking afterimage effect
    (Platform.OS === "android" && openState === "closing")
      ? ""
      : Platform.OS === "android"
        ? // Box shadows don't work on android so we have to use the elevation api
          // instead
          `elevation: 22; shadow-color: ${theme.colors.gray500};`
        : `box-shadow: ${theme.planCard.card.boxShadow};`}
  position: relative;
  width: 100%;
  /* Necessary to keep the body from overflowing vertically on the web */
  max-height: 100%;
`

/**
 * Renders the actual visual bounds of the modal including border, border
 * radius, background color, etc. (what you think of as the modal proper when
 * you look at the screen).
 */
const ModalBody = styled.View<{
  showScrim: boolean
  showBorder: boolean
  type: ModalType
  paddingBottom: number
  paddingLeft: number
  paddingRight: number
  backgroundColor: BackgroundColor
  hasBoxShadow: boolean
}>`
  overflow: hidden;
  background-color: ${(props) =>
    props.theme.colors.background[props.backgroundColor]};
  ${({ theme, type }) => getBorderRadiusStyles(type, theme)};
  border-style: solid;
  border-color: ${(props) =>
    props.showScrim || props.hasBoxShadow
      ? props.theme.colors.border.shadowAdjacent
      : props.theme.colors.border.primary};
  ${(props) =>
    props.type === "floating" && props.showBorder
      ? "border-width: 1px"
      : (props.type === "sheet" || "roundedSheet") && props.showBorder
        ? "border-top-width: 1px"
        : ""};
  padding-bottom: ${(props) => props.paddingBottom}px;
  padding-left: ${(props) => props.paddingLeft}px;
  padding-right: ${(props) => props.paddingRight}px;
  position: relative;
  width: 100%;
  /* Necessary to keep the body from overflowing vertically on the web */
  max-height: 100%;
`

const Header = styled(Box)<{ title?: string }>`
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: ${MODAL_HEADER_HEIGHT_PX}px;
`

const CloseButtonContainer = styled.View`
  position: absolute;
  right: 0;
  height: ${MODAL_HEADER_HEIGHT_PX}px;
  width: ${(props) => props.theme.minTapTarget}px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled(Heading)`
  flex: 1;
  padding-right: ${(props) => props.theme.minTapTarget}px;
`

const { scrimTouchableStyle } = StyleSheet.create({
  scrimTouchableStyle: { width: "100%", height: "100%" },
})

/**
 * Render content in a modal over the rest of the app.
 *
 * If the modal should be closeable by the user at any time (most use-cases)
 * then set the open prop to false when onPressOutside and onPressCloseButton are
 * called. If the modal doesn't have a header then instead of setting the
 * onPressCloseButton prop include some sort of close button in the modal's
 * children (e.g. a "Done" button).
 *
 * The modal will expand vertically to fit the children passed to it until it
 * fills 100% of the screen height (minus modal margin + safe area insets).
 * Beyond this point the children will be clipped. If your modal has dynamic
 * content that could conceivably be clipped, wrap it in a ScrollView, FlatList,
 * SectionList, VirtualizedList, etc. Note that the modal makes use of the
 * useLockBodyScroll hook, so a data-web-scroll-unlock attribute should be set
 * on the scrollable element (this can be done by setting the `dataSet` prop to
 * `{{ webScrollUnlock: true }}`.
 *
 * The modal body does not include any built-in padding for flexibility. In most
 * cases it is recommend that padding equal to the "large" spacing token be
 * applied around your content. This padding should be applied inside any
 * scrollable view to ensure scrollbars are positioned at the very edge of the
 * modal.
 *
 * Note that for fullscreen modals no safe area insets are applied. This results
 * in a true fullscreen effect on native mobile, but requires that the content
 * placed inside the modal take safe area insets into account IF the modal is
 * displayed in fullscreen mode. This gets a bit tricky because the modal will
 * automatically switch out of fullscreen mode on larger screens. To get the
 * current display type of the modal, children can be passed as a function that
 * will be called with the current type.
 */
export default function Modal({
  open,
  children,
  showScrim = true,
  showBorder = true,
  showBoxShadow = false,
  title,
  maxWidth = 540,
  type: typeProp = "floating",
  backgroundColor = "primary",
  floatingAlignmentHorizontal = "center",
  floatingAlignmentVertical = "center",
  margin = 16,
  marginTop = margin,
  marginBottom = margin,
  marginLeft = margin,
  marginRight = margin,
  keyboardAvoidingViewBehavior = "disabled",
  onPressOutside,
  onPressCloseButton,
  onCloseComplete,
  portalHost = "middleground",
  insets: insetsProp,
}: {
  open: boolean
  children: ReactNode | ((type: ModalType) => ReactNode)
  showScrim?: boolean
  showBorder?: boolean
  showBoxShadow?: boolean
  title?: string
  maxWidth?: number
  type?: ModalType
  backgroundColor?: BackgroundColor
  floatingAlignmentHorizontal?: AlignmentHorizontal
  floatingAlignmentVertical?: AlignmentVertical
  margin?: number
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
  keyboardAvoidingViewBehavior?: "height" | "padding" | "position" | "disabled"
  onPressOutside?: () => unknown
  onPressCloseButton?: () => unknown
  onCloseComplete?: () => unknown
  portalHost?: "foreground" | "middleground"
  insets?: { top?: number; bottom?: number; left?: number; right?: number }
}): JSX.Element | null {
  const manifest = useAppManifest()
  const { displayWidth } = useTokens()
  const safeInsets = useSafeAreaInsets()
  const insets = { ...safeInsets, ...insetsProp }
  const [openValue, openState] = useOpenAnimation({
    open,
    onCloseComplete,
  })
  const type =
    displayWidth > maxWidth + marginRight + marginLeft ? "floating" : typeProp
  const { t } = useTranslation()

  // Lock body scroll on the web when the scrim is shown over the page
  useLockBodyScroll(open && showScrim)

  // Bail as early as possible
  if (openState === "closed") {
    return <Portal hostName={portalHost}></Portal>
  }

  const modalContent = (
    <>
      {title || onPressCloseButton ? (
        <Header
          title={title}
          borderBottomColor="primary"
          paddingHorizontal="medium"
          backgroundColor="primary"
        >
          {title ? (
            <Title textStyle="headingMedium" level={4} numberOfLines={1}>
              {title}
            </Title>
          ) : null}
          {onPressCloseButton ? (
            <CloseButtonContainer>
              <IconButton
                id={kebabCase(`modal-close-btn${title ? `-${title}` : ""}`)}
                source={manifest.icons.close}
                iconSize="medium"
                onPress={onPressCloseButton}
                label={t("Close")}
              />
            </CloseButtonContainer>
          ) : null}
        </Header>
      ) : null}
      {typeof children === "function" ? children(type) : children}
      <NavigationBar
        onPressBack={openState === "open" ? onPressCloseButton : undefined}
      />
    </>
  )

  const opacity = openValue
  const transform = [
    {
      translateY: openValue.interpolate({
        inputRange: [0, 1],
        outputRange: [MODAL_ANIMATION_VERTICAL_PX, 0],
      }),
    },
  ]

  // NOTE: Portal is always rendered regardless of openState to avoid issues
  // that can arise when Portal components are mounted and unmounted rapidly
  return (
    <Portal hostName={portalHost}>
      {type === "fullscreen" ? (
        <Container
          style={{ opacity, transform }}
          backgroundColor={backgroundColor}
          touchable
        >
          {modalContent}
        </Container>
      ) : type === "floating" ? (
        <Container style={{ opacity }}>
          {showScrim || onPressOutside ? (
            <Scrim show={showScrim}>
              {
                // Listen for touches if onPressOutside is set and we're not in
                // the process of closing. We pass touches through while closing
                // to prevent the app from feeling unresponsive at the tail end
                // of the close animation.
                onPressOutside && openState !== "closing" ? (
                  <Touchable
                    id={kebabCase(
                      `modal-scrim-press-outside${title ? `-${title}` : ""}`,
                    )}
                    style={scrimTouchableStyle}
                    containerStyle={scrimTouchableStyle}
                    onPress={onPressOutside}
                  />
                ) : null
              }
            </Scrim>
          ) : null}
          <KeyboardAvoidingView
            behavior={keyboardAvoidingViewBehavior}
            pointerEvents="box-none"
          >
            <ModalBuffer
              type={type}
              pointerEvents="box-none"
              paddingTop={insets.top + marginTop}
              paddingBottom={insets.bottom + marginBottom}
              paddingLeft={insets.left + marginLeft}
              paddingRight={insets.right + marginRight}
              floatingAlignmentHorizontal={floatingAlignmentHorizontal}
              floatingAlignmentVertical={floatingAlignmentVertical}
            >
              <ModalBodyContainer
                backgroundColor={backgroundColor}
                maxWidth={maxWidth}
                type={type}
                openState={openState}
                showBoxShadow={showBoxShadow}
                style={{
                  transform,
                }}
              >
                <ModalBody
                  type={type}
                  showScrim={showScrim}
                  showBorder={showBorder}
                  paddingBottom={0}
                  paddingLeft={0}
                  paddingRight={0}
                  backgroundColor={backgroundColor}
                  hasBoxShadow={showBoxShadow}
                >
                  {modalContent}
                </ModalBody>
              </ModalBodyContainer>
            </ModalBuffer>
          </KeyboardAvoidingView>
        </Container>
      ) : type === "sheet" ? (
        <Container style={{ opacity }}>
          {showScrim || onPressOutside ? (
            <Scrim show={showScrim}>
              {onPressOutside ? (
                <Touchable
                  id={kebabCase(
                    `modal-scrim-press-outside${title ? `-${title}` : ""}`,
                  )}
                  style={scrimTouchableStyle}
                  containerStyle={scrimTouchableStyle}
                  onPress={onPressOutside}
                />
              ) : null}
            </Scrim>
          ) : null}
          <KeyboardAvoidingView
            behavior={keyboardAvoidingViewBehavior}
            pointerEvents="box-none"
          >
            <ModalBuffer
              type={type}
              pointerEvents="box-none"
              paddingTop={insets.top + marginTop}
              paddingBottom={0}
              paddingLeft={0}
              paddingRight={0}
              floatingAlignmentHorizontal={floatingAlignmentHorizontal}
              floatingAlignmentVertical={floatingAlignmentVertical}
            >
              <ModalBodyContainer
                maxWidth={maxWidth}
                type={type}
                backgroundColor={backgroundColor}
                openState={openState}
                showBoxShadow={showBoxShadow}
                style={{
                  transform,
                }}
              >
                <ModalBody
                  type={type}
                  showScrim={showScrim}
                  showBorder={showBorder}
                  paddingBottom={insets.bottom}
                  paddingLeft={insets.left}
                  paddingRight={insets.right}
                  backgroundColor={backgroundColor}
                  hasBoxShadow={showBoxShadow}
                >
                  {modalContent}
                </ModalBody>
              </ModalBodyContainer>
            </ModalBuffer>
          </KeyboardAvoidingView>
        </Container>
      ) : type === "roundedSheet" ? (
        <Container style={{ opacity }}>
          {showScrim || onPressOutside ? (
            <Scrim show={showScrim}>
              {onPressOutside ? (
                <Touchable
                  id={kebabCase(
                    `modal-scrim-press-outside${title ? `-${title}` : ""}`,
                  )}
                  style={scrimTouchableStyle}
                  containerStyle={scrimTouchableStyle}
                  onPress={onPressOutside}
                />
              ) : null}
            </Scrim>
          ) : null}
          <KeyboardAvoidingView
            behavior={keyboardAvoidingViewBehavior}
            pointerEvents="box-none"
          >
            <ModalBuffer
              type={type}
              pointerEvents="box-none"
              paddingTop={insets.top + marginTop}
              paddingBottom={0}
              paddingLeft={0}
              paddingRight={0}
              floatingAlignmentHorizontal={floatingAlignmentHorizontal}
              floatingAlignmentVertical={floatingAlignmentVertical}
            >
              <ModalBodyContainer
                maxWidth={maxWidth}
                type={type}
                backgroundColor={backgroundColor}
                openState={openState}
                showBoxShadow={showBoxShadow}
                style={{
                  transform,
                }}
              >
                <ModalBody
                  type={type}
                  showScrim={showScrim}
                  showBorder={showBorder}
                  paddingBottom={insets.bottom}
                  paddingLeft={insets.left}
                  paddingRight={insets.right}
                  backgroundColor={backgroundColor}
                  hasBoxShadow={showBoxShadow}
                >
                  {modalContent}
                </ModalBody>
              </ModalBodyContainer>
            </ModalBuffer>
          </KeyboardAvoidingView>
        </Container>
      ) : (
        (type satisfies never)
      )}
    </Portal>
  )
}
