import { Track } from "../av/types"
import {
  ConsumableContent,
  AudiobookConsumableContent,
  EbookConsumableContent,
  PodcastEpisodeConsumableContent,
  VideoConsumableContent,
  AlbumConsumableContent,
} from "../consumable-content"
import { debug as appDebug } from "../logging"
import settings from "../settings"
import {
  AudiobookProgressItem,
  PlayableProgressItem,
  PlayableProgressItemData,
  PodcastEpisodeProgressItem,
  VideoProgressItem,
  AudiobookProgressItemData,
  VideoProgressItemData,
  PodcastEpisodeProgressItemData,
  AlbumProgressItem,
  AlbumProgressItemData,
} from "./playable"
import {
  EbookProgressItem,
  EbookProgressItemData,
  ReadableProgressItem,
  ReadableProgressItemData,
  WebEmbedProgressItem,
  WebEmbedProgressItemData,
} from "./readable"

const debug = appDebug.extend("progress-item")

export type ProgressItemDataForConsumableContent<T extends ConsumableContent> =
  T extends PodcastEpisodeConsumableContent
    ? PodcastEpisodeProgressItemData
    : Extract<ProgressItemData, { contentType: T["type"] }>

export type ProgressItem = PlayableProgressItem | ReadableProgressItem

export type ProgressItemData =
  | PlayableProgressItemData
  | ReadableProgressItemData

export type ProgressItemForConsumableContent<T extends ConsumableContent> =
  T extends AudiobookConsumableContent
    ? AudiobookProgressItem
    : T extends EbookConsumableContent
      ? EbookProgressItem
      : T extends VideoConsumableContent
        ? VideoProgressItem
        : T extends PodcastEpisodeConsumableContent
          ? PodcastEpisodeProgressItem
          : T extends AlbumConsumableContent
            ? AlbumProgressItem
            : ProgressItem

export type ProgressItemForData<T extends ProgressItemData> =
  T extends AudiobookProgressItemData
    ? AudiobookProgressItem
    : T extends EbookProgressItemData
      ? EbookProgressItem
      : T extends VideoProgressItemData
        ? VideoProgressItem
        : T extends PodcastEpisodeProgressItemData
          ? PodcastEpisodeProgressItem
          : T extends WebEmbedProgressItemData
            ? WebEmbedProgressItem
            : T extends AlbumProgressItemData
              ? AlbumProgressItem
              : ProgressItem

export function getProgressItemFromConsumableContent<
  T extends ConsumableContent,
>({
  consumableContent,
  profileId,
}: {
  consumableContent: T
  profileId: string | null
}): ProgressItemForConsumableContent<T> {
  switch (consumableContent.type) {
    case "book":
      return new AudiobookProgressItem({
        data: {
          contentId: consumableContent.content.id,
          contentType: consumableContent.content.type,
        },
        profileId,
      }) as ProgressItemForConsumableContent<T>
    case "ebook":
      return new EbookProgressItem({
        data: {
          contentId: consumableContent.content.id,
          contentType: consumableContent.content.type,
        },
        profileId,
      }) as ProgressItemForConsumableContent<T>

    case "webEmbed":
      return new WebEmbedProgressItem({
        data: {
          contentId: consumableContent.content.id,
          contentType: consumableContent.content.type,
        },
        profileId,
      }) as ProgressItemForConsumableContent<T>
    case "video":
      return new VideoProgressItem({
        data: {
          contentId: consumableContent.content.id,
          contentType: consumableContent.content.type,
        },
        profileId,
      }) as ProgressItemForConsumableContent<T>
    case "podcastEpisode":
      return new PodcastEpisodeProgressItem({
        data: {
          contentId: consumableContent.content.id,
          contentType: consumableContent.content.type,
          podcastEpisode: consumableContent.podcastEpisode.episode,
        },
        profileId,
      }) as ProgressItemForConsumableContent<T>
    case "album":
      return new AlbumProgressItem({
        data: {
          contentId: consumableContent.content.id,
          contentType: consumableContent.content.type,
        },
        profileId,
      }) as ProgressItemForConsumableContent<T>
  }

  // NOTE: We'll never fall through here because the client can only create
  // consumable content for content that it recognizes
}

export function getProgressItemFromTrack(track: Track) {
  switch (track.extra.contentType) {
    case "book":
      return new AudiobookProgressItem({
        data: {
          contentId: track.extra.contentId,
          contentType: "book",
        },
        profileId: track.extra.profileId,
      })
    case "video":
      return new VideoProgressItem({
        data: {
          contentId: track.extra.contentId,
          contentType: "video",
        },
        profileId: track.extra.profileId,
      })
    case "podcast":
      return new PodcastEpisodeProgressItem({
        data: {
          contentId: track.extra.contentId,
          contentType: "podcast",
          podcastEpisode: track.extra.podcastEpisodeNumber,
        },
        profileId: track.extra.profileId,
      })
    case "album":
      return new AlbumProgressItem({
        data: {
          contentId: track.extra.contentId,
          contentType: "album",
        },
        profileId: track.extra.profileId,
      })
  }

  // NOTE: We'll never fall through here because the client can only create
  // consumable content tracks for content that it recognizes
}

export function getProgressItemFromSettingValue<T extends ProgressItemData>({
  value: data,
  profileId,
}: {
  value: T
  profileId: string | null
}) {
  switch (data.contentType) {
    case "book":
      return new AudiobookProgressItem({
        data,
        profileId,
      }) as ProgressItemForData<T>
    case "ebook":
      return new EbookProgressItem({
        data,
        profileId,
      }) as ProgressItemForData<T>
    case "video":
      return new VideoProgressItem({
        data,
        profileId,
      }) as ProgressItemForData<T>
    case "podcast":
      return new PodcastEpisodeProgressItem({
        data,
        profileId,
      }) as ProgressItemForData<T>
    case "webEmbed":
      return new WebEmbedProgressItem({
        data,
        profileId,
      }) as ProgressItemForData<T>
    case "album":
      return new AlbumProgressItem({
        data,
        profileId,
      }) as ProgressItemForData<T>
    default:
      data satisfies never
      return undefined
  }
}

export async function fetchProgressItemFromSettings<
  T extends ConsumableContent,
>({
  consumableContent,
  profileId,
  strategy,
}: {
  consumableContent: T
  profileId: string | null
  strategy: "local" | "localOrRemote"
}) {
  // Create an "empty" progress item - i.e. a progress item with no progress set
  const emptyProgressItem = getProgressItemFromConsumableContent<T>({
    consumableContent,
    profileId,
  })

  // Look for progress data in settings
  const { value, timestamp } = await (
    strategy === "local" ? settings.getLocal : settings.getLocalOrRemote
  )<ProgressItemData>(emptyProgressItem.getKey(), {
    profileId,
  })

  debug("Retrieved progress value and timestamp from settings", {
    key: emptyProgressItem.getKey(),
    value,
    timestamp,
  })

  // If we found a setting then create a progress from the setting's value,
  // otherwise use the empty progress item.
  const progressItem = (
    value
      ? getProgressItemFromSettingValue({ value, profileId }) ||
        emptyProgressItem
      : emptyProgressItem
  ) as ProgressItemForConsumableContent<T>

  debug("Built progress item", { progressItem })

  // If the progress item supports playback rates but doesn't have one set then
  // load the default playback rate from settings.
  if (
    progressItem instanceof PlayableProgressItem &&
    !progressItem.hasPlaybackRate()
  ) {
    const defaultPlaybackRate = await progressItem.getDefaultPlaybackRate()
    if (defaultPlaybackRate) {
      progressItem.setPlaybackRate(defaultPlaybackRate)
    }
  }

  return { progressItem, timestamp }
}

export * from "./playable"
export * from "./readable"
