import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import useAppManifest from "../../hooks/use-app-manifest"
import { useAppTab } from "../../hooks/use-app-tab"
import { useNavigate } from "../../hooks/use-navigate"
import { kebabCase } from "../../lib/kebab-case"
import { getPathFromTab } from "../../navigation/routes"
import { i18nKey } from "../../types/i18next"
import { Tab } from "./tab"

const { tabContainerStyle } = StyleSheet.create({
  tabContainerStyle: {
    flex: 1,
  },
})

export default function Tabs(): JSX.Element {
  const manifest = useAppManifest()
  const navigate = useNavigate()
  const currentTab = useAppTab()
  const tabs = manifest.tabs
  const { t } = useTranslation()

  return (
    <>
      {tabs.map((tab) => {
        const path = getPathFromTab(tab)
        const label = t(tab.title as i18nKey)
        const isActive = tab.id === currentTab.id
        const onPress = () => navigate(path)

        return (
          <Tab
            id={kebabCase(`bottom-tab-bar-${tab.id}-${tab.slug}`)}
            source={tab.icon}
            state={isActive ? "active" : "inactive"}
            key={tab.id}
            onPress={onPress}
            label={label}
            showLabel={true}
            feedback="ripple-or-none"
            iconSize="large"
            containerStyle={tabContainerStyle}
          />
        )
      })}
    </>
  )
}
