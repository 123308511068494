import { useMemo } from "react"

import { ConsumableContent } from "../lib/consumable-content"
import { debug as appDebug } from "../lib/logging"
import {
  getProgressItemFromConsumableContent,
  getProgressItemFromSettingValue,
  ProgressItemDataForConsumableContent,
  ProgressItemForConsumableContent,
} from "../lib/progress-item"
import settings from "../lib/settings"
import { useActiveProfileId } from "./use-active-profile-id"
import { useSetting } from "./use-setting"

const unwrappedDebug = appDebug.extend("hooks:useProgressItem")

/**
 * Returns a progress item for a piece of content that is always in sync with
 * the underlying settings data.
 */
export const useProgressItem = <T extends ConsumableContent>({
  consumableContent,
  throttle,
  shouldUpdate,
}: {
  consumableContent?: T
  throttle?: number
  shouldUpdate?: (
    a: ProgressItemDataForConsumableContent<T> | null,
    b: ProgressItemDataForConsumableContent<T> | null,
  ) => boolean
}): ProgressItemForConsumableContent<T> | undefined => {
  const debug = (msg: string, ...args: unknown[]) =>
    unwrappedDebug(
      `[${consumableContent?.type}:${consumableContent?.content.id}] ${msg}`,
      ...args,
    )

  const profileId = useActiveProfileId()
  const progressItem =
    consumableContent &&
    getProgressItemFromConsumableContent({ consumableContent, profileId })

  debug("Fetching value from useSetting", {
    key: progressItem?.getKey(),
    profileId,
  })

  const [value] = useSetting<ProgressItemDataForConsumableContent<T>>({
    key: progressItem?.getKey(),
    settings,
    strategy: "localOrRemote",
    throttle,
    shouldUpdate,
    profileId,
  })

  debug("value is", { value })

  return useMemo(
    () =>
      value
        ? getProgressItemFromSettingValue({ value, profileId })
        : progressItem,
    [value, profileId, progressItem],
  ) as ProgressItemForConsumableContent<T> | undefined
}
