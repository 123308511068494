import React, { useCallback } from "react"
import { Animated, DimensionValue } from "react-native"

import { AppModuleBanner } from "@treefort/api-spec"

import Banner, { getBannerHeight } from "../banner"
import BannerItem from "../banner-item"
import TokensProvider, { useTokens } from "../tokens-provider"

export { getBannerHeight }

export function BannerModule({
  items,
  height,
  scrollRestorationKey,
  autoplay = false,
  paddingTop = 0,
}: {
  items: AppModuleBanner["config"]["items"]
  height: Exclude<DimensionValue, null | Animated.AnimatedNode>
  scrollRestorationKey?: string
  autoplay?: boolean
  paddingTop?: number
}): JSX.Element {
  const { tokens, displayWidth } = useTokens()
  const intervals = items?.length || 1
  const { gradientOffset, gradientCoverage, scrollIndicator } =
    tokens.bannerModule

  // Make room for the scroll indicator when it's present
  const itemPaddingBottom =
    intervals > 1 ? scrollIndicator.container.height : tokens.spacing.large

  const bannerItemHeight =
    paddingTop && typeof height === "number" ? height - paddingTop : height

  const renderItems = useCallback(
    (itemWidth: number) =>
      items.map((item, index) => (
        <BannerItem
          height={bannerItemHeight}
          style={{ width: itemWidth, height: bannerItemHeight }}
          bannerItem={item}
          key={index}
          paddingBottom={itemPaddingBottom}
          gradientColor={tokens.colors.gray950}
          gradientOffset={gradientOffset}
          gradientCoverage={gradientCoverage}
          touchableArea="view"
        />
      )) || [],
    [
      gradientCoverage,
      gradientOffset,
      itemPaddingBottom,
      bannerItemHeight,
      items,
      tokens.colors.gray950,
    ],
  )

  return (
    <TokensProvider displayMode="dark">
      <Banner
        paddingTop={paddingTop}
        renderItems={renderItems}
        autoplay={autoplay}
        height={height}
        width={displayWidth}
        scrollRestorationKey={scrollRestorationKey}
      />
    </TokensProvider>
  )
}
