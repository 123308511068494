import React from "react"
import { useTranslation } from "react-i18next"

import styled from "styled-components/native"

import useAppManifest from "../hooks/use-app-manifest"
import { useConsent } from "../lib/consent"
import ActivityIndicator from "./activity-indicator"
import { AppLink } from "./app-link"
import SettingsFieldset from "./settings-fieldset"
import SettingsRow from "./settings-row"
import Switch from "./switch"
import Text from "./text"

const SwitchContainer = styled.View`
  flex: 0.2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export function SettingsConsent(): JSX.Element {
  const { t } = useTranslation()
  const manifest = useAppManifest()
  const [analyticsCookies, setAnalyticsCookies] = useConsent("analyticsCookies")
  const [advertisingCookies, setAdvertisingCookies] =
    useConsent("advertisingCookies")

  return (
    <SettingsFieldset id="consent" title={t("Cookies")}>
      <SettingsRow
        label={t("Necessary")}
        secondaryLabel={t(
          "These cookies are required for the service to function.",
        )}
      >
        <SwitchContainer>
          <Switch value={true} disabled />
        </SwitchContainer>
      </SettingsRow>
      <SettingsRow
        label={t("Analytics")}
        secondaryLabel={t(
          "These cookies help us improve the quality of the service by measuring performance and usage.",
        )}
      >
        <SwitchContainer>
          {analyticsCookies === undefined ? (
            <ActivityIndicator size="medium" />
          ) : (
            <Switch
              value={analyticsCookies === "granted"}
              onValueChange={(on) =>
                setAnalyticsCookies(on ? "granted" : "denied")
              }
            />
          )}
        </SwitchContainer>
      </SettingsRow>
      <SettingsRow
        label={t("Advertising")}
        secondaryLabel={t(
          "These cookies help us grow the service by measuring advertising campaign effectiveness.",
        )}
      >
        <SwitchContainer>
          {advertisingCookies === undefined ? (
            <ActivityIndicator size="medium" />
          ) : (
            <Switch
              value={advertisingCookies === "granted"}
              onValueChange={(on) =>
                setAdvertisingCookies(on ? "granted" : "denied")
              }
            />
          )}
        </SwitchContainer>
      </SettingsRow>
      <SettingsRow justifyContent="center">
        {manifest.externalLinks.cookiePolicy ? (
          <AppLink
            id="settings-cookie-policy"
            to={{ type: "url", url: manifest.externalLinks.cookiePolicy }}
            aria-label={t("Cookie policy")}
          >
            <Text textStyle="body" color="accent">
              {t("Cookie policy")}
            </Text>
          </AppLink>
        ) : undefined}
      </SettingsRow>
    </SettingsFieldset>
  )
}
