import React from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import styled from "styled-components/native"

import { AppCardItem } from "@treefort/api-spec"
import { clamp } from "@treefort/lib/clamp"
import { coalesceUndefined } from "@treefort/lib/coalesce-undefined"
import icons from "@treefort/tokens/app/icons"

import { AppLink } from "../../components/app-link"
import { BoxPadding } from "../../components/box"
import Column from "../../components/column"
import HorizontalListViewWithArrows from "../../components/horizontal-list-view-with-arrows"
import Icon from "../../components/icon"
import Row from "../../components/row"
import Spacer from "../../components/spacer"
import Text from "../../components/text"
import TextPlaceholder from "../../components/text-placeholder"
import { useTokens } from "../../components/tokens-provider"
import useAppManifest from "../../hooks/use-app-manifest"
import useCollection from "../../hooks/use-collection"
import useContent from "../../hooks/use-content"
import { usePageArtworkMedia } from "../../hooks/use-page-artwork-media"
import { kebabCase } from "../../lib/kebab-case"
import { spacingToNumber } from "../../lib/spacing"
import { getAbsoluteLineHeight } from "../../lib/text-style"
import { i18nKey } from "../../types/i18next"
import ButtonView from "../button-view"
import { SquareMediaThumbnail } from "../thumbnail"

type CardModuleProps = {
  items: AppCardItem[]
  paddingTop?: BoxPadding
  maxWidth?: number
  displayWidth: number
}

const MIN_ITEM_WIDTH = 275
const MAX_ITEM_WIDTH = 450
const PADDING = 24
const ACTION_BUTTON_SIZE = "small"

// Use margin between cards instead of the getGapSize prop so that the box
// shadow doesn't get clipped
const HORIZONTAL_CARD_MARGIN = 8

// The minimum item width at which the container should become side-scrollable
const OVERFLOW_MIN_WIDTH = 350

function getArtworkHeight(layout: string) {
  return layout === "desktop" ? 108 : 90
}

function getCardPadding(layout: string) {
  return layout === "desktop" ? 16 : 12
}

const getItemKey = (item: AppCardItem, i: number) =>
  `${item.type}-${
    item.type === "url"
      ? item.appLink.url
      : item.type === "content"
        ? item.appLink.contentId
        : item.type === "page"
          ? item.appLink.pageId
          : item.type === "collection"
            ? item.appLink.collectionId
            : (item satisfies never)
  }-${i}`

function getIsUpdateRequired(item: AppCardItem) {
  switch (item.type) {
    case "content":
    case "collection":
    case "page":
    case "url":
      return false
    default:
      item satisfies never
      return true
  }
}

const CardContainer = styled(Row)<{
  width?: number
  modulePaddingTop: number
  layout: "mobile" | "desktop"
}>`
  flex: 1 1 ${MIN_ITEM_WIDTH}px;
  border-radius: ${({ theme }) => theme.borderRadius.roundedMedium}px;
  margin: ${({ modulePaddingTop }) => modulePaddingTop}px
    ${HORIZONTAL_CARD_MARGIN}px ${PADDING}px ${HORIZONTAL_CARD_MARGIN}px;
  width: ${({ width }) => width}px;
  min-width: ${MIN_ITEM_WIDTH}px;
  max-width: ${MAX_ITEM_WIDTH}px;
  height: ${({ layout }) =>
    getArtworkHeight(layout) + getCardPadding(layout) * 2}px;
  background-color: ${({ theme }) => theme.colors.background.primary};
  ${Platform.OS === "android"
    ? // Use the elevation api on Android since it doesn't support box-shadows
      `elevation: 8;
       shadow-color: rgba(0, 0, 0, .15)`
    : "box-shadow: 0 4px 6px rgba(0, 0, 0, 0.045);"};
`

const StyledAppLink = styled(AppLink)<{ layout: "mobile" | "desktop" }>`
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  padding: ${({ layout }) => getCardPadding(layout)}px;
`

function Card({
  item,
  layout,
  width,
  paddingTop,
}: {
  item: AppCardItem
  layout: "mobile" | "desktop"
  width?: number
  paddingTop: number
}) {
  const { tokens } = useTokens()
  const { t } = useTranslation()
  const manifest = useAppManifest()
  const content = useContent(
    item.type === "content" ? item.appLink.contentId : undefined,
  )
  const pageArtworkMedia = usePageArtworkMedia(
    item.type === "page" ? item.appLink.pageId : undefined,
  )
  const page =
    item.type === "page"
      ? manifest.pages.find((page) => page.id === item.appLink.pageId)
      : undefined
  const collection = useCollection(
    item.type === "collection" ? item.appLink.collectionId : undefined,
  )

  // This will be null if the card should not have artwork, undefined if the
  // artwork is loading, or a media object/array if the artwork has loaded.
  const artworkMedia = coalesceUndefined(
    typeof item.artwork === "string"
      ? { type: "url" as const, id: item.artwork, url: item.artwork }
      : item.artwork,
    content.data?.artworkMedia,
    pageArtworkMedia,
    collection.data?.content.slice(0, 4).map((content) => content.artworkMedia),
  )

  const isUpdateRequired = getIsUpdateRequired(item)
  const title =
    (isUpdateRequired && "Update Required") ||
    ((item.title ||
      content.data?.title ||
      page?.title ||
      collection.data?.title) as i18nKey)
  const subtitleGap = layout === "desktop" ? "small" : "tiny"
  const subtitle = coalesceUndefined(
    (isUpdateRequired && "An update is required to view this content.") ||
      (item.subtitle as i18nKey),
    content.data?.description as i18nKey,
  )
  const isLoading =
    (item.type === "content" && !content.data) ||
    (item.type === "collection" && !collection.data)

  return (
    <CardContainer width={width} modulePaddingTop={paddingTop} layout={layout}>
      <StyledAppLink
        id={kebabCase(`card-${item.type}-${content.data?.id}-${title}`)}
        to={item.appLink}
        layout={layout}
        containerStyle={{
          width: "100%",
        }}
      >
        {artworkMedia !== null ? (
          <SquareMediaThumbnail
            size={getArtworkHeight(layout)}
            media={artworkMedia}
          />
        ) : null}
        <Spacer horizontal size={getCardPadding(layout)} />
        <Column flex={1} alignItems="flex-start">
          {isLoading ? (
            <TextPlaceholder
              textStyle="headingSmall"
              maxWidth={MIN_ITEM_WIDTH * 0.25}
            />
          ) : (
            <Text
              textStyle="headingSmall"
              // Let the title take up two lines if either subtitle or action
              // isn't set
              numberOfLines={!subtitle || !item.action ? 2 : 1}
            >
              {t(title)}
            </Text>
          )}
          {isLoading ? (
            <>
              <Spacer size={subtitleGap} />
              <TextPlaceholder textStyle="caption" />
            </>
          ) : subtitle ? (
            <>
              <Spacer size={subtitleGap} />
              <Text textStyle="caption" numberOfLines={1}>
                {t(subtitle)}
              </Text>
            </>
          ) : null}
          {item.action ? (
            <>
              <Spacer
                // Align the action button with the bottom of the artwork if the
                // card has both a title and subtitle or if the card has no
                // subtitle and the title takes up two lines
                size={
                  getArtworkHeight(layout) -
                  tokens.button.height[ACTION_BUTTON_SIZE] -
                  (!subtitle
                    ? getAbsoluteLineHeight("headingSmall", tokens) * 2
                    : getAbsoluteLineHeight("headingSmall", tokens) +
                      tokens.spacing[subtitleGap] +
                      getAbsoluteLineHeight("caption", tokens))
                }
              />
              <ButtonView type="primary" size={ACTION_BUTTON_SIZE}>
                <Text
                  textStyle="button"
                  color={tokens.button.color.primary}
                  numberOfLines={1}
                >
                  {t(item.action as i18nKey)}
                </Text>
              </ButtonView>
            </>
          ) : null}
        </Column>
        <Spacer horizontal size="small" />
        <Icon source={icons.chevronRight} />
      </StyledAppLink>
    </CardContainer>
  )
}

export function CardModule({
  items,
  paddingTop: paddingTopProp = PADDING,
  maxWidth,
  displayWidth,
}: CardModuleProps) {
  const { tokens } = useTokens()
  const paddingTop = spacingToNumber(tokens, paddingTopProp)
  const layout =
    displayWidth < tokens.breakpoints.desktop ? "mobile" : "desktop"
  const moduleHeight =
    PADDING + paddingTop + getArtworkHeight(layout) + getCardPadding(layout) * 2

  // The item width to use in the mobile layout when there are multiple items to
  // ensure that the user can see a "peek" of the next item and know that the
  // module is side scrollable
  const peekWidth = (displayWidth - PADDING * 2) * 0.965

  // The optimal item width, clamped at MIN_ITEM_WIDTH and MAX_ITEM_WIDTH
  const itemWidth = clamp(
    layout === "mobile" && items.length === 1
      ? // If there's only one item in the mobile layout then fit it to
        // the width of the screen
        displayWidth - PADDING * 2
      : layout === "mobile" && peekWidth <= MAX_ITEM_WIDTH
        ? // Use the peek width in the mobile layout if it's less than the max width
          peekWidth
        : // Otherwise use the max width
          MAX_ITEM_WIDTH,
    MIN_ITEM_WIDTH,
    MAX_ITEM_WIDTH,
  )

  // Whether or not the items should be shown in a side-scrolling container
  const isScrollable =
    items.length * OVERFLOW_MIN_WIDTH +
      (items.length - 1) * (HORIZONTAL_CARD_MARGIN * 2) +
      PADDING * 2 >
    displayWidth

  // If there are less than three items then left align them with content in the
  // landscape layout
  const paddingLeft =
    tokens.artworkFullPage.layout === "landscape" &&
    maxWidth &&
    items.length < 3
      ? Math.max(
          spacingToNumber(tokens, "pagePaddingHorizontal"),
          (displayWidth - maxWidth) / 2,
        ) - HORIZONTAL_CARD_MARGIN
      : undefined

  return isScrollable ? (
    <HorizontalListViewWithArrows
      id={null}
      scrollEventThrottle={8}
      style={{
        backgroundColor: tokens.colors.background.tertiary,
        flexGrow: 0,
      }}
      items={items}
      viewSize={{ width: displayWidth, height: moduleHeight }}
      getItemKey={getItemKey}
      getItemSize={() => itemWidth + HORIZONTAL_CARD_MARGIN * 2}
      renderItem={(item) => (
        <Card
          paddingTop={paddingTop}
          item={item}
          width={itemWidth}
          layout={layout}
        />
      )}
      arrowButtonHeight={moduleHeight}
      getGapSize={() => 0}
      paddingStart={PADDING - HORIZONTAL_CARD_MARGIN}
      paddingEnd={PADDING - HORIZONTAL_CARD_MARGIN}
    />
  ) : (
    <Row
      justifyContent={paddingLeft ? "flex-start" : "center"}
      height={moduleHeight}
      paddingLeft={paddingLeft}
      paddingHorizontal={
        paddingLeft ? undefined : PADDING - HORIZONTAL_CARD_MARGIN
      }
      backgroundColor={tokens.colors.background.tertiary}
    >
      {items.map((item, i) => (
        <Card
          key={getItemKey(item, i)}
          paddingTop={paddingTop}
          item={item}
          width={itemWidth}
          layout={layout}
        />
      ))}
    </Row>
  )
}
