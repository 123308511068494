import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform, View } from "react-native"

import { isAndroid } from "@braintree/browser-detection"
import { withObservables } from "@nozbe/watermelondb/react"
import { map, of } from "rxjs"
import styled from "styled-components/native"

import { useAuth } from "@treefort/lib/auth-provider"
import { joinContributorNames } from "@treefort/lib/contributor"
import { DisplayableError } from "@treefort/lib/displayable-error"
import { EventEmitter } from "@treefort/lib/event-emitter"
import icons from "@treefort/tokens/app/icons"

import config from "../../config"
import { useActiveProfileId } from "../../hooks/use-active-profile-id"
import useAppManifest from "../../hooks/use-app-manifest"
import { useEventEmitterValue } from "../../hooks/use-event-emitter-value"
import analytics from "../../lib/analytics"
import authenticator from "../../lib/authenticator"
import confirm from "../../lib/confirm"
import { EbookReaderEvent, ebookReader } from "../../lib/ebook-reader"
import { HIGHLIGHT_COLOR_PRESETS } from "../../lib/ebook-reader"
import { logError } from "../../lib/logging"
import { isParent } from "../../lib/parental-gateway"
import { canShareUrl, shareUrl } from "../../lib/share-url"
import {
  SEARCH_TAB,
  getPathFromContent,
  getRouteFromPath,
} from "../../navigation/routes"
import { HighlightData } from "../../watermelon/models/highlight"
import { highlightStore } from "../../watermelon/stores/highlight"
import Box from "../box"
import TextInput, { TextInputRef } from "../form-text-input"
import IconButton from "../icon-button"
import Modal from "../modal"
import Row from "../row"
import { useTokens } from "../tokens-provider"
import Touchable from "../touchable"

// Padding necessary to avoid the "tap to see search results" UI foisted upon us
// by Android Chrome
const ANDROID_CHROME_TAP_TO_SEARCH_INSET = 96
const DEBOUNCE_UPDATE_NOTE_MS = 800

class Controller extends EventEmitter<{
  highlightChanged: HighlightData | undefined
  isModalOpenChanged: boolean
}> {
  private highlight: HighlightData | undefined = undefined
  private isModalOpen = false

  getHighlight = () => this.highlight
  getIsModalOpen = () => this.isModalOpen

  setHighlight = (highlight: HighlightData | undefined) => {
    this.highlight = highlight
    this.emitter.emit("highlightChanged", highlight)
  }

  /** NOTE: This shouldn't be called outside of `highlight-detail.tsx`, use the
   * `setHighlight` method instead */
  _setIsModalOpen = (isModalOpen: boolean) => {
    this.isModalOpen = isModalOpen
    this.emitter.emit("isModalOpenChanged", isModalOpen)
  }
}

export const highlightDetail = new Controller()

/**
 * Returns a one-off key that can be stored in state and used to force React to
 * re-render an element
 */
function getKey() {
  return Math.round(Math.random() * 1000000).toString()
}

const SwatchTouchable = styled(Touchable).attrs({
  containerStyle: { width: "100%", flex: 1 },
})`
  height: ${({ theme }) => theme.spacing.jumbo}px;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const ActiveSwatchRing = styled.View<{
  color: string
  active: boolean
}>`
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.roundedFull}px;
  border: solid 2px
    ${({ color, theme, active }) =>
      active ? color : theme.colors.background.primary};
`

const Swatch = styled.View<{ color: string; active: boolean }>`
  width: ${({ theme }) => theme.spacing.large}px;
  height: ${({ theme }) => theme.spacing.large}px;
  background-color: ${({ color }) => color};
  border-radius: ${({ theme }) => theme.borderRadius.roundedFull}px;
  border: ${({ theme, active }) =>
    active ? `solid 2px ${theme.colors.background.primary}` : "none"};
`

/**
 * Helper that, if enabled, blocks touches on its children and runs an alternate
 * onPress handler.
 */
function TouchInterceptor({
  id,
  enabled,
  onPress,
  children,
}: {
  id: string
  enabled: boolean
  onPress: () => unknown
  children: React.ReactNode
}) {
  if (enabled) {
    return (
      <Touchable id={id} cursor="default" onPress={onPress}>
        <View pointerEvents="none">{children}</View>
      </Touchable>
    )
  } else {
    return <>{children}</>
  }
}

/**
 * Make sure the user is authenticated before allowing them to interact with the
 * highlight UI
 */
function RequireAccount({ children }: { children: React.ReactNode }) {
  const manifest = useAppManifest()
  const auth = useAuth()
  const { t } = useTranslation()

  const { parentalGateway } = manifest.features
  const promptCreateFreeAccount = async () => {
    if (
      await confirm({
        title: t("Account Required"),
        message: t(
          "You must have an account to highlight and take notes. Would you like to create one now?",
        ),
        confirmLabel: t("Create FREE Account"),
        cancelLabel: t("Cancel"),
      })
    ) {
      if (!parentalGateway || (await isParent())) {
        authenticator.register()
      }
    }
  }

  return (
    <TouchInterceptor
      id="interceptor-prompt-create-free-account"
      enabled={!auth.user}
      onPress={promptCreateFreeAccount}
    >
      {children}
    </TouchInterceptor>
  )
}

function HighlightDetailBase({
  contentId,
  viewerStatus,
  highlights,
}: {
  contentId: number
  viewerStatus: "rendered" | "error" | "loading" | "resizing"
  highlights: HighlightData[]
}) {
  const { t } = useTranslation()
  const manifest = useAppManifest()
  const { tokens } = useTokens()
  const profileId = useActiveProfileId()
  const auth = useAuth()
  const [selection, setSelection] = useState<{
    startCfi: string
    endCfi: string
    selectedText: string
    sectionId?: string
    progressPercent?: number
  } | null>(null)
  const [isSelectionChanging, setIsSelectionChanging] = useState(false)
  const [isSelection, setIsSelection] = useState(false)
  const isModalOpen = useEventEmitterValue(
    highlightDetail,
    "isModalOpenChanged",
    highlightDetail.getIsModalOpen,
  )
  const highlight = useEventEmitterValue(
    highlightDetail,
    "highlightChanged",
    highlightDetail.getHighlight,
  )

  // The text input is uncontrolled to avoid an odd flickering behavior on
  // native that makes it difficult or impossible to type, so we need a way to
  // force it to re-render sometimes so the wrong note isn't shown
  const [inputKey, setInputKey] = useState<string | undefined>(undefined)

  const input = useRef<TextInputRef>(null)
  const inputTapIntercepted = useRef(false)

  const handleClose = useCallback(() => {
    setSelection(null)
    highlightDetail.setHighlight(undefined)
    highlightDetail._setIsModalOpen(false)
    input.current?.blur()
  }, [])

  const addHighlight = async (highlight: {
    colorPresetId: number
    notes: string | null
    selectedText: string
    sectionId?: string
    progressPercent?: number
    startCfi: string
    endCfi: string
  }) => {
    try {
      if (!auth.user) {
        throw new Error(
          "[Highlights] Cannot add highlight for unauthenticated user",
        )
      }
      return (
        await highlightStore.create({
          ...highlight,
          userId: auth.user.id,
          contentId,
          profileId,
        })
      ).toPlainObject()
    } catch (cause) {
      logError(
        new DisplayableError(
          t("Something went wrong, please try again."),
          new Error("[Highlights] Failed to add highlight", { cause }),
        ),
      )
    }
  }

  const deleteHighlight = async (id: string) => {
    try {
      await highlightStore.delete(id)
    } catch (cause) {
      logError(
        new DisplayableError(
          t("Something went wrong, please try again."),
          new Error(`[Highlights] Failed to delete highlight with id ${id}`, {
            cause,
          }),
        ),
      )
    }
  }

  const timeout = useRef<NodeJS.Timeout>()
  const updateHighlightNote = async (id: string, notes: string) => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(async () => {
      try {
        await highlightStore.update(id, { notes })
      } catch (cause) {
        logError(
          new DisplayableError(
            t("Something went wrong, please try again."),
            new Error(
              `[Highlights] Failed to update notes for highlight with id ${id}`,
              {
                cause,
              },
            ),
          ),
        )
      }
    }, DEBOUNCE_UPDATE_NOTE_MS)
  }

  const selectColor = async (colorPresetId: number) => {
    if (selection && !highlight) {
      highlightDetail.setHighlight(
        await addHighlight({
          notes: null,
          colorPresetId,
          ...selection,
        }),
      )
    } else if (highlight && highlight.colorPresetId !== colorPresetId) {
      highlightDetail.setHighlight({ ...highlight, colorPresetId })
      try {
        await highlightStore.update(highlight.id, { colorPresetId })
      } catch (cause) {
        logError(
          new DisplayableError(
            t("Something went wrong, please try again."),
            new Error(
              `[Highlights] Failed to update color for highlight with id ${highlight.id}`,
              {
                cause,
              },
            ),
          ),
        )
      }
    }
  }

  // Mark highlights when the book loads or highlights change
  useEffect(() => {
    if (viewerStatus === "rendered") {
      ebookReader.markAllHighlights(highlights)
    }
  }, [viewerStatus, highlights])

  // Open the highlight detail modal when the highlight state is set
  const prevHighlight = useRef<HighlightData>()
  useEffect(() => {
    if (highlight && highlight.id !== prevHighlight.current?.id) {
      // Don't rerender the text input if the user might've just created a
      // highlight by tapping the text input. Otherwise they would have to tap
      // twice before being able to type
      if (prevHighlight.current) {
        setInputKey(getKey())
      }
      highlightDetail._setIsModalOpen(true)
    }

    prevHighlight.current = highlight
  }, [highlight])

  // Set the highlight state when a user taps an existing highlight
  useEffect(
    () =>
      ebookReader.on(EbookReaderEvent.TapHighlight, async ({ id }) => {
        const highlight = highlights.find((highlight) => highlight.id === id)
        if (highlight) {
          // Rerender the input when a highlight is tapped and no highlight was
          // previously selected. If a highlight was previously selected, then
          // the effect before this one will take care of rerendering the input
          if (!prevHighlight.current) {
            setInputKey(getKey())
          }
          highlightDetail.setHighlight(highlight)
        }
      }),
    [highlights],
  )

  // Handle the edge case where an unauthenticated user makes a selection on
  // native identical to a highlight they saved when signed in, and then presses
  // the "Create Free Account" option in the prompt, and then signs into their
  // existing account
  const prevHighlights = useRef(highlights)
  useEffect(() => {
    const existingHighlight = highlights.find(
      (highlight) =>
        highlight.startCfi === selection?.startCfi &&
        highlight.endCfi === highlight.endCfi,
    )
    if (
      !prevHighlights.current.length &&
      highlights.length &&
      existingHighlight
    ) {
      // Clear the selection state and swap in the existing highlight
      setSelection(null)
      setInputKey(getKey())
      highlightDetail.setHighlight(existingHighlight)
    }
    prevHighlights.current = highlights
  }, [selection, highlights])

  // Close the highlight modal when the ebook is resized or loading
  useEffect(() => {
    if (viewerStatus !== "rendered") {
      handleClose()
    }
  }, [handleClose, viewerStatus])

  // Close the highlight modal when:
  // - we receive a tap event from the ebook viewer
  // - the user begins a new text selection
  // - the user is closing the ebook viewer
  useEffect(
    () =>
      ebookReader.on(
        [
          EbookReaderEvent.Tap,
          EbookReaderEvent.NewTextSelectionStarted,
          EbookReaderEvent.CloseRequested,
        ],
        handleClose,
      ),
    [handleClose],
  )

  // Close the modal when the user clears their text selection if they haven't
  // begun interacting with a highlight
  useEffect(
    () =>
      ebookReader.on(EbookReaderEvent.TextSelectionCleared, () => {
        setSelection(null)
        if (!highlight) {
          handleClose()
        }
      }),
    [handleClose, highlight],
  )

  // Close the modal when the user changes pages
  const prevCfi = useRef<string>()
  useEffect(
    () =>
      ebookReader.on(EbookReaderEvent.LocationChanged, (event) => {
        if (event.current?.type === "rendered") {
          if (event.current.location.type === "epubCfi") {
            const cfi = event.current.location.epubCfi
            if (cfi !== prevCfi.current) {
              handleClose()
            }
            prevCfi.current = cfi
          }
        }
      }),
    [handleClose],
  )

  // Listen for when the user finishes making a text selection
  useEffect(
    () =>
      ebookReader.on(EbookReaderEvent.TextSelection, async (selection) => {
        setInputKey(getKey())
        setSelection(selection)
        setIsSelectionChanging(false)
        highlightDetail._setIsModalOpen(true)

        // If the user selected a range that is identical to a highlight
        // they already made then just open that highlight
        const existingHighlight = highlights.find(
          ({ startCfi, endCfi }) =>
            selection.startCfi === startCfi && selection.endCfi === endCfi,
        )

        if (existingHighlight) {
          highlightDetail.setHighlight(existingHighlight)
        }
      }),
    [highlights],
  )

  // Listen for when the user's existing text selection starts to change
  useEffect(
    () =>
      ebookReader.on(EbookReaderEvent.CurrentTextSelectionChangeStarted, () => {
        setIsSelectionChanging(true)
      }),
    [highlight, handleClose],
  )

  // Track when the user has started making a selection
  useEffect(
    () =>
      ebookReader.on(EbookReaderEvent.NewTextSelectionStarted, () =>
        setIsSelection(true),
      ),
    [],
  )

  // Track when the user clears their selection
  useEffect(
    () =>
      ebookReader.on(EbookReaderEvent.TextSelectionCleared, () =>
        setIsSelection(false),
      ),
    [],
  )

  // On iOS input.focus() is more reliable than autoFocus
  useEffect(() => {
    if (highlight && inputTapIntercepted.current) {
      input.current?.focus()
    }
  }, [highlight])

  return (
    <Modal
      open={isModalOpen && !isSelectionChanging}
      type="roundedSheet"
      margin={tokens.spacing.xsmall}
      marginBottom={
        // On Android Chrome when the user makes a text selection then "Tap to
        // see search results" is shown in some UI that slides up from the bottom.
        // This logic scoots the highlight detail modal up and out of the way
        Platform.OS === "web" && isSelection && isAndroid()
          ? ANDROID_CHROME_TAP_TO_SEARCH_INSET
          : tokens.spacing.medium
      }
      showScrim={false}
      showBoxShadow
      showBorder
      maxWidth={
        // Width of the buttons plus the share button and/or the trash button
        tokens.spacing.jumbo *
          (HIGHLIGHT_COLOR_PRESETS.length + (canShareUrl() ? 2 : 1)) +
        // Give the buttons a little more space if we're not showing the share
        // button so that the highlight modal isn't too narrow
        (canShareUrl() ? 0 : tokens.spacing.medium)
      }
      floatingAlignmentHorizontal="center"
      floatingAlignmentVertical="bottom"
      keyboardAvoidingViewBehavior="height"
    >
      <RequireAccount>
        <Box padding="xsmall" paddingBottom="none">
          <TouchInterceptor
            id="create-highlight-for-note"
            // If a highlight hasn't been created then intercept any tap on the
            // note input and create one. This gives us a highlight to attach
            // the note to and also prevents an input focus event from clearing
            // the user's text selection prematurely.
            enabled={!highlight}
            onPress={() => {
              inputTapIntercepted.current = true
              selectColor(HIGHLIGHT_COLOR_PRESETS[0].id)
            }}
          >
            <TextInput
              key={inputKey}
              autoGrow
              // On Android autoFocus is more reliable than input.focus()
              autoFocus={inputTapIntercepted.current}
              dataSet={{ webScrollUnlock: true }}
              ref={input}
              size="small"
              placeholder={t("Enter a note...")}
              editable={Boolean(auth.user) && Boolean(highlight)}
              // Using a controlled text input can lead to an odd flickering
              // behavior on native that makes it difficult or impossible to
              // type
              defaultValue={highlight?.notes || ""}
              onFocus={() => {
                inputTapIntercepted.current = false
              }}
              onChangeText={(notes) => {
                if (highlight) {
                  highlightDetail.setHighlight({ ...highlight, notes })
                  updateHighlightNote(highlight.id, notes)
                }
              }}
            />
          </TouchInterceptor>
        </Box>
        <Row justifyContent="center">
          {HIGHLIGHT_COLOR_PRESETS.map((colorPreset) => {
            const active = highlight?.colorPresetId === colorPreset.id
            return (
              <SwatchTouchable
                id={`highlight-select-color-${colorPreset.id}`}
                key={colorPreset.id}
                onPress={() => {
                  selectColor(colorPreset.id)
                  input.current?.blur()
                }}
              >
                <ActiveSwatchRing color={colorPreset.fill} active={active}>
                  <Swatch color={colorPreset.fill} active={active} />
                </ActiveSwatchRing>
              </SwatchTouchable>
            )
          })}
          {canShareUrl() ? (
            <IconButton
              id="ebook-share-url"
              source={manifest.icons.share}
              label={t("Share")}
              onPress={() => {
                const path = getPathFromContent(contentId, "ebook", SEARCH_TAB)
                const url = `https://${config.DOMAIN_NAME}${path}`
                const ebook = ebookReader.getEbook()
                const title = ebook?.title
                const authors = joinContributorNames(
                  ebook?.extra.consumableContent.content.contributors,
                  "author",
                )
                const selectedText =
                  highlight?.selectedText || selection?.selectedText

                shareUrl({
                  url,
                  title: [`“${selectedText}”`, title, authors]
                    .filter(Boolean)
                    .join("\n"),
                }).then((success) => {
                  if (success) {
                    analytics.logShare(
                      getRouteFromPath(path, manifest),
                      manifest,
                    )
                  }
                })
              }}
            />
          ) : null}
          <IconButton
            id="ebook-delete-highlight"
            source={icons.trash}
            label={t("Delete")}
            onPress={() => {
              if (highlight) {
                deleteHighlight(highlight.id)
              }
              handleClose()
            }}
          />
        </Row>
      </RequireAccount>
    </Modal>
  )
}

const enhance = withObservables(
  ["contentId", "userId", "profileId"],
  ({
    contentId,
    userId,
    profileId,
  }: {
    contentId: number
    userId?: string
    profileId: string | null
  }) => ({
    highlights: userId
      ? highlightStore
          .findBy({
            contentId,
            userId,
            profileId,
          })
          .observeWithColumns(["updatedAtDate"])
          .pipe(
            // Sort highlights oldest to newest so that they're marked in the
            // ebook in the order that the user made them
            map((highlights) =>
              highlights
                .map((highlight) => highlight.toPlainObject())
                .sort((a, b) =>
                  !a.createdAtDate && !b.createdAtDate
                    ? 0
                    : !a.createdAtDate
                      ? 1
                      : !b.createdAtDate
                        ? -1
                        : a.createdAtDate - b.createdAtDate,
                ),
            ),
          )
      : of([] as HighlightData[]),
  }),
)

export const HighlightDetail = enhance(HighlightDetailBase)
