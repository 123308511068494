import React, { useCallback, useState, useRef, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Animated } from "react-native"

import styled from "styled-components/native"

import { VideoResponse } from "@treefort/api-spec"
import icons from "@treefort/tokens/app/icons"
import { aspectRatios } from "@treefort/tokens/app/tokens/base"

import useAppManifest from "../hooks/use-app-manifest"
import { useAppTab } from "../hooks/use-app-tab"
import { useNavigate } from "../hooks/use-navigate"
import { getMetadataFromContent } from "../lib/content"
import { startAnimation } from "../lib/start-animation"
import { getAbsoluteLineHeight } from "../lib/text-style"
import { VideoPlayer, Event } from "../lib/video-player"
import { getPathFromAppLink } from "../navigation/routes"
import { Button } from "./button"
import Column from "./column"
import Icon from "./icon"
import Image from "./image"
import ProgressCircle from "./progress-circle"
import Row from "./row"
import Spacer from "./spacer"
import Text from "./text"
import TokensProvider, { ResolvedTokens, useTokens } from "./tokens-provider"

type Layout = "tallWide" | "tallNarrow" | "short"

const PLAY_NEXT_COUNTDOWN_MS = 5000
const NARROW_SHORT_ARTWORK_HEIGHT = 76
const CONTENT_MAX_WIDTH = 685
const SHORT_CONTENT_MAX_WIDTH = 375
const TALL_WIDE_WIDTH_BRK_POINT = 1024
const TALL_NARROW_HEIGHT_BRK_POINT = 425

/**
 * Decide on a layout based on player dimensions
 */
function getLayout({ height, width }: { height: number; width: number }) {
  return width >= TALL_WIDE_WIDTH_BRK_POINT
    ? "tallWide"
    : width < height || height >= TALL_NARROW_HEIGHT_BRK_POINT
      ? "tallNarrow"
      : "short"
}

/**
 * Calculate a max width for the lockup container that will constrain the height
 * of the lockup to the height arg
 */
function getContainerMaxWidthForLayout({
  height,
  layout,
  tokens,
}: {
  height: number
  layout: Layout
  tokens: ResolvedTokens
}) {
  // For the tall layouts w = (h - constants) * artwork aspect ratio
  switch (layout) {
    case "tallWide":
      return Math.min(
        (height -
          tokens.spacing.large -
          getAbsoluteLineHeight("headingLarge", tokens) * 2 -
          tokens.spacing.medium -
          getAbsoluteLineHeight("body", tokens)) *
          tokens.aspectRatios.wide,
        CONTENT_MAX_WIDTH,
      )
    case "tallNarrow":
      return Math.min(
        (height -
          tokens.spacing.large -
          getAbsoluteLineHeight("headingMedium", tokens) * 2 -
          tokens.spacing.small -
          getAbsoluteLineHeight("caption", tokens) -
          tokens.spacing.large -
          tokens.button.height.medium) *
          aspectRatios.wide,
        CONTENT_MAX_WIDTH,
      )
    case "short":
      // Fine to use a constant since we don't need to worry about the short
      // layout overflowing vertically
      return SHORT_CONTENT_MAX_WIDTH
  }
}

const Container = styled.View`
  position: absolute;
  background-color: ${(props) => props.theme.colors.black};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
`

const OverlayContent = styled.View<{ maxWidth?: number | string }>`
  width: 100%;
  max-width: ${({ maxWidth }) =>
    !maxWidth || typeof maxWidth === "number"
      ? `${maxWidth || CONTENT_MAX_WIDTH}px`
      : maxWidth};
  padding: ${({ theme }) => theme.spacing.medium}px;
`

const Artwork = styled(Image)<{ height?: number | string; width?: number }>`
  background-color: ${(props) => props.theme.colors.loading.image};
  width: ${({ width }) => (width ? width + "px" : "100%")};
  ${({ theme, height }) =>
    height
      ? `height: ${typeof height === "number" ? `${height}px` : height}`
      : `aspect-ratio: ${theme.artworkListItem.shape["wide"].aspectRatio}`};
  border-radius: 10px;
`

const StyledButton = styled(Button)`
  flex: 1;
`

const ProgressCircleContainer = styled.View`
  position: relative;
`

const PlayIconContainer = styled.View`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

function NextEpisodeButton({
  goToNext,
  animatedValue,
}: {
  goToNext: () => void
  animatedValue: Animated.Value
}) {
  const { tokens } = useTokens()
  const color = tokens.button.color.primary
  const { t } = useTranslation()
  return (
    <StyledButton
      id="video-play-next-episode"
      type="primary"
      onPress={goToNext}
      containerStyle={{ flex: 1, height: tokens.button.height.medium }}
    >
      <Text textStyle="button" color={color} numberOfLines={1}>
        {t("Next")}
      </Text>
      <Spacer horizontal size="small" />
      <ProgressCircleContainer>
        <ProgressCircle
          size={22}
          color={color}
          trackColor="rgba(0, 0, 0, .15)"
          animatedValue={animatedValue}
          backgroundColor={tokens.button.backgroundColor.primary}
        />
        <PlayIconContainer>
          <Icon source={icons.play} size="xsmall" color={color} />
        </PlayIconContainer>
      </ProgressCircleContainer>
    </StyledButton>
  )
}

export function VideoPlayNextOverlay({
  videoPlayer,
  nextVideo,
  playerDimensions: { width, height },
}: {
  videoPlayer?: VideoPlayer
  nextVideo?: VideoResponse
  playerDimensions: { width: number; height: number }
}) {
  const { tokens } = useTokens()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const tab = useAppTab()
  const manifest = useAppManifest()
  const [showOverlay, setShowOverlay] = useState(false)

  const layout = getLayout({ width, height })

  const animatedValue = useRef(new Animated.Value(1))

  const onCancel = () => {
    animatedValue.current.resetAnimation()
    setShowOverlay(false)
  }

  const onGoToNext = useCallback(() => {
    if (nextVideo) {
      animatedValue.current.resetAnimation()
      setShowOverlay(false)
      navigate(
        getPathFromAppLink(
          {
            type: "content",
            contentId: nextVideo.id,
            contentType: "video",
          },
          tab,
          manifest,
        ),
      )
    }
  }, [nextVideo, navigate, tab, manifest])

  // Suspend the player when a video finishes. Show the overlay if there's a
  // next video
  useEffect(() => {
    if (videoPlayer) {
      return videoPlayer.on(Event.Finished, async () => {
        await videoPlayer.suspend()
        if (nextVideo) {
          setShowOverlay(true)
        }
      })
    }
  }, [nextVideo, videoPlayer])

  // Start the countdown animation for the next video and navigate to the next
  // video page when the animation finishes
  useEffect(() => {
    if (showOverlay) {
      const animation = Animated.timing(animatedValue.current, {
        toValue: 0,
        duration: PLAY_NEXT_COUNTDOWN_MS,
        useNativeDriver: true,
      })
      const cleanup = startAnimation(animation, () => {
        onGoToNext()
      })
      return () => {
        cleanup()
        setShowOverlay(false)
      }
    }
  }, [showOverlay, onGoToNext])

  const buttons = (
    <>
      <StyledButton
        id="video-cancel-play-next-episode"
        type="secondary"
        containerStyle={{ flex: 1, height: tokens.button.height.medium }}
        onPress={onCancel}
      >
        {t("Cancel")}
      </StyledButton>
      <NextEpisodeButton
        goToNext={onGoToNext}
        animatedValue={animatedValue.current}
      />
    </>
  )

  if (!showOverlay || !nextVideo) {
    return null
  }

  const { artwork, title, subtitle } = getMetadataFromContent(nextVideo)

  return (
    <TokensProvider displayMode="dark">
      <Container>
        <OverlayContent
          maxWidth={getContainerMaxWidthForLayout({
            layout,
            // Include some extra vertical padding for the tall layouts
            height: height - tokens.spacing.medium * 2,
            tokens,
          })}
        >
          {layout === "tallWide" ? (
            <>
              <Artwork source={{ uri: artwork }} />
              <Spacer size="large" />
              <Row alignItems="flex-start" gap="small">
                <Column flex={1} alignItems="flex-start" gap="medium">
                  <Text textStyle="headingLarge" numberOfLines={2}>
                    {title}
                  </Text>
                  {subtitle ? (
                    <Text textStyle="body" color="secondary" numberOfLines={1}>
                      {subtitle}
                    </Text>
                  ) : null}
                </Column>
                <Row flex={1} justifyContent="flex-end" gap="medium">
                  {buttons}
                </Row>
              </Row>
            </>
          ) : layout === "tallNarrow" ? (
            <>
              <Artwork source={{ uri: artwork }} />
              <Spacer size="large" />
              <Text textStyle="headingMedium" numberOfLines={2}>
                {title}
              </Text>
              {subtitle ? (
                <>
                  <Spacer size="small" />
                  <Text textStyle="caption" color="secondary" numberOfLines={1}>
                    {subtitle}
                  </Text>
                </>
              ) : null}
              <Spacer size="large" />
              <Row gap="medium">{buttons}</Row>
            </>
          ) : (
            <>
              <Row height={NARROW_SHORT_ARTWORK_HEIGHT} gap="medium">
                <Artwork
                  source={{ uri: artwork }}
                  height="100%"
                  width={NARROW_SHORT_ARTWORK_HEIGHT * tokens.aspectRatios.wide}
                />
                <Column
                  flex={1}
                  height="100%"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Text textStyle="headingMedium" numberOfLines={2}>
                    {title}
                  </Text>
                  {subtitle ? (
                    <Text
                      textStyle="caption"
                      color="secondary"
                      numberOfLines={1}
                    >
                      {subtitle}
                    </Text>
                  ) : null}
                </Column>
              </Row>
              <Spacer size="large" />
              <Row gap="medium">{buttons}</Row>
            </>
          )}
        </OverlayContent>
      </Container>
    </TokensProvider>
  )
}
