import React, { ReactNode, useMemo } from "react"
import { useTranslation } from "react-i18next"

import { withObservables } from "@nozbe/watermelondb/react"
import { EMPTY } from "rxjs"
import styled from "styled-components/native"

import { ANONYMOUS_USER_ID } from "@treefort/constants"
import { useAuth } from "@treefort/lib/auth-provider"

import { useActiveProfileId } from "../hooks/use-active-profile-id"
import {
  getTracksFromConsumableContent,
  ConsumableContent,
  getDurationFromConsumableContent,
} from "../lib/consumable-content"
import { debug as appDebug } from "../lib/logging"
import {
  PlayableProgressItem,
  EbookProgressItem,
  getProgressItemFromSettingValue,
  getProgressItemFromConsumableContent,
} from "../lib/progress-item"
import { Progress } from "../watermelon/models/progress"
import { progressStore } from "../watermelon/stores/progress"
import { BoxPadding } from "./box"
import ProgressBar from "./progress-bar"
import ProgressFinishedBadge from "./progress-finished-badge"
import Row from "./row"
import Spacer from "./spacer"
import Text from "./text"
import Time from "./time"

const debug = appDebug.extend("components:progressForConsumableContent")

const ProgressBarContainer = styled.View`
  flex: 1;
`

const withProgress = withObservables(
  ["consumableContent", "userId", "profileId"],
  ({
    consumableContent,
    userId,
    profileId,
  }: {
    consumableContent?: ConsumableContent
    userId?: string
    profileId: string | null
  }) => {
    if (!consumableContent) {
      debug(
        "withProgress missing consumableContent; returning EMPTY progress Observable.",
      )
      return { progress: EMPTY }
    }

    const emptyProgress = getProgressItemFromConsumableContent({
      consumableContent,
      profileId,
    })

    debug(`Subscribing single progress '${emptyProgress.getKey()}'`)

    return {
      progress: progressStore.observeSingle(emptyProgress.getKey(), {
        userId: userId || ANONYMOUS_USER_ID,
        profileId,
      }),
    }
  },
)

function getProgressItemData(progress: Progress, profileId: string | null) {
  return getProgressItemFromSettingValue({
    value: JSON.parse(progress.data),
    profileId,
  })
}

function DurationLabel({
  units,
  value,
}: {
  units: "pages" | "milliseconds"
  value: number
}) {
  const { t } = useTranslation()
  switch (units) {
    case "pages":
      return (
        <Text textStyle="caption" color="secondary">
          {t("{{number}} pages", { number: value })}
        </Text>
      )
    case "milliseconds":
      return (
        <Time units textStyle="caption" color="secondary">
          {value}
        </Time>
      )
  }
}

type ProgressProps = {
  consumableContent?: ConsumableContent
  progress?: Progress
  profileId: string | null
  flex?: number
  includeFinishedBadge?: boolean
  includeProgressBar?: boolean
  includeFinishedProgressBar?: boolean
  includeProgressLabel?: boolean
  includeDurationLabel?: boolean
  paddingTop?: BoxPadding
  paddingBottom?: BoxPadding
  paddingLeft?: BoxPadding
  paddingRight?: BoxPadding
  alignment?: "left" | "right"
  childrenBefore?: ReactNode
  childrenAfter?: ReactNode
  className?: string
}

/**
 * Displays real-time progress for playable content. Can be configured to show
 * any combination of time (duration or time left), a finished badge, and a
 * linear progress bar.
 */
const ObservableProgress = withProgress(function Progress({
  consumableContent,
  progress,
  profileId,
  flex,
  includeFinishedBadge,
  includeProgressBar,
  includeFinishedProgressBar,
  includeProgressLabel,
  includeDurationLabel,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  alignment = "left",
  childrenBefore = null,
  childrenAfter = null,
}: ProgressProps): JSX.Element | null {
  const progressItem = progress
    ? getProgressItemData(progress, profileId)
    : undefined

  const tracks = useMemo(
    () => getTracksFromConsumableContent({ consumableContent, profileId }),
    [consumableContent, profileId],
  )

  const { t } = useTranslation()

  const getProgressBar = ({
    finished,
    position,
    duration,
    percent,
  }: {
    finished: boolean
    position?: number
    duration?: number
    percent?: number
  }): ReactNode | undefined => {
    if (!includeProgressBar) {
      return undefined
    }

    if (finished) {
      if (!includeFinishedProgressBar) {
        return undefined
      }

      return <ProgressBar progress={1} />
    }

    if (percent) {
      return <ProgressBar progress={percent} />
    }

    if (position && duration) {
      return <ProgressBar progress={position / duration} />
    }

    return undefined
  }

  let finishedBadge: ReactNode | undefined
  let progressBar: ReactNode | undefined
  let progressLabel: ReactNode | undefined

  // Progress elements for "playable" content (e.g. videos, audiobooks)
  if (progressItem instanceof PlayableProgressItem) {
    const { finished } = progressItem.getProgress()
    const duration = progressItem.getTotalDuration(tracks) || 0
    const position = tracks ? progressItem.getOverallPosition(tracks) : 0

    finishedBadge =
      includeFinishedBadge && finished ? <ProgressFinishedBadge /> : undefined
    progressLabel =
      includeProgressLabel && duration && position && !finished ? (
        <Time
          units
          textStyle="caption"
          color="secondary"
          stringKey="{{time}} left"
        >
          {
            // HACK: On occasion the position will be greater than the
            // duration at the very end of a track. This is because position
            // is always exact while duration is sometimes an estimate.
            Math.max(duration - position, 0)
          }
        </Time>
      ) : undefined
    progressBar = getProgressBar({ finished, position, duration })
  }
  // Progress elements for ebooks
  else if (
    progressItem instanceof EbookProgressItem &&
    consumableContent?.type === "ebook"
  ) {
    const { percent, finished } = progressItem.getProgress()
    finishedBadge =
      includeFinishedBadge && finished ? <ProgressFinishedBadge /> : undefined
    progressLabel =
      includeProgressLabel && percent && !finished ? (
        <Text textStyle="caption" color="secondary">
          {t("{{number}}% complete", { number: Math.round(percent * 100) })}
        </Text>
      ) : undefined

    progressBar = getProgressBar({ finished, percent })
  }

  const displayDuration =
    (includeProgressLabel || includeDurationLabel) && !progressLabel
      ? getDurationFromConsumableContent(consumableContent)
      : undefined
  const durationLabel = displayDuration ? (
    <DurationLabel {...displayDuration} />
  ) : null

  return finishedBadge || progressLabel || durationLabel || progressBar ? (
    <>
      {childrenBefore}
      <Row
        flex={flex}
        alignItems="center"
        justifyContent={alignment == "left" ? "flex-start" : "flex-end"}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
      >
        {finishedBadge}
        {finishedBadge && (progressLabel || durationLabel) ? (
          <Spacer size="tiny" horizontal />
        ) : null}
        {progressLabel || durationLabel}
        {progressBar && (finishedBadge || progressLabel || durationLabel) ? (
          <Spacer size="small" horizontal />
        ) : null}
        {progressBar ? (
          <ProgressBarContainer>{progressBar}</ProgressBarContainer>
        ) : null}
      </Row>
      {childrenAfter}
    </>
  ) : null
})

export default function ProgressForConsumableContent(
  props: Omit<ProgressProps, "profileId">,
) {
  const userId = useAuth().user?.id
  const profileId = useActiveProfileId()

  const propsWithProfile = { ...props, profileId }

  return <ObservableProgress userId={userId} {...propsWithProfile} />
}
