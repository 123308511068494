import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import styled from "styled-components/native"

import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"
import icons from "@treefort/tokens/app/icons"

import { useTrack } from "../hooks/audio"
import useAppManifest from "../hooks/use-app-manifest"
import { useLockBodyScroll } from "../hooks/use-lock-body-scroll"
import { useOfflineState } from "../hooks/use-offline-state"
import { Track } from "../lib/audio-player"
import { kebabCase } from "../lib/kebab-case"
import { canShareUrl } from "../lib/share-url"
import Controls from "./audio-player-controls"
import Progress, { ProgressContainer } from "./audio-player-progress"
import AudioPlayerTitle from "./audio-player-title"
import IconButton from "./icon-button"
import ImageContained from "./image-contained"
import { NavigationBar } from "./system-bars/navigation-bar"
import { StatusBar } from "./system-bars/status-bar"
import { useTokens } from "./tokens-provider"

const TOP_BAR_PADDING_TOP = 18
const TOP_BAR_PADDING_HORIZONTAL = 8

const { containedImageContainerStyle } = StyleSheet.create({
  containedImageContainerStyle: { flex: 1 },
})

const Container = styled.View`
  background: ${({ theme }) => theme.colors.background.primary};
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  max-width: ${({ theme }) => theme.audioPlayerFullscreen.maxWidth}px;
  padding-bottom: ${({ theme }) =>
    theme.audioPlayerFullscreen.paddingVertical}px;
  padding-horizontal: ${({ theme }) =>
    theme.audioPlayerFullscreen.paddingHorizontal}px;
`

const ArtworkContainer = styled.View`
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-top: ${({ theme }) =>
    theme.audioPlayerFullscreen.artwork.paddingTop}px;
  padding-bottom: ${({ theme }) =>
    theme.audioPlayerFullscreen.artwork.paddingBottom}px;
`

const ControlsContainer = styled.View`
  width: 100%;
  flex: 0.3;
  flex-shrink: 0;
  padding-bottom: ${({ theme }) =>
    theme.audioPlayerFullscreen.controls.paddingBottom}px;
  align-items: center;
  justify-content: center;
`

const Title = styled(AudioPlayerTitle)`
  flex-shrink: 0;
  align-self: flex-start;
  max-width: 100%;
  margin-bottom: ${(props) => props.theme.spacing.small}px;
`

const CloseButton = styled(IconButton)`
  align-self: flex-start;
`

const TopButtonContainer = styled.View`
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  padding-top: ${TOP_BAR_PADDING_TOP}px;
  padding-horizontal: ${TOP_BAR_PADDING_HORIZONTAL}px;
`

export function FullscreenAudioPlayer({
  open,
  onPressTitle,
  onClose,
  onShare,
}: {
  open: boolean
  onPressTitle?: (track: Track) => void
  onClose: () => void
  onShare: (track: Track) => void
}): JSX.Element {
  const { tokens, displayMode } = useTokens()
  const track = useTrack()

  const manifest = useAppManifest()
  const [offline] = useOfflineState()

  useLockBodyScroll(open)

  const { t } = useTranslation()

  return (
    <>
      <TopButtonContainer>
        <CloseButton
          id={kebabCase(
            `audio-player-fullscreen-close-${track?.album || "unknown-album"}-${track?.title || "unknown-track"}`,
          )}
          onPress={onClose}
          source={icons.chevronDown}
          label={t("Close player")}
          iconSize="large"
        />
        {track && canShareUrl() && (
          <IconButton
            id={kebabCase(
              `audio-player-fullscreen-share-${track?.album || "unknown-album"}-${track?.title || "unknown-track"}`,
            )}
            label={t("Share")}
            iconSize="large"
            source={manifest.icons.share}
            onPress={() => {
              if (!track) {
                return
              }

              onShare(track)
            }}
            disabled={offline}
          />
        )}
      </TopButtonContainer>

      <Container>
        {open ? (
          <StatusBar
            barStyle={
              displayMode === "light" ? "dark-content" : "light-content"
            }
          />
        ) : null}

        <ArtworkContainer>
          <ImageContained
            uri={getOptimizedImageSource(
              track?.artwork,
              tokens.audioPlayerFullscreen.maxWidth,
            )}
            borderRadius="roundedLarge"
            containerStyle={containedImageContainerStyle}
          />
        </ArtworkContainer>
        {track ? <Title onPress={onPressTitle} track={track} /> : null}
        {
          // Don't keep updating the progress UI while we're collapsed - it's a
          // waste of effort
          open ? <Progress /> : <ProgressContainer />
        }
        <ControlsContainer>
          <Controls />
        </ControlsContainer>
      </Container>
      <NavigationBar onPressBack={onClose} />
    </>
  )
}
