import React from "react"
import { View } from "react-native"

import { useAppLinkHelpers } from "../../hooks/use-app-link-helpers"
import type { AppLinkProps } from "./types"

/**
 * Render a web-friendly link (an <a/> tag with an href) that'll navigate the user
 * to an AppLink destination.
 */
export function AppLink({
  to,
  params,
  onPress,
  ...props
}: AppLinkProps): JSX.Element {
  const appLinkHelpers = useAppLinkHelpers()
  const appLinkProps = appLinkHelpers.getProps({
    id: props.id,
    appLink: to,
    params,
    onPress,
    label:
      "aria-label" in props && typeof props["aria-label"] === "string"
        ? props["aria-label"]
        : typeof props.children === "string"
          ? props.children
          : "",
  })

  return <View {...props} {...appLinkProps} />
}
