import React, { ReactNode } from "react"
import { StyleProp, ViewStyle } from "react-native"

import styled from "styled-components/native"

import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"

import { IS_BOT } from "../lib/is-bot"
import { Spacing } from "../lib/spacing"
import Box from "./box"
import { ImageWithFade, ImageWithFadeProps } from "./image-with-fade"
import LinearGradient from "./linear-gradient"

const BACKGROUND_IMAGE_MAX_SIZE = 1200

const Container = styled.View<{
  height: number | string
}>`
  position: relative;
  height: ${(props) =>
    typeof props.height === "number" ? props.height + "px" : props.height};
  justify-content: flex-end;
  flex: auto;
`

const BackgroundImage = styled(ImageWithFade)`
  height: 100%;
  width: 100%;
  position: absolute;
`

const BackgroundImagePlaceholder = styled.View`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: ${(props) => props.theme.colors.loading.image};
`

const ContentOuter = styled(Box)`
  position: relative;
  align-items: flex-start;
  width: 100%;
`

const ContentInner = styled(Box)`
  width: 100%;
`

const SolidBackground = styled.View<{ color: string; gradientOffset: number }>`
  height: ${(props) => props.gradientOffset * 100}%;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: ${(props) => props.color};
`

const GradientBackground = styled(LinearGradient)<{
  gradientOffset: number
  gradientCoverage: number
}>`
  height: ${(props) => props.gradientCoverage * 100}%;
  width: 100%;
  position: absolute;
  bottom: ${(props) => props.gradientOffset * 100}%;
  /* Make sure there's no gap between the gradient and solid background */
  margin-bottom: -1px;
`

export default function BannerImageContainer({
  children,
  height,
  gradientColor,
  style,
  source,
  gradientOffset = 0,
  gradientCoverage = 1,
  paddingBottom = "medium",
  alwaysShowGradient,
}: {
  children?: ReactNode
  height: number | string
  /**
   * This prop can be used to push the gradient up from the bottom of the
   * container. Any space below the gradient will be filled in with the
   * background color of the container. This will take a value from 0 to 1 where
   * 0 = no offset and 1 = offset by the height of the content in the container.
   */
  gradientOffset?: number
  gradientColor: string
  /**
   * This prop can be used to control the height of the gradient. As with the
   * gradientOffset prop, the value is a multiplier of the height of the
   * container's content, with 1 = 100% of the height, 1.5 = 150%, etc.
   */
  gradientCoverage?: number
  style?: StyleProp<ViewStyle>
  source?: ImageWithFadeProps["source"]
  paddingBottom?: Spacing
  /**
   * If `true` and children is `undefined` or `null` a small gradient will still
   * be shown.
   */
  alwaysShowGradient?: boolean
}): JSX.Element {
  return (
    <Container height={height} style={style}>
      {source ? (
        <BackgroundImage
          source={
            // Don't load banner images for bots (waste of time and bandwidth)
            IS_BOT
              ? undefined
              : getOptimizedImageSource(source, BACKGROUND_IMAGE_MAX_SIZE)
          }
          resizeMode="cover"
        />
      ) : (
        <BackgroundImagePlaceholder />
      )}
      {children || alwaysShowGradient ? (
        <ContentOuter>
          <SolidBackground
            color={gradientColor}
            gradientOffset={gradientOffset}
          />
          <GradientBackground
            fadeTowards="top"
            color={gradientColor}
            gradientOffset={gradientOffset}
            gradientCoverage={gradientCoverage}
          />
          <ContentInner
            paddingBottom={paddingBottom}
            paddingHorizontal="pagePaddingHorizontal"
          >
            {children}
          </ContentInner>
        </ContentOuter>
      ) : null}
    </Container>
  )
}
