import { AxiosError } from "axios"

import {
  CouponCodeValidationFailure,
  CouponCodeValidationResponse,
} from "@treefort/api-spec"
import { isAxiosError } from "@treefort/lib/is-axios-error"
import isObject from "@treefort/lib/is-object"
import strictEncodeUriComponent from "@treefort/lib/strict-encode-uri-component"

import api from "./api"

export async function validateCouponCode(couponCode: string) {
  const res = await api.get<CouponCodeValidationResponse>(
    `/coupon-codes/validate/${strictEncodeUriComponent(couponCode)}`,
  )
  return res.data
}

export async function redeemCouponCode({
  couponCodeId,
}: {
  couponCodeId: number
}) {
  return api.post(`/coupon-codes/${couponCodeId}/redeem`)
}

export function isCouponCodeValidationError(
  error: unknown,
): error is Omit<AxiosError<CouponCodeValidationFailure>, "response"> &
  Required<Pick<AxiosError<CouponCodeValidationFailure>, "response">> {
  return (
    isAxiosError(error) &&
    error.response?.status === 400 &&
    isObject(error.response.data) &&
    error.response.data.status === "invalid"
  )
}
