import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform, View } from "react-native"

import { useAuth } from "@treefort/lib/auth-provider"

import { ActionCard } from "../../../components/action-card"
import { ActionLink, ActionLinkStack } from "../../../components/action-link"
import { CallToDownloadApp } from "../../../components/call-to-download-app"
import CallToSignUp, {
  CallToSignUpState,
} from "../../../components/call-to-sign-up"
import Column from "../../../components/column"
import { SocialLinks } from "../../../components/social-links"
import { SubscriptionStatusSummary } from "../../../components/subscription-status-summary"
import Text from "../../../components/text"
import { useTokens } from "../../../components/tokens-provider"
import config from "../../../config"
import {
  useSubscriptionPlan,
  useUserSubscriptions,
} from "../../../hooks/subscriptions"
import { useActiveProfileInfo } from "../../../hooks/use-active-profile-info"
import useAppManifest from "../../../hooks/use-app-manifest"
import { useAppMenuLinkGroups } from "../../../hooks/use-app-menu-link-groups"
import { useAsyncViewPropsForQueries } from "../../../hooks/use-async-view-props-for-queries"
import { useIsProfilesFeatureEnabled } from "../../../hooks/use-is-profiles-feature-enabled"
import { useOfflineState } from "../../../hooks/use-offline-state"
import { useOpenCheckoutPage } from "../../../hooks/use-open-checkout-page"
import { useProfiles } from "../../../hooks/use-profiles"
import useUserInfo from "../../../hooks/use-user-info"
import confirm from "../../../lib/confirm"
import { profilesOverlay } from "../../../lib/profiles"
import {
  getCurrentSubscription,
  subscriptionWillRenew,
} from "../../../lib/subscriptions"
import MenuLayout from "../../layouts/menu"
import { isLibraryTab } from "../../routes"

export function MenuScreen(): JSX.Element {
  const [offline] = useOfflineState()
  return offline ? <MenuScreenOffline /> : <MenuScreenOnline />
}

function MenuScreenOnline(): JSX.Element {
  const auth = useAuth()
  const userInfo = useUserInfo()
  const userSubscriptions = useUserSubscriptions()
  const subscription = getCurrentSubscription(userSubscriptions.data)
  const subscriptionPlan = useSubscriptionPlan(subscription?.subscriptionPlanId)
  const { tokens } = useTokens()
  const { t } = useTranslation()
  const isProfilesFeatureEnabled = useIsProfilesFeatureEnabled()
  const profiles = useProfiles()
  const activeProfileInfo = useActiveProfileInfo()
  const [callToSignUpState, setCallToSignUpState] =
    useState<CallToSignUpState>("loading")

  const showAccountCard = auth.user && userInfo.isSuccess
  const showProfileCard =
    isProfilesFeatureEnabled && activeProfileInfo.isSuccess
  // Show the subscription card if the user is subscribed. Also show the card if
  // the user was previously subscribed but isn't any more. This allows them to
  // do things like update their payment info to resurrect an expired
  // subscription. The exception to this is group memberships: once the user
  // leaves we don't show anything as there's no billing portal or other plan
  // options associated with group memberships.
  const showSubscriptionCard =
    userSubscriptions.data &&
    subscriptionPlan.data &&
    (subscription?.provider === "groupMembership"
      ? subscriptionWillRenew(subscription)
      : Boolean(subscription))
  const desktop = tokens.appHeader.mode === "desktop"
  // Show app store CTAs when we're online and on mobile web. On desktop the app
  // store CTAs are still shown but in the app footer.
  const showCallToDownloadApp = Platform.OS === "web" && !desktop

  const asyncViewProps = useAsyncViewPropsForQueries(
    [userInfo, subscriptionPlan, activeProfileInfo],
    {
      forceLoading: auth.loading || callToSignUpState === "loading",
    },
  )

  // Open the checkout page for users who need to subscribe or upgrade to
  // access the profiles feature
  const openCheckoutPage = useOpenCheckoutPage({
    availability:
      profiles.data?.status === "notAvailable" &&
      profiles.data.reasonCode === "offeringRequired"
        ? {
            status: "notAvailable",
            reasonCode: "offeringRequired",
            data: { offeringIds: profiles.data.offeringIds },
          }
        : undefined,
  })

  async function openProfilesOverlay() {
    const isSubscribed = userInfo.data?.subscription.subscribed
    if (
      profiles.data?.status === "notAvailable" &&
      profiles.data.reasonCode === "offeringRequired" &&
      (await confirm({
        title: isSubscribed
          ? t("Upgrade required")
          : t("Subscription required"),
        message: isSubscribed
          ? t("profiles_call_to_upgrade", { count: profiles.data.maxCount - 1 })
          : t("profiles_call_to_subscribe", {
              count: profiles.data.maxCount - 1,
            }),
        confirmLabel: isSubscribed ? t("Upgrade") : t("Subscribe"),
        cancelLabel: t("Cancel"),
      }))
    ) {
      openCheckoutPage()
    } else if (!profiles.data?.profiles.length) {
      // If the user doesn't have any profiles then take them straight to the
      // manage screen where they'll have the option to add one
      profilesOverlay.requestOpen({ screen: { name: "manage" } })
    } else {
      profilesOverlay.requestOpen({ screen: { name: "select" } })
    }
  }

  const linkGroups = useAppMenuLinkGroups(
    desktop ? "desktopMenu" : "mobileMenu",
  )

  return (
    <MenuLayout asyncViewProps={asyncViewProps}>
      <Column gap="large" alignItems="stretch">
        {showAccountCard ? (
          <ActionCard
            id="menu-account"
            title={t("Account")}
            imageUrl={
              // Don't show the profile image here if we're already showing it
              // in the profiles card
              showProfileCard ? undefined : userInfo.data.profilePic
            }
            to={{ type: "path", path: "/menu/account" }}
          >
            <Text textStyle="strong" color="secondary" numberOfLines={1}>
              {userInfo.data.email}
            </Text>
          </ActionCard>
        ) : null}
        {showSubscriptionCard ? (
          <ActionCard
            id="menu-subscription"
            title={t("Subscription")}
            to={{ type: "path", path: "/menu/subscription" }}
          >
            <SubscriptionStatusSummary
              userSubscriptions={userSubscriptions.data}
              subscriptionPlan={subscriptionPlan.data}
              textStyle="strong"
              textColor="secondary"
              showPlanName
            />
          </ActionCard>
        ) : null}
        {showProfileCard ? (
          <ActionCard
            id="menu-profile"
            title={t("Profile")}
            imageUrl={activeProfileInfo.data.image}
            onPress={openProfilesOverlay}
          >
            <Text textStyle="strong" color="secondary" numberOfLines={1}>
              {activeProfileInfo.data.name}
            </Text>
          </ActionCard>
        ) : null}
        <CallToSignUp
          onStateChange={setCallToSignUpState}
          paddingBottom="large"
          showLogo={
            // The logo is in the app header on desktop
            !desktop
          }
        />
        <ActionLinkStack linkGroups={linkGroups} />
        {
          // Social links are shown in the app footer on desktop
          !desktop ? <SocialLinks justifyContent="center" /> : null
        }
        {showCallToDownloadApp ? <CallToDownloadApp /> : null}
      </Column>
    </MenuLayout>
  )
}

function MenuScreenOffline(): JSX.Element {
  const manifest = useAppManifest()
  const { t } = useTranslation()
  return (
    <MenuLayout>
      <Column gap="large" alignItems="stretch">
        <View>
          <ActionLink
            id="menu-offline-settings"
            to={{ type: "path", path: "/menu/settings" }}
          >
            {t("Settings")}
          </ActionLink>
          {config.DOWNLOADS_SUPPORTED && !manifest.tabs.some(isLibraryTab) ? (
            <ActionLink
              id="menu-offline-downloads"
              to={{ type: "path", path: "/menu/downloads" }}
            >
              {t("Downloads")}
            </ActionLink>
          ) : null}
          <ActionLink
            id="menu-offline-about"
            to={{ type: "path", path: "/menu/about" }}
          >
            {t("About")}
          </ActionLink>
        </View>
      </Column>
    </MenuLayout>
  )
}
