import { UseQueryOptions, useQuery } from "react-query"

import { FreeTrialEligibility } from "@treefort/api-spec"

import { CheckoutCodeValidationResult } from "../lib/checkout-codes"
import {
  AvailableSubscriptionPlan,
  getFreeTrialEligibilities,
  getSubscriptionPlans,
  getAvailableSubscriptionPlans,
  getSubscriptionPlan,
  getPaymentProvidersForPlatform,
} from "../lib/subscription-plans"
import useAppPreview from "./use-app-preview"
import useQueryKey from "./use-query-key"

export type SignUpOptionsData = {
  availableSubscriptionPlans: AvailableSubscriptionPlan[]
  recommendedSubscriptionPlans: AvailableSubscriptionPlan[]
  freeTrialEligibilities: FreeTrialEligibility[]
}

/**
 * Fetches data from the server that `getSignUpOptions` needs to generate the
 * the list of sign up options
 */
export function useSignUpOptionsData({
  offeringIds,
  checkoutCodeValidationResult,
  ...queryOptions
}:
  | ({
      offeringIds?: number[]
      checkoutCodeValidationResult?: CheckoutCodeValidationResult
    } & UseQueryOptions<SignUpOptionsData>)
  | undefined = {}) {
  const appPreview = useAppPreview()
  const appPreviewToken =
    appPreview.state === "on" ? appPreview.token : undefined

  const checkoutCodeEligiblePlanIds =
    checkoutCodeValidationResult?.type === "validWebPaymentPromoCode" ||
    checkoutCodeValidationResult?.type === "validStripePromoCode"
      ? checkoutCodeValidationResult.data.eligibleSubscriptionPlanIds
      : undefined

  return useQuery(
    useQueryKey([
      "sign-up-options-data",
      offeringIds,
      checkoutCodeValidationResult,
      appPreviewToken,
    ]),
    async () => {
      switch (checkoutCodeValidationResult?.type) {
        /**
         * Group membership code
         */
        case "validGroupMembershipCode": {
          const groupMembershipPlanId =
            checkoutCodeValidationResult.subscriptionPlanId

          const plan = await getSubscriptionPlan(groupMembershipPlanId)
          if (plan.archivedAt) {
            throw new Error("[Checkout] Can't show archived plan", {
              cause: new Error(
                `Encountered archived plan with id: ${groupMembershipPlanId}`,
              ),
            })
          }

          if (plan.provider !== "groupMembership") {
            throw new Error(
              '[Checkout] Expected a group membership plan, but plan.provider is not "groupMembership"',
              {
                cause: new Error(
                  `Encounted plan with id ${groupMembershipPlanId} and unexpected provider "${plan.provider}"`,
                ),
              },
            )
          }

          return {
            availableSubscriptionPlans: [plan] as AvailableSubscriptionPlan[],
            recommendedSubscriptionPlans: [] as AvailableSubscriptionPlan[],
            freeTrialEligibilities: [] as FreeTrialEligibility[],
          }
        }
        /**
         * Coupon code
         */
        case "validCouponCode": {
          // Don't bother fetching anything for coupon codes
          return {
            availableSubscriptionPlans: [] as AvailableSubscriptionPlan[],
            recommendedSubscriptionPlans: [] as AvailableSubscriptionPlan[],
            freeTrialEligibilities: [] as FreeTrialEligibility[],
          }
        }
        /**
         * Paid subscription plans
         */
        default: {
          const providers = getPaymentProvidersForPlatform()
          const allSubscriptionPlans = await getSubscriptionPlans({
            providers,
            appPreviewToken,
          })
          const [
            availableSubscriptionPlans,
            subscriptionPlansWithAccessToContent,
            freeTrialEligibilities,
          ] = await Promise.all([
            getAvailableSubscriptionPlans(allSubscriptionPlans),
            offeringIds &&
              getSubscriptionPlans({
                offeringIds,
                providers,
                appPreviewToken,
              }),
            getFreeTrialEligibilities(allSubscriptionPlans.map(({ id }) => id)),
          ])

          return {
            availableSubscriptionPlans,
            recommendedSubscriptionPlans: availableSubscriptionPlans.filter(
              (availablePlan) =>
                // Only recommend plans with access to the requested content
                (!subscriptionPlansWithAccessToContent ||
                  subscriptionPlansWithAccessToContent.some(
                    (planWithAccessToContent) =>
                      planWithAccessToContent.id === availablePlan.id,
                  )) &&
                // Only recommend plans that the requested checkout code
                // applies to
                (!checkoutCodeEligiblePlanIds ||
                  checkoutCodeEligiblePlanIds.includes(availablePlan.id)),
            ),
            freeTrialEligibilities,
          }
        }
      }
    },
    queryOptions,
  )
}
