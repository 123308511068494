import { useRef, useInsertionEffect, useCallback } from "react"

/**
 * A pollyfill until useEffectEvent makes into a stable release of React. Copied
 * with changes from [SO](https://stackoverflow.com/a/76514983/14397313). Also,
 * see [the React
 * docs](https://react.dev/learn/separating-events-from-effects#reading-latest-props-and-state-with-effect-events)
 * for when useEffectEvent is helpful.
 */
export function useTfEffectEvent<TCallbackArgs extends unknown[]>(
  callback: (...args: TCallbackArgs) => void,
) {
  const ref = useRef(callback)
  useInsertionEffect(() => {
    ref.current = callback
  }, [callback])
  return useCallback((...args: TCallbackArgs) => {
    return ref.current(...args)
  }, [])
}
