import React from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import styled from "styled-components/native"

import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"
import icons from "@treefort/tokens/app/icons"

import { useTrack } from "../hooks/audio"
import useHover from "../hooks/use-hover"
import { Track } from "../lib/audio-player"
import { kebabCase } from "../lib/kebab-case"
import Controls from "./audio-player-controls"
import Progress from "./audio-player-progress"
import AudioPlayerTitle from "./audio-player-title"
import Icon from "./icon"
import Image from "./image"
import Spacer from "./spacer"
import { useTokens } from "./tokens-provider"
import Touchable from "./touchable"

const Container = styled.View`
  position: ${Platform.OS === "web" ? "fixed" : "absolute"};
  bottom: ${(props) => props.theme.spacing.pagePaddingHorizontal}px;
  right: ${(props) => props.theme.spacing.pagePaddingHorizontal}px;
  background: ${(props) => props.theme.colors.background.primary};
  flex-direction: row;
  align-items: center;
  padding: ${(props) =>
    props.theme.audioPlayerFloating.padding -
    props.theme.audioPlayerFloating.borderWidth}px;
  padding-right: ${(props) =>
    props.theme.audioPlayerFloating.paddingRight -
    props.theme.audioPlayerFloating.borderWidth}px;
  border-radius: ${(props) => props.theme.audioPlayerFloating.borderRadius}px;
  border-width: ${(props) => props.theme.audioPlayerFloating.borderWidth}px;
  border-color: ${(props) => props.theme.audioPlayerFloating.borderColor};
  box-shadow: 0 0
    ${(props) => props.theme.audioPlayerFloating.dropShadow.radius}px
    ${(props) => props.theme.audioPlayerFloating.dropShadow.color};
  max-width: ${(props) => props.theme.audioPlayerFloating.maxWidth}px;
`

const Artwork = styled(Image)<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.theme.borderRadius.rounded}px;
  background-color: ${(props) => props.theme.colors.loading.image};
`

const Title = styled(AudioPlayerTitle)`
  flex: 1;
  margin-left: ${(props) => props.theme.spacing.medium}px;
`

const ProgressAndControlsContainer = styled.View`
  margin-top: -${(props) => props.theme.audioPlayerFloating.padding}px;
  align-items: center;
`

const ProgressContainer = styled.View`
  margin-bottom: -${(props) => props.theme.audioPlayerFloating.padding}px;
`

const ControlsContainer = styled.View`
  width: ${(props) => props.theme.audioPlayerFloating.controlsWidth}px;
`

const CloseButtonContainer = styled.View<{ visible: boolean }>`
  position: absolute;
  top: -${(props) => props.theme.audioPlayerFloating.closeButton.size / 2}px;
  right: -${(props) => props.theme.audioPlayerFloating.closeButton.size / 2}px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
`

const CloseButton = styled(Touchable)`
  width: ${(props) => props.theme.audioPlayerFloating.closeButton.size}px;
  height: ${(props) => props.theme.audioPlayerFloating.closeButton.size}px;
  background-color: ${(props) =>
    props.theme.audioPlayerFloating.closeButton.backgroundColor};
  align-items: center;
  justify-content: center;
  border-radius: ${(props) =>
    props.theme.audioPlayerFloating.closeButton.borderRadius}px;
`

export default function AudioPlayerFloating({
  onPressTitle,
  onRequestClose,
}: {
  onPressTitle?: (track: Track) => void
  onRequestClose?: () => void
}): JSX.Element {
  const track = useTrack()
  const { tokens } = useTokens()
  const [isHovering, hoverProps] = useHover()
  const { t } = useTranslation()
  return (
    <Container {...hoverProps}>
      <Artwork
        size={tokens.audioPlayerFloating.artworkSize}
        source={getOptimizedImageSource(
          { uri: track?.artwork },
          tokens.audioPlayerFloating.artworkSize,
        )}
        resizeMode="cover"
      />
      {track ? <Title onPress={onPressTitle} track={track} /> : null}
      <Spacer size="xlarge" horizontal={true} />
      <ProgressAndControlsContainer>
        <ProgressContainer>
          <Progress width={tokens.audioPlayerFloating.progressWidth} />
        </ProgressContainer>
        <ControlsContainer>
          <Controls compact />
        </ControlsContainer>
      </ProgressAndControlsContainer>
      {onRequestClose ? (
        <CloseButtonContainer visible={isHovering}>
          <CloseButton
            id={kebabCase(
              `audio-player-floating-close-${track?.album || "unknown-album"}-${track?.title || "unknown-track"}`,
            )}
            role="button"
            aria-label={t("Close")}
            onPress={onRequestClose}
          >
            <Icon
              source={icons.closeCircle.fill}
              size="medium"
              color="secondary"
            />
          </CloseButton>
        </CloseButtonContainer>
      ) : null}
    </Container>
  )
}
