import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { useAuth } from "@treefort/lib/auth-provider"

import config from "../config"
import useAppManifest from "../hooks/use-app-manifest"
import { useMenu } from "../hooks/use-menu"
import { useOfflineState } from "../hooks/use-offline-state"
import { useOpenLibrary } from "../hooks/use-open-library"
import { queryClient } from "../lib/query-client"
import { AsyncViewProps, AsyncViewProvider } from "./async-view"

/**
 * This is a wrapper around the generic AsyncViewProvider that detects when the
 * device is offline and sets AsyncView props accordingly.
 */
export function AsyncViewOfflineProvider({
  offlineStateDisabled,
  ...asyncViewProviderProps
}: {
  offlineStateDisabled?: boolean
} & Parameters<typeof AsyncViewProvider>[0]): JSX.Element {
  const { t } = useTranslation()
  const [offline, refreshOffline] = useOfflineState()
  const auth = useAuth()
  const manifest = useAppManifest()
  const menu = useMenu()
  const openLibrary = useOpenLibrary()

  const offlineProps = useMemo<AsyncViewProps | null>(
    () =>
      offline && !offlineStateDisabled
        ? {
            state: "offline",
            message: t(
              "You appear to be offline. Please connect to the internet to view this page.",
            ),
            primaryAction:
              config.DOWNLOADS_SUPPORTED &&
              (auth.user || !manifest.features.userAccounts)
                ? {
                    label: t("View downloads"),
                    onPress: async () => {
                      openLibrary
                        ? openLibrary("downloaded")
                        : menu.open("/downloads")
                    },
                  }
                : undefined,
            secondaryAction: {
              label: t("Refresh"),
              onPress: async () => {
                const nextOffline = await refreshOffline()
                if (!nextOffline) {
                  await queryClient.refetchQueries({ active: true })
                }
              },
            },
          }
        : null,
    [
      t,
      offline,
      offlineStateDisabled,
      auth.user,
      manifest.features.userAccounts,
      menu,
      refreshOffline,
      openLibrary,
    ],
  )

  return <AsyncViewProvider {...asyncViewProviderProps} {...offlineProps} />
}
