import i18next from "i18next"

import { DisplayableError } from "@treefort/lib/displayable-error"

import { refetchUserSubscriptions } from "../../../hooks/subscriptions"
import api from "../../api"
import { getCheckoutCodeValidationMessage } from "../../checkout-codes"
import { isCouponCodeValidationError, redeemCouponCode } from "../../coupons"
import { requestGroupMembership } from "../request-group-membership"
import { requestPayment } from "../request-payment"
import { CheckoutSession, checkoutSessionManager } from "../session"

export async function completeCheckout(checkoutSession: CheckoutSession) {
  switch (checkoutSession.type) {
    case "paidPlan":
      await requestPayment({ checkoutSession })
      break

    case "paidPlanResubscription":
      if (checkoutSession.plan.provider === "webPayment") {
        await requestPayment({ checkoutSession })
      } else {
        await api.patch("/integrations/stripe/subscription", {
          type: "resubscription",
          subscriptionPlanId: checkoutSession.plan.id,
          promoCode: checkoutSession.promoCode,
        })
      }
      break

    case "paidPlanWithProrationPreview":
      await api.patch("/integrations/stripe/subscription", {
        type: "proration",
        subscriptionPlanId: checkoutSession.plan.id,
        prorationDate: checkoutSession.prorationDate,
        stripeSubscriptionId: checkoutSession.stripeSubscriptionId,
        promoCode: checkoutSession.promoCode,
      })
      break

    case "groupMembership":
      await requestGroupMembership({ checkoutSession })
      break

    case "coupon": {
      try {
        await redeemCouponCode({ couponCodeId: checkoutSession.couponCode.id })
      } catch (error) {
        // Show an error message to the user if we got a specific validation
        // error back from the redemption endpoint
        if (isCouponCodeValidationError(error)) {
          throw new DisplayableError(
            getCheckoutCodeValidationMessage({
              i18n: i18next,
              validationResult: {
                type: "invalidCouponCode",
                data: error.response.data,
              },
            }) || i18next.t("Invalid code."),
            error,
          )
        }

        // Otherwise re-throw the error to be handled upstream
        throw error
      }

      // Complete the checkout session for entitlement coupons here. Completion
      // for subscription plan coupons is handled by
      // handleSubscriptionResponseForCheckout()
      if (checkoutSession.coupon.type === "entitlement") {
        checkoutSessionManager.endSession({ complete: true })
      }
      break
    }

    default:
      checkoutSession satisfies never
  }

  // Refetch the user's subscriptions for subscription-based checkout sessions.
  // This will both refresh the app's UI with the user's new access and will end
  // up triggering handleSubscriptionResponseForCheckout which will close out
  // the session and pop a success toast (assuming everything worked correctly).
  switch (checkoutSession.type) {
    case "paidPlan":
    case "paidPlanResubscription":
    case "paidPlanWithProrationPreview":
    case "groupMembership":
      await refetchUserSubscriptions()
      break
    case "coupon":
      if (checkoutSession.coupon.type === "subscriptionPlan") {
        await refetchUserSubscriptions()
      }
      break
  }
}
