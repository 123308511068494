import React from "react"
import { useTranslation } from "react-i18next"
import { ActivityIndicator } from "react-native"

import styled from "styled-components/native"

const Container = styled.View<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  align-items: center;
  justify-content: center;
`

/**
 * A thin wrapper around react-native-web's ActivityIndicator which looks nice
 * and uses CSS animations which are much much faster than animating in react.
 */
export default function Indicator({
  size,
  containerSize,
  color,
}: {
  size: number
  containerSize: number
  color: string
}): JSX.Element {
  const { t } = useTranslation()
  return (
    <Container size={containerSize}>
      <ActivityIndicator
        color={color}
        size={size}
        aria-label={t("Loading...")}
      />
    </Container>
  )
}
