import i18next from "i18next"

import { InvalidGroupMembershipCodeValidationResponse } from "@treefort/api-spec"
import { DisplayableError } from "@treefort/lib/displayable-error"
import { getMessageFromAxiosError } from "@treefort/lib/errors"
import isObject from "@treefort/lib/is-object"

import { getCheckoutCodeValidationMessage } from "../checkout-codes"
import { signUpForGroupMembership } from "../group-membership"
import {
  checkoutSessionManager,
  CheckoutSessionGroupMembership,
} from "./session"

export const requestGroupMembership = async ({
  checkoutSession,
}: {
  checkoutSession: CheckoutSessionGroupMembership
}) => {
  try {
    await signUpForGroupMembership(checkoutSession)
  } catch (e) {
    await checkoutSessionManager.endSession({ complete: false })
    if (
      isObject(e) &&
      isObject(e.response) &&
      isObject(e.response.data) &&
      typeof e.response.data.reason === "string"
    ) {
      throw new DisplayableError(
        getCheckoutCodeValidationMessage({
          i18n: i18next,
          validationResult: {
            type: "invalidGroupMembershipCode",
            reason: e.response.data
              .reason as InvalidGroupMembershipCodeValidationResponse["reason"],
          },
        }) || i18next.t("Invalid code."),
        e,
      )
    } else {
      const message = getMessageFromAxiosError(e)
      if (message) {
        throw new DisplayableError(message, e)
      } else {
        throw e
      }
    }
  }
}
