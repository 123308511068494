function kebabCase(id: string) {
  return (
    id
      // Camel- to kebab-case
      .replace(/([^A-Z])([A-Z])/g, "$1-$2")
      // Dash all non-alphanumeric characters
      .replace(/[^a-zA-Z0-9]/g, "-")
      // Remove any resulting strings of 2+ dashes
      .replace(/-{2,}/g, "-")
      // Trim start and end dashes
      .replace(/^-*(.*[^-])-*$/, "$1")
      // Downcase everything
      .toLowerCase()
  )
}

export { kebabCase }
