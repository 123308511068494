import { useCallback, useEffect, useRef, useState } from "react"

import throttle from "lodash/throttle"

import { SettingsFacade } from "@treefort/lib/settings-facade"
import { Event } from "@treefort/lib/types/settings"
import type { ISettings } from "@treefort/lib/types/settings"

import { debug as appDebug } from "../lib/logging"

const debug = appDebug.extend("hooks:useSetting")

export type SettingHookStrategy = "local" | "remote" | "localOrRemote"

type Options<T> = {
  // The key prop can be undefined to make usage of the hook easier when the key
  // is asyncronously loaded. If the key is undefined the hook will act like the
  // setting is missing from the store and calling the setter function will do
  // nothing.
  key?: string
  // The Settings class instance
  settings: SettingsFacade
  // The strategy determines how the setting will be loaded and saved.
  strategy: SettingHookStrategy
  profileId: string | null
  // Throttle re-renders to at most once every X milliseconds.
  throttle?: number
  // This can be used to limit re-renders so that they only occur when the
  // setting value changes in a way that we care about. Note that this cannot
  // be changed after the hook is first called.
  shouldUpdate?: (prevValue: T | null, nextValue: T | null) => boolean
  // If provied the default value will be returned instead of null if the
  // setting does not have a value set. Note that this cannot be changed after
  // the hook is first called.
  defaultValue?: T
}

type OptionsWithDefaultValue<T> = Omit<
  Options<T>,
  "shouldUpdate" | "defaultValue"
> & {
  shouldUpdate?: (prevValue: T, nextValue: T) => boolean
  defaultValue: T
}

export type SettingHookReturn<T> = [
  value: T,
  setValue: (value: T) => Promise<void>,
]

function saveSetting({
  settings,
  strategy,
  key,
  value,
  profileId,
}: {
  settings: ISettings
  strategy: SettingHookStrategy
  key: string
  value: unknown
  profileId: string | null
}): Promise<void> {
  switch (strategy) {
    case "local":
      return settings.saveLocal(key, value, { profileId })
    case "remote":
      return settings.saveRemote(key, value, {
        profileId,
      })
    case "localOrRemote":
      return settings.saveLocalAndRemote(key, value, {
        profileId,
      })
  }
}

function getSetting<T>({
  settings,
  strategy,
  key,
  profileId,
}: {
  settings: ISettings
  strategy: SettingHookStrategy
  key: string
  profileId: string | null
}) {
  switch (strategy) {
    case "local":
      return settings.getLocal<T>(key, { profileId })
    case "remote":
      return settings.getRemote<T>(key, {
        profileId,
      })
    case "localOrRemote":
      return settings.getLocalOrRemote<T>(key, {
        profileId,
      })
  }
}

/**
 * This hook makes it easy to get and set a setting from within a component and
 * to stay in sync with all other components that may be working with the same
 * setting at the same time.
 *
 * If a default value is provided then the hook will always return a value. If a
 * default value is not provided then the hook will return `undefined` while the
 * setting is being loaded and `null` if the setting is not set.
 */
export function useSetting<T = unknown>(
  options: OptionsWithDefaultValue<T>,
): SettingHookReturn<T>
export function useSetting<T = unknown>(
  options: Options<T>,
): SettingHookReturn<T | null | undefined>
export function useSetting<T = unknown>({
  key,
  settings,
  strategy,
  throttle: throttlePeriod,
  shouldUpdate,
  defaultValue,
  profileId,
}: Options<T>): SettingHookReturn<T | null | undefined> {
  // The value is stored in a map keyed by profileId so that changes to the
  // profileId are reflected immediately in the hook output.
  const [localValueMap, setLocalValueMap] = useState<
    ReadonlyMap<string | null, T | null | undefined>
  >(new Map([[profileId, undefined]]))

  const localValueMapRef =
    useRef<ReadonlyMap<string | null, T | null | undefined>>(localValueMap)

  const setValue = useCallback(
    async (value: T | null | undefined) => {
      if (key) {
        await saveSetting({
          settings,
          strategy,
          key,
          profileId,
          value: value ?? null,
        })
      }
    },
    [settings, strategy, key, profileId],
  )

  useEffect(
    () => {
      if (key) {
        debug(`[${key}] Mounting.`, { throttlePeriod })

        // Handle new setting values
        const handler = (event: { key: string; value: unknown }) => {
          const nextValue = (event.value as T) ?? defaultValue ?? null

          if (
            event.key === key &&
            (!shouldUpdate ||
              shouldUpdate(
                localValueMapRef.current.get(profileId) ?? defaultValue ?? null,
                nextValue,
              ))
          ) {
            debug(`[${key}] Updating localValueMap`, { profileId, nextValue })
            const nextValueMap = new Map([[profileId, nextValue]])
            setLocalValueMap(nextValueMap)
            localValueMapRef.current = nextValueMap
          }
        }

        // Listen for new setting values
        const removeListener = settings.on(
          strategy === "local" || strategy === "localOrRemote"
            ? Event.LocalSettingUpdated
            : Event.RemoteSettingUpdated,
          throttlePeriod ? throttle(handler, throttlePeriod) : handler,
        )

        // Fetch the initial setting value (_after_ adding a listener to make
        // sure we don't miss any values)
        debug(`[${key}] Performing initial fetch`)
        getSetting({ settings, strategy, key, profileId }).then((setting) =>
          handler({ key, value: setting.value }),
        )

        return () => {
          debug(
            `[${key}] Unmounting. Removing listener and clearing localValueMap.`,
          )
          removeListener()
          const emptyMap = new Map([[profileId, undefined]])
          setLocalValueMap(emptyMap)
          localValueMapRef.current = emptyMap
        }
      }
    },
    // Omit shouldUpdate and defaultValue (see docs for those options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settings, key, profileId, strategy, throttlePeriod],
  )

  const localValue = localValueMap.get(profileId)

  debug(`[${key}] Found local value from localValueMap %o`, localValue)
  return [
    // If the local value is still loading (undefined) then return either the
    // defaultValue or undefined.
    localValue === undefined
      ? defaultValue
      : localValue ?? defaultValue ?? null,
    setValue,
  ]
}
