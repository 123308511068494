import React, { useCallback, useMemo } from "react"
import { EdgeInsets } from "react-native-safe-area-context"

import styled from "styled-components/native"

import { AppOnboardingScreen } from "@treefort/api-spec"

import useWindowDimensions from "../hooks/use-window-dimensions"
import Banner from "./banner"
import { ModalType } from "./modal"
import OnboardingScreen from "./onboarding-screen"
import { useTokens } from "./tokens-provider"

const StyledBanner = styled(Banner)<{ height: number | string }>`
  height: ${({ height }) =>
    typeof height === "number" ? height + "px" : height};
`

export default function OnboardingBanner({
  type,
  insets,
  screens,
  onRequestClose,
}: {
  type: ModalType
  insets: EdgeInsets
  screens: AppOnboardingScreen[]
  onRequestClose: (
    closeCompleteCallback?: () => unknown,
  ) => Promise<void> | void
}): JSX.Element {
  const { tokens } = useTokens()
  const windowDimensions = useWindowDimensions({ updateOnHeightChange: true })
  const { scrollIndicator } = tokens.bannerModule
  const scrollIndicatorHeight = scrollIndicator.container.height
  // Add the banner scroll indicator height to the bottom inset applied to
  // onboarding screens so that the screen content doesn't overlap the
  // indicator. We subtract "large" spacing from this extra height because the
  // onboarding screens already have some bottom padding baked in and we don't
  // need to give the indicator _that_ much room.
  const screenInsets = useMemo(
    () =>
      type === "fullscreen"
        ? {
            ...insets,
            bottom:
              insets.bottom +
              (screens.length > 1
                ? scrollIndicatorHeight - tokens.spacing.large
                : 0),
          }
        : screens.length > 1
          ? {
              top: 0,
              right: 0,
              left: 0,
              bottom:
                screens.length > 1
                  ? scrollIndicatorHeight - tokens.spacing.large
                  : 0,
            }
          : undefined,
    [insets, screens, type, scrollIndicatorHeight, tokens],
  )

  // Make room for the scroll indicator when it's present
  const renderItems = useCallback(
    (screenWidth: number) =>
      screens.map((screen) => (
        <OnboardingScreen
          key={screen.id}
          screen={screen}
          width={screenWidth}
          insets={screenInsets}
          onRequestClose={onRequestClose}
        />
      )),
    [screens, screenInsets, onRequestClose],
  )

  return (
    <StyledBanner
      renderItems={renderItems}
      height={type === "fullscreen" ? "100%" : 0.75 * windowDimensions.height}
      scrollIndicatorInsetBottom={type === "fullscreen" ? insets.bottom : 0}
    />
  )
}
