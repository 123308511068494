import React, { useContext, ReactNode, createContext, useMemo } from "react"
import { Platform } from "react-native"

import { ThemeProvider } from "styled-components/native"

import { getAppThemeColors } from "@treefort/lib/app-theme"
import rawTokens, {
  TokenResolutionProps,
  resolveTokens,
  ResolvedTokens as OriginalResolvedTokens,
} from "@treefort/tokens/app"

import useAppManifest from "../hooks/use-app-manifest"
import { useDisplayModeSetting } from "../hooks/use-display-mode-setting"
import { useSystemDisplayMode } from "../hooks/use-system-display-mode"
import useWindowDimensions from "../hooks/use-window-dimensions"
import { DisplayMode } from "../lib/display-mode"
import { fontFamilyDisplay, fontFamilyText } from "../typography"

const tokens = {
  ...rawTokens,
  fontFamilies: { display: fontFamilyDisplay, text: fontFamilyText },
}

export type Tokens = typeof tokens

export type ResolvedTokens = OriginalResolvedTokens<Tokens>

export type { DisplayMode }

type Theme = TokenResolutionProps["theme"]

interface TokensContext {
  tokens: ResolvedTokens
  displayMode: DisplayMode
  displayWidth: number
  displayHeight: number
  theme: Theme
}

const Context = createContext<TokensContext>({} as TokensContext)

export const useTokens = (): TokensContext => {
  return useContext(Context)
}

export function RootTokensProvider({
  children,
}: {
  children: ReactNode
}): JSX.Element {
  const [displayModeSetting] = useDisplayModeSetting()
  const ready = displayModeSetting !== undefined
  const windowDimensions = useWindowDimensions()
  const manifest = useAppManifest()

  const theme = useMemo(
    () => ({
      ...manifest.theme,
      ...getAppThemeColors(manifest.theme),
    }),
    [manifest.theme],
  )

  // A handy built in hook for subscribing to changes in OS display mode
  const systemDisplayMode = useSystemDisplayMode()

  const displayMode =
    displayModeSetting === "auto" || displayModeSetting === undefined
      ? systemDisplayMode
      : displayModeSetting

  const resolvedTokens = useMemo(
    () =>
      resolveTokens(tokens, {
        displayMode,
        displayWidth: windowDimensions.width,
        platform: Platform.OS,
        theme,
      }),
    [displayMode, windowDimensions.width, theme],
  )

  return (
    <Context.Provider
      value={{
        tokens: resolvedTokens,
        displayMode,
        displayWidth: windowDimensions.width,
        displayHeight: windowDimensions.height,
        theme,
      }}
    >
      <ThemeProvider theme={resolvedTokens}>
        {ready ? children : null}
      </ThemeProvider>
    </Context.Provider>
  )
}

export default function TokensProvider({
  displayMode: overrideDisplayMode,
  theme: overrideTheme,
  children,
}: {
  displayMode?: DisplayMode
  theme?: Theme
  children: ReactNode
}): JSX.Element {
  const windowDimensions = useWindowDimensions()
  const parentContext = useContext(Context)
  const theme = overrideTheme || parentContext.theme
  const displayMode = overrideDisplayMode || parentContext.displayMode
  const resolvedTokens = useMemo(
    () =>
      resolveTokens(tokens, {
        displayMode,
        displayWidth: windowDimensions.width,
        platform: Platform.OS,
        theme,
      }),
    [displayMode, windowDimensions.width, theme],
  )

  return (
    <Context.Provider
      value={{
        ...parentContext,
        tokens: resolvedTokens,
        displayMode: displayMode,
        displayWidth: windowDimensions.width,
        displayHeight: windowDimensions.height,
      }}
    >
      <ThemeProvider theme={resolvedTokens}>{children}</ThemeProvider>
    </Context.Provider>
  )
}
