import { i18n } from "i18next"
import queryString from "query-string"

import {
  CouponCodeValidationResponse,
  InvalidGroupMembershipCodeValidationResponse,
  StripePromoCodeResponse,
  WebPaymentPromoCodeResponse,
} from "@treefort/api-spec"
import { formatMoney, getCurrencyDivisor } from "@treefort/lib/money"
import strictEncodeUriComponent from "@treefort/lib/strict-encode-uri-component"

import api from "./api"
import { validateCouponCode } from "./coupons"
import { checkGroupMembershipCode } from "./group-membership"

export type CheckoutCodeValidationResult =
  | {
      type: "validGroupMembershipCode"
      subscriptionPlanId: number
      groupMembershipCode: string
    }
  | {
      type: "invalidGroupMembershipCode"
      reason: InvalidGroupMembershipCodeValidationResponse["reason"]
    }
  | {
      type: "validWebPaymentPromoCode"
      data: Extract<WebPaymentPromoCodeResponse, { type: "amount" | "percent" }>
    }
  | {
      type: "validStripePromoCode"
      data: Extract<StripePromoCodeResponse, { type: "amount" | "percent" }>
    }
  | {
      type: "validCouponCode"
      data: Extract<CouponCodeValidationResponse, { status: "valid" }>
    }
  | {
      type: "invalidCouponCode"
      data: Extract<CouponCodeValidationResponse, { status: "invalid" }>
    }
  | {
      type: "invalidCode"
    }

class InvalidCheckoutCodeError extends Error {
  constructor() {
    super("Invalid checkout code")
  }
}

/**
 * Validates a checkout code. The first service to send back a response other
 * than "error" or "no code" is the winner. This will result in
 * non-deterministic behavior for conflicting codes but will result in the
 * fastest execution for non-conflicting codes.
 */

export function validateCheckoutCode({
  code,
  subscriptionPlanIds,
}: {
  code: string
  subscriptionPlanIds: number[]
}): Promise<CheckoutCodeValidationResult> {
  // Use Promise.any to send back the first non-error code validation. Each
  // individual promise should throw `InvalidCheckoutCodeError` if validation
  // fails in order to prevent any "invalid code" responses from winning against
  // "valid code" responses.
  return Promise.any([
    /**
     * Web payment promo codes
     */
    api
      .get<WebPaymentPromoCodeResponse>(
        `/integrations/web-payment/promo-codes/${strictEncodeUriComponent(code)}?${queryString.stringify(
          { planId: subscriptionPlanIds },
        )}`,
      )
      .then(({ data }) => {
        if (data.type === "invalid") {
          throw new InvalidCheckoutCodeError()
        }
        return { type: "validWebPaymentPromoCode" as const, data }
      }),
    /**
     * Stripe promo codes
     */
    api
      .get<StripePromoCodeResponse>(
        `/integrations/stripe/promo-codes/${strictEncodeUriComponent(code)}?${queryString.stringify(
          { planId: subscriptionPlanIds },
        )}`,
      )
      .then(({ data }) => {
        if (data.type === "invalid") {
          throw new InvalidCheckoutCodeError()
        }
        return { type: "validStripePromoCode" as const, data }
      }),
    /**
     * Group membership codes
     */
    checkGroupMembershipCode(code).then((data) => {
      // Only bail in the case of a code that we don't recognize. If we
      // recognize the code but it's invalid (e.g. expired) then we consider
      // that a successful response that should be shown to the user.
      if (data.status === "invalid" && data.reason === "noGroupMembership") {
        throw new InvalidCheckoutCodeError()
      }
      return data.status === "valid"
        ? {
            type: "validGroupMembershipCode" as const,
            subscriptionPlanId: data.subscriptionPlanId,
            groupMembershipCode: code,
          }
        : { type: "invalidGroupMembershipCode" as const, reason: data.reason }
    }),
    /**
     * Coupon codes
     */
    validateCouponCode(code).then((data) => {
      // Only bail in the case of a code that we don't recognize. If we
      // recognize the code but it's invalid (e.g. expired) then we consider
      // that a successful response that should be shown to the user.
      if (data.status === "invalid" && data.reason === "missing") {
        throw new InvalidCheckoutCodeError()
      }
      return data.status === "valid"
        ? { type: "validCouponCode" as const, data }
        : { type: "invalidCouponCode" as const, data }
    }),
  ]).catch((cause) => {
    // If we got a response from every service but all of the responses were
    // "invalid code" then pass that on as a known state instead of an error.
    if (
      cause instanceof AggregateError &&
      cause.errors.every((error) => error instanceof InvalidCheckoutCodeError)
    ) {
      return { type: "invalidCode" }
    } else {
      throw cause
    }
  })
}

export function getCheckoutCodeValidationMessage({
  i18n,
  validationResult,
}: {
  i18n: i18n
  validationResult: CheckoutCodeValidationResult
}) {
  switch (validationResult.type) {
    case "validStripePromoCode":
    case "validWebPaymentPromoCode": {
      const { data } = validationResult
      const firstTimeOnly = data.firstTimeSubscribersOnly
      switch (data.type) {
        case "percent":
          return i18n.t(
            firstTimeOnly
              ? "{{percentZeroToOne}} off at checkout for new subscribers"
              : "{{percentZeroToOne}} off at checkout",
            { percentZeroToOne: data.percentOff / 100 },
          )
        case "amount":
          return i18n.t(
            firstTimeOnly
              ? "{{formattedAmount}} off at checkout for new subscribers"
              : "{{formattedAmount}} off at checkout",
            {
              formattedAmount: formatMoney(
                {
                  amount: data.amountOff,
                  currency: data.currency,
                  divisor: getCurrencyDivisor(data.currency),
                },
                i18n.language,
              ),
            },
          )
        default:
          return undefined
      }
    }
    case "invalidGroupMembershipCode": {
      switch (validationResult.reason) {
        case "expiredGroupMembership":
          return i18n.t("This code has expired.")
        case "emailNotAllowed":
          return i18n.t("You don’t have permission to use this code.")
        case "fullGroupMembership":
          return i18n.t("This code has reached its usage limit.")
        default:
          return i18n.t("Invalid code.")
      }
    }
    case "invalidCouponCode": {
      switch (validationResult.data.reason) {
        case "expired":
          return i18n.t("This code has expired.")
        case "redeemed":
          return i18n.t("This code has reached its usage limit.")
        default:
          return i18n.t("Invalid code.")
      }
    }
    case "validCouponCode":
    case "validGroupMembershipCode":
      return undefined
    case "invalidCode":
      return i18n.t("Invalid code.")
    default:
      validationResult satisfies never
      return i18n.t("Invalid code.")
  }
}
