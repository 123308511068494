"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appPathAliasSchema = exports.appMenuLinkSchema = exports.secureUrlMediaSchema = exports.membershipCodeSchema = exports.featureProfilesSchema = exports.appExternalLinksSchema = exports.secureUrl = void 0;
const yup_1 = require("yup");
const constants_1 = require("@treefort/constants");
function secureUrl() {
    return (0, yup_1.string)().matches(/^https:\/\/\S+$/, {
        excludeEmptyString: true,
        message: "Please provide a secure URL beginning with https://",
    });
}
exports.secureUrl = secureUrl;
exports.appExternalLinksSchema = (0, yup_1.object)({
    termsOfService: secureUrl(),
    privacyPolicy: secureUrl(),
    cookiePolicy: secureUrl(),
    facebook: secureUrl(),
    instagram: secureUrl(),
    twitter: secureUrl(),
    youtube: secureUrl(),
    website: secureUrl(),
    shop: secureUrl(),
});
exports.featureProfilesSchema = (0, yup_1.object)({
    enabled: (0, yup_1.boolean)().required(),
    offeringIds: (0, yup_1.array)((0, yup_1.number)().required()).required(),
    profileImages: (0, yup_1.array)((0, yup_1.string)().required()).required(),
    maxCount: (0, yup_1.number)().required(),
})
    .default(null)
    .nullable();
exports.membershipCodeSchema = (0, yup_1.lazy)((value) => (0, yup_1.string)()
    .required()
    .matches(/^[a-zA-Z0-9_]+$/, {
    excludeEmptyString: true,
    message: `${value} is not a valid membership code. Membership codes can only contain numbers, letters a-z (case insensitive), and underscores (_).`,
}));
exports.secureUrlMediaSchema = (0, yup_1.object)({
    type: (0, yup_1.string)().oneOf(["secureUrl"]).required(),
    id: (0, yup_1.string)().required(),
    secureUrl: secureUrl().required(),
});
exports.appMenuLinkSchema = (0, yup_1.object)({
    title: (0, yup_1.string)().required("Please provide a title."),
    url: secureUrl().required("Please provide a URL."),
    locations: (0, yup_1.array)((0, yup_1.string)()
        .oneOf(constants_1.APP_MENU_LINK_LOCATIONS.map((location) => location.id))
        .required())
        .min(1, "Please select at least 1 location.")
        .required("Please select at least 1 location."),
});
exports.appPathAliasSchema = (0, yup_1.object)({
    alias: (0, yup_1.string)()
        .required("Please provide a short path.")
        .matches(/^\/.*/, { message: 'Please provide a path starting with "/"' }),
    path: (0, yup_1.string)()
        .required("Please provide a target path.")
        .matches(/^\/.*/, { message: 'Please provide a path starting with "/"' }),
});
