import React from "react"

import styled from "styled-components/native"

import { useAppMenuLinkGroups } from "../hooks/use-app-menu-link-groups"
import { kebabCase } from "../lib/kebab-case"
import { AppLink } from "./app-link"
import { CallToDownloadApp } from "./call-to-download-app"
import Column from "./column"
import { PoweredByTreefort } from "./powered-by-treefort"
import Row from "./row"
import { SocialLinks } from "./social-links"
import Text from "./text"

const SOCIAL_LINKS_MARGIN_LEFT_PX = -10
const FOOTER_PADDING_HORIZONTAL_PX = 60

const StyledSocialLinks = styled(SocialLinks)`
  margin-left: ${SOCIAL_LINKS_MARGIN_LEFT_PX}px;
`

const FooterContent = styled(Row)`
  padding-horizontal: ${FOOTER_PADDING_HORIZONTAL_PX}px;
  padding-top: ${({ theme }) => theme.spacing.xlarge}px;
  background-color: ${(props) => props.theme.colors.background.secondary};
  width: 100%;
`

const FooterLinks = styled(Column)`
  /* Make sure that we reserve space for the audio player so that footer links
  never get stuck behind it */
  padding-bottom: ${({ theme }) =>
    theme.audioPlayerFloating.artworkSize +
    theme.audioPlayerFloating.padding * 2 +
    theme.spacing.pagePaddingHorizontal}px;
`

export function DesktopPageFooter() {
  const linkGroups = useAppMenuLinkGroups("desktopFooter")

  return (
    <FooterContent alignItems="flex-start" gap="jumbo">
      <Column alignItems="flex-start" gap="large">
        <CallToDownloadApp />
        <StyledSocialLinks />
        <PoweredByTreefort />
      </Column>
      {linkGroups.map((links, index) => (
        <FooterLinks
          alignItems="flex-start"
          rowGap="medium"
          columnGap="jumbo"
          flexWrap="wrap"
          height="100%"
          key={index}
        >
          {links.map((link) => (
            <AppLink
              id={kebabCase(`footer-link-${link.title}`)}
              key={link.title}
              to={link.to}
              aria-label={link.title}
            >
              <Text textStyle="headingSmall">{link.title}</Text>
            </AppLink>
          ))}
        </FooterLinks>
      ))}
    </FooterContent>
  )
}
