import { t } from "i18next"

import { SubscriptionResponse } from "@treefort/api-spec"
import { Action } from "@treefort/lib/authenticator"
import { DisplayableError } from "@treefort/lib/displayable-error"
import { getMessageFromAxiosError } from "@treefort/lib/errors"

import analytics from "../analytics"
import authenticator from "../authenticator"
import { checkGroupMembershipCode } from "../group-membership"
import { logError } from "../logging"
import { toast } from "../toaster"
import { completeCheckout } from "./complete-checkout"
import { Event, CheckoutSession, checkoutSessionManager } from "./session"

export type { CheckoutSession }

/**
 * Log checkout analytics
 */
checkoutSessionManager.on(
  Event.CheckoutSessionStarted,
  analytics.logCheckoutSessionStart,
)
checkoutSessionManager.on(
  Event.CheckoutSessionEnded,
  ({ session, complete }) => {
    if (complete) {
      analytics.logCheckoutSessionComplete(session)
    }
  },
)

/**
 * Takes a checkout session and attempts to actually checkout (e.g. sign up for
 * a plan, create an account, etc.)
 */
export async function initiateCheckout(checkoutSession: CheckoutSession) {
  try {
    await checkoutSessionManager.startSession(checkoutSession)
    const user = authenticator.getUser()
    if (user) {
      await completeCheckout(checkoutSession)
    } else {
      await authenticator.register()
    }
  } catch (error) {
    handleError(error)
  }
}

/**
 * Takes a subscription response object from the api, checks if it matches the
 * current checkout session, if any, and ends the checkout session with a
 * success message if it does
 */
export async function handleSubscriptionResponseForCheckout({
  subscribed,
  willRenewWithSubscriptionPlanId,
}: SubscriptionResponse) {
  const checkoutSession = await checkoutSessionManager.getSession()
  switch (checkoutSession?.type) {
    case "paidPlan":
    case "paidPlanResubscription":
    case "paidPlanWithProrationPreview":
      if (
        (subscribed === checkoutSession.plan.id &&
          (willRenewWithSubscriptionPlanId === subscribed ||
            !willRenewWithSubscriptionPlanId)) ||
        willRenewWithSubscriptionPlanId === checkoutSession.plan.id
      ) {
        handleSubscriptionActivated()
      }
      break
    case "groupMembership": {
      const res = await checkGroupMembershipCode(
        checkoutSession.membershipCode,
      ).catch(logError)
      const subscriptionPlanId = res?.subscriptionPlanId
      if (
        subscriptionPlanId &&
        (subscriptionPlanId === subscribed ||
          (Array.isArray(subscribed) &&
            subscribed.includes(subscriptionPlanId)))
      ) {
        handleSubscriptionActivated()
      }
      break
    }
    case "coupon": {
      const subscriptionPlanId =
        checkoutSession.coupon.type === "subscriptionPlan"
          ? checkoutSession.coupon.redeems.subscriptionPlanId
          : null
      if (
        subscriptionPlanId &&
        (subscriptionPlanId === subscribed ||
          (Array.isArray(subscribed) &&
            subscribed.includes(subscriptionPlanId)))
      ) {
        handleSubscriptionActivated()
      }
      break
    }
  }
}

/**
 * Takes an auth action event, checks it against the current checkout session,
 * if any, and ends the checkout session with a success message or moves the
 * user on to the next step of the checkout process
 */
export async function handleCompletedAuthActionForCheckout(action: Action) {
  const checkoutSession = await checkoutSessionManager.getSession()
  const signedIn = action === "login" || action === "register"
  if (signedIn && checkoutSession) {
    await completeCheckout(checkoutSession)
  }
}

async function handleError(error: unknown) {
  await checkoutSessionManager.endSession({ complete: false })
  const message = getMessageFromAxiosError(error)
  logError(
    error instanceof DisplayableError
      ? error
      : new DisplayableError(
          message && message.toLowerCase().startsWith("invalid promo code")
            ? t("Invalid code.")
            : t("An error occurred. Please try again."),
          error,
        ),
  )
}

function handleSubscriptionActivated() {
  checkoutSessionManager.endSession({ complete: true })
  toast.success(t("Your subscription has been activated. Thank you!"))
}
