import React from "react"
import Helmet from "react-helmet"

import type { WithContext, VideoObject } from "schema-dts"

import config from "../../config"
import { PageMetadataResult } from "../../hooks/use-page-metadata"

function generateJsonLd({
  pageMetadata,
}: {
  pageMetadata: PageMetadataResult
}) {
  let jsonLd: string | undefined

  switch (pageMetadata.data?.content?.type) {
    case "video": {
      const videoData: WithContext<VideoObject> = {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "@id": pageMetadata.data.url,
        thumbnailUrl: pageMetadata.data.image,
        name: getTitleFromPageMetadata(pageMetadata),
        description: pageMetadata.data.description,
        contentUrl: pageMetadata.data.url,
        uploadDate: pageMetadata.data.content?.createdAt,
        url: pageMetadata.data.url,
        isAccessibleForFree: !pageMetadata.data.content.offeringIds.length,
      }

      jsonLd = JSON.stringify(videoData)
      break
    }
    default:
      return null
  }

  if (!jsonLd) {
    return null
  }

  return <script type="application/ld+json">{jsonLd}</script>
}

type MetaTags = Record<string, string | undefined>

/**
 * This sets a slew of important tags in the HTML doc's head (e.g. Open Graph
 * properties from https://ogp.me/).
 */
export default function HtmlHead({
  pageMetadata,
}: {
  pageMetadata: PageMetadataResult
}) {
  return pageMetadata.data ? (
    <Helmet>
      <title>{getTitleFromPageMetadata(pageMetadata)}</title>
      <meta
        name="description"
        property="og:description"
        content={pageMetadata.data.description}
      />
      {Object.entries(getMetaTagsFromPageMetadata(pageMetadata))
        .filter(([_name, value]) => value !== undefined)
        .map(([name, value]) => (
          <meta
            key={name}
            {...{ [name.startsWith("og:") ? "property" : "name"]: name }}
            content={value}
          />
        ))}
      {generateJsonLd({ pageMetadata }) ?? ""}
    </Helmet>
  ) : null
}

const getTitleFromPageMetadata = (pageMetadata: PageMetadataResult): string =>
  !pageMetadata.data ||
  pageMetadata.data?.home ||
  pageMetadata.data.title === config.APP_NAME
    ? // Use the app name instead of the page name when sharing the home page
      config.APP_NAME
    : // Qualify all other pages with the app name
      `${pageMetadata.data.title} | ${config.APP_NAME}`

const getMetaTagsFromPageMetadata = (
  pageMetadata: PageMetadataResult,
): MetaTags => ({
  ...getOpenGraphMetaTagsFromPageMetadata(pageMetadata),
  ...getTwitterCardMetaTagsFromPageMetadata(pageMetadata),
})

const getOpenGraphMetaTagsFromPageMetadata = (
  pageMetadata: PageMetadataResult,
): MetaTags => {
  const metadata = pageMetadata.data
  return metadata
    ? {
        "og:type": "website",
        "og:title": getTitleFromPageMetadata(pageMetadata),
        "og:url": metadata.url,
        "og:image": metadata.image,
      }
    : {}
}

const getTwitterCardMetaTagsFromPageMetadata = (
  pageMetadata: PageMetadataResult,
): MetaTags => ({
  "twitter:card": pageMetadata.data?.content
    ? "summary_large_image"
    : "summary",
})
