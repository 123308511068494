import React from "react"

import { hasUnsyncedChanges } from "@nozbe/watermelondb/sync"
import { t } from "i18next"
import styled from "styled-components/native"

import { DisplayableError } from "@treefort/lib/displayable-error"
import { WatermelonError } from "@treefort/lib/errors"

import Column from "../../../../components/column"
import SettingsFieldset from "../../../../components/settings-fieldset"
import SettingsRow from "../../../../components/settings-row"
import Text from "../../../../components/text"
import Touchable from "../../../../components/touchable"
import config from "../../../../config"
import confirm from "../../../../lib/confirm"
import { logError } from "../../../../lib/logging"
import { toast } from "../../../../lib/toaster"
import { getClient } from "../../../../watermelon/db"
import { syncManager } from "../../../../watermelon/sync"
import MenuLayout from "../../../layouts/menu"

const CenteredTouchable = styled(Touchable)`
  display: flex;
  justify-content: center;
  height: ${(props) => props.theme.button.height.medium}px;
`

async function onPressDeleteLocalData() {
  // We will default to false because if we are unable to check for local
  // changes (i.e. hasUnsyncedChanges throws) then it indicates a corrupt
  // database of some kind and we won't be able to do anything about it anyway.
  let unsyncedChangesPresent = false

  try {
    unsyncedChangesPresent = await hasUnsyncedChanges({
      database: getClient(),
    })
  } catch (cause) {
    logError(
      new WatermelonError(
        "Error while checking for unsynced changes during user-initiated local data deletion",
        { cause },
      ),
    )
  }

  const message = unsyncedChangesPresent
    ? t(
        "Warning: you have local data that has not yet been synced to the server. To save it, cancel this action and sync local changes first. If you continue now, this data will be lost. Do you want to proceed to delete local data?",
      )
    : t("Are you sure you want to delete local data?")

  const response = await confirm({
    title: t("Delete?"),
    message,
    cancelLabel: t("Cancel"),
    confirmLabel: t("Delete"),
  })

  if (response) {
    try {
      await syncManager.resetDatabase()
      toast.success(t("Deleted local data."))
    } catch (cause) {
      throw new DisplayableError(
        t("An error occurred. Please contact us if the issue persists."),
        new WatermelonError("Failed to reset database (user-initiated)", {
          cause,
        }),
      )
    }
  }
}

export function AdvancedSettingsScreen(): JSX.Element {
  return (
    <MenuLayout>
      <Column gap="large" alignItems="stretch">
        <SettingsFieldset title={t("Local data")}>
          <SettingsRow justifyContent="center" paddingVertical="none">
            <CenteredTouchable
              id="adv-settings-sync-local-data"
              feedback="opacity"
              onPress={async () => {
                try {
                  await syncManager.requestSync({ syncType: "immediate" })
                  toast.success(t("Sync complete"))
                } catch (cause) {
                  throw new DisplayableError(
                    t(
                      "An error occurred. Please contact us if the issue persists.",
                    ),
                    new WatermelonError("User-initiated sync failed", {
                      cause,
                    }),
                  )
                }
              }}
            >
              <Text textStyle="button">{t("Sync local data")}</Text>
            </CenteredTouchable>
          </SettingsRow>
          <SettingsRow
            justifyContent="center"
            paddingVertical="none"
            showBorder
          >
            <CenteredTouchable
              id="adv-settings-delete-local-data"
              feedback="opacity"
              onPress={onPressDeleteLocalData}
            >
              <Text textStyle="button" color="danger">
                {t("Delete local data")}
              </Text>
            </CenteredTouchable>
          </SettingsRow>
        </SettingsFieldset>
        {config.ENV === "development" ? (
          // eslint-disable-next-line @shopify/jsx-no-hardcoded-content
          <SettingsFieldset title="Development">
            <SettingsRow justifyContent="center" paddingVertical="none">
              <CenteredTouchable
                id="adv-settings-test-intentional-test-error"
                onPress={() => {
                  throw new Error("[Test] Intentional test error", {
                    cause: new Error("Someone tapped the 'Throw error' button"),
                  })
                }}
              >
                {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content */}
                <Text textStyle="button">Throw error</Text>
              </CenteredTouchable>
            </SettingsRow>
          </SettingsFieldset>
        ) : null}
      </Column>
    </MenuLayout>
  )
}
