import {
  CheckoutSession,
  checkoutSessionManager,
  Event,
} from "../lib/checkout/session"
import { useEventEmitterValue } from "./use-event-emitter-value"

export const useCheckoutSession = (): CheckoutSession | null | undefined =>
  useEventEmitterValue(
    checkoutSessionManager,
    Event.CheckoutSessionChanged,
    checkoutSessionManager.getSessionSync,
  )
