import { useCallback } from "react"

import queryString from "query-string"

import { simpleHash } from "@treefort/lib/simple-hash"

import { history } from "../../lib/history.web"
import { getPageFromPath, Params } from "../../navigation/routes"
import useAppManifest from "../use-app-manifest"

export function useNavigate(): (
  path: string,
  params?: Params,
  // This is only applicable on native. It will have no effect here.
  mode?: string,
) => void {
  const manifest = useAppManifest()
  return useCallback(
    (path, params) => {
      const currentPath = window.location.pathname + window.location.search
      const currentPage = getPageFromPath(currentPath, manifest)

      const utmParameterNames = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_term",
        "utm_content",
      ]

      const queryParams = queryString.parseUrl(currentPath).query

      const utmParams = Object.fromEntries(
        utmParameterNames.flatMap((utmParamName) =>
          queryParams[utmParamName]
            ? [[utmParamName, queryParams[utmParamName]]]
            : [],
        ),
      )

      const preservedParams = {
        ...(params || {}),
        ...utmParams,
      }

      const nextPath =
        path +
        (preservedParams ? "?" + queryString.stringify(preservedParams) : "")
      const nextPage = getPageFromPath(nextPath, manifest)

      // Navigate unless we're already on the requested page. This protects
      // against quirky behavior if a page ends up linking to itself.
      if (
        !nextPage ||
        nextPage.id !== currentPage?.id ||
        simpleHash(preservedParams || {}) !==
          simpleHash(queryString.parse(window.location.search))
      ) {
        history.push(nextPath)
      }
    },
    [manifest],
  )
}
