import React from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import styled from "styled-components/native"

import { AvailabilityNotAvailable } from "@treefort/api-spec"
import { useAuth } from "@treefort/lib/auth-provider"
import icons from "@treefort/tokens/app/icons"

import useUserInfo from "../hooks/use-user-info"
import { stringKeyLookup } from "../lib/i18n/string-key-lookup"
import { Button, ButtonProps } from "./button"

type Action = keyof typeof stringKeyLookup.subscribeAction

const LoadingButton = styled(Button)`
  width: ${({ theme }) => theme.lockedContentButton.loadingButton.width}px;
`

/**
 * NOTE: content.availability should be passed to useOpenCheckoutPage to ensure
 * that the checkout page is opened with correct route params
 */
export default function LockedContentButton({
  availability,
  action,
  openCheckoutPage,
  externalPurchaseUrl,
  ...props
}: {
  availability: AvailabilityNotAvailable
  action: Action
  openCheckoutPage: () => void
  externalPurchaseUrl?: string | null
} & Omit<
  ButtonProps,
  "children" | "onPress" | "aria-label"
>): JSX.Element | null {
  const auth = useAuth()
  const userInfo = useUserInfo()
  const { t } = useTranslation()

  if (auth.user && !userInfo.data) {
    return (
      <LoadingButton id={null} type="primary" loading>
        {" "}
      </LoadingButton>
    )
  }

  switch (availability.reasonCode) {
    case "offeringRequired":
      return (
        <Button
          type="primary"
          icon={icons.userAdd}
          iconSize="medium"
          onPress={openCheckoutPage}
          {...props}
        >
          {!auth.user || !userInfo.data?.subscription.subscribed
            ? t(stringKeyLookup.subscribeAction[action])
            : t(stringKeyLookup.upgradeAction[action])}
        </Button>
      )
    case "notAuthenticated":
      return (
        <Button
          type="primary"
          icon={icons.userAdd}
          iconSize="medium"
          onPress={openCheckoutPage}
          {...props}
        >
          {t(stringKeyLookup.createAccountAction[action])}
        </Button>
      )
    case "offeringNotAvailable":
      if (Platform.OS === "web" && externalPurchaseUrl) {
        return (
          <Button
            type="primary"
            icon={icons.priceTag}
            iconSize="medium"
            onPress={() => (window.location.href = externalPurchaseUrl)}
            {...props}
          >
            {t("Purchase")}
          </Button>
        )
      }

      return (
        <Button type="primary" disabled iconSize="medium" {...props}>
          {t("Unavailable")}
        </Button>
      )
  }
}
