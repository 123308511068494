import React from "react"
import { Platform } from "react-native"

import styled from "styled-components/native"

import { AppLink } from "@treefort/api-spec"

import { useAppLinkHelpers } from "../hooks/use-app-link-helpers"
import { getAppLinkFromUrl } from "../lib/app-link"
import Text, { TextProps } from "./text"

const LinkText = styled.Text`
  color: ${(props) => props.theme.colors.text.accent};
  text-decoration: underline ${(props) => props.theme.colors.text.accent};
`

/**
 * Convert a string of text containing URLs to a JSX Element containing strings
 * interspersed with actual AppLink elements in place of the URLs.
 */
export default function TextWithLinks({
  children,
  getAppLinkProps,
  ...textProps
}: {
  children: string
  getAppLinkProps: ReturnType<typeof useAppLinkHelpers>["getProps"]
} & TextProps): JSX.Element {
  // TODO: Android keeps crashing because of the nested text in this component.
  // For now Android users don't get links. We should fix this. #1317
  if (Platform.OS === "android") {
    return <Text {...textProps}>{children}</Text>
  }

  // Split the string up into an array of text and link parts. Note that we
  // consider anything starting with https:// to be a link. This doesn't cover
  // every use-case, but it covers almost all of them and is extremely simple.
  const matchSecureLink = /https:\/\/[^\s]+/g
  const parts: Array<
    | { type: "text"; text: string }
    | { type: "link"; text: string; link: AppLink }
  > = []
  let match: RegExpExecArray | null
  let cursor = 0
  while ((match = matchSecureLink.exec(children)) != null) {
    const link = getAppLinkFromUrl(match[0])
    parts.push({ type: "text", text: children.slice(cursor, match.index) })
    parts.push({ type: "link", text: match[0], link })
    cursor = match.index + match[0].length
  }
  parts.push({ type: "text", text: children.slice(cursor) })

  return (
    <Text {...textProps}>
      {parts
        .filter(
          (part) =>
            part.type === "link" || (part.type === "text" && part.text.length),
        )
        .map((part) =>
          part.type === "text" ? (
            part.text
          ) : (
            <LinkText
              {...getAppLinkProps({
                id: "",
                appLink: part.link,
                label: part.text,
              })}
              key={part.text}
            >
              {part.text}
            </LinkText>
          ),
        )}
    </Text>
  )
}
