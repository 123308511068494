import { useCallback } from "react"

import { LibraryTab } from "../components/library/tabs"
import { getPathFromTab, isLibraryTab } from "../navigation/routes"
import useAppManifest from "./use-app-manifest"
import { useNavigate } from "./use-navigate"

/**
 * Returns a navigation function that will open the library. If the library is
 * not available in the app then null will be returned instead.
 */
export function useOpenLibrary() {
  const manifest = useAppManifest()
  const navigate = useNavigate()
  const libraryAppTab = manifest.tabs.find(isLibraryTab)

  const openLibrary = useCallback(
    (tab?: LibraryTab) => {
      if (libraryAppTab) {
        navigate(getPathFromTab(libraryAppTab), { tab })
      }
    },
    [navigate, libraryAppTab],
  )

  return libraryAppTab ? openLibrary : null
}
