import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

import queryString from "query-string"

import { FirebaseMessagingTokenGetResponse } from "@treefort/api-spec"
import { DisplayableError } from "@treefort/lib/displayable-error"
import { NotificationsPermissionError } from "@treefort/lib/errors"

import useAppState from "../hooks/use-app-state"
import { useIsFocused } from "../hooks/use-is-focused"
import useQueryKey from "../hooks/use-query-key"
import api from "../lib/api"
import { logError } from "../lib/logging"
import notifications, { PermissionStatus } from "../lib/notifications"
import { openNotificationSettings } from "../lib/open-notification-settings"
import ActivityIndicator from "./activity-indicator"
import SettingsFieldset from "./settings-fieldset"
import SettingsRow from "./settings-row"
import Switch from "./switch"
import Text from "./text"
import Touchable from "./touchable"

export default function SettingsNotifications(): JSX.Element {
  const isFocused = useIsFocused()
  const appState = useAppState({ inactiveStateiOS: "background" })
  const [enabledOptimistic, setEnabledOptimistic] = useState<boolean>()
  const { t } = useTranslation()

  // Figure out what our current permission status is. Refetch this every 2000
  // milliseconds while the page is focused and in the foreground in case the
  // user grants/denies permissions.
  const permissionStatus = useQuery<PermissionStatus>(
    useQueryKey("settings-notification-permission-status"),
    async () => notifications.getPermissionStatus(),
    {
      enabled: isFocused && appState === "active",
      refetchInterval: 2000,
    },
  )

  // Ask the API if the user has notifications enabled or disabled.
  const enabled = useQuery<boolean>(
    useQueryKey("settings-notification-enabled"),
    async () => {
      const token = await notifications
        .getToken({ requestPermission: false })
        // Don't worry about notification setup errors. These happen all the
        // time due to permissions issues. If the user isn't actively trying to
        // enable notifications then these errors are not worth bothering about.
        .catch(() => null)
      if (token === null) {
        return false
      } else {
        const tokenQueryParam = queryString.stringify({ token })
        const res = await api.get<FirebaseMessagingTokenGetResponse>(
          `/integrations/firebase-messaging/tokens?${tokenQueryParam}`,
        )
        return res.data.optedOutAt === null
      }
    },
    {
      cacheTime: 0,
      enabled:
        permissionStatus.isSuccess &&
        permissionStatus.data !== "unavailable" &&
        permissionStatus.data !== "denied",
    },
  )

  // This is called when the user has already granted us permissions at a system
  // level but wants to toggle notifications on/off.
  const toggleNotifications = useCallback(
    async (enabled: boolean) => {
      setEnabledOptimistic(enabled)
      try {
        // If the user wants to enable notifications, request permissions and post
        // the token to our server.
        if (enabled) {
          const token = await notifications.getToken({
            requestPermission: true,
          })

          await notifications.postToken({ token, optOut: false })
        }
        // If the user wants to disable notifications and they have a token then
        // opt it out of notifications, otherwise do nothing (they've either
        // blocked our access anyway or have never enabled notifications).
        else {
          const token = await notifications
            .getToken({ requestPermission: false })
            // Don't worry about notification setup errors when the user is
            // disabling them anyway.
            .catch(() => null)
          if (token) {
            await notifications.postToken({ token, optOut: true })
          }
        }
      } catch (error) {
        if (
          error instanceof NotificationsPermissionError &&
          error.status === "denied"
        ) {
          openNotificationSettings(t)
        } else {
          logError(
            new DisplayableError(
              enabled
                ? t(
                    "An error occurred enabling push notifications. Please try again.",
                  )
                : t(
                    "An error occurred disabling push notifications. Please try again.",
                  ),
              error,
            ),
          )
        }

        setEnabledOptimistic(!enabled)
      }
    },
    [t],
  )

  let control: JSX.Element = <ActivityIndicator size="medium" />
  switch (permissionStatus?.data) {
    case "unavailable":
      control = <Text textStyle="body">{t("Unavailable")}</Text>
      break
    case "denied":
      control = (
        <Touchable
          id="settings-notifications-enable"
          feedback="opacity"
          onPress={() => openNotificationSettings(t)}
        >
          <Text textStyle="body" color="accent">
            {t("Enable")}
          </Text>
        </Touchable>
      )
      break
    case "unauthorized":
      control = (
        <Switch value={enabledOptimistic} onValueChange={toggleNotifications} />
      )
      break
    case "authorized":
    case "limited":
      if (!enabled.isFetching) {
        control = (
          <Switch
            value={enabledOptimistic ?? enabled.data}
            onValueChange={toggleNotifications}
          />
        )
      }
      break
  }

  return (
    <SettingsFieldset title={t("Notifications")}>
      <SettingsRow label={t("Receive push notifications")}>
        {control}
      </SettingsRow>
    </SettingsFieldset>
  )
}
