"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAppThemeColors = void 0;
const color_1 = require("./color");
/**
 * This augments the set of theme colors chosen by the tenant with additional
 * colors based on what we think is best.
 *
 * *Why not let the tenant choose every color?*
 *
 * We limit the set of colors that the tenant can choose to simplify theme
 * maintenance. The more colors that we let the tenant choose the more careful
 * we have to be about the way we use them (e.g. to avoid white text on a white
 * background) and the harder it will be to to make changes to our theme system.
 * Of course the downside to placing limitations on the tenant is that they may
 * not be able to accomplish the styling they would like. Over time we should
 * keep an eye on tenant satisfaction with our theming options and re-evaluate
 * the options we give them as necessary.
 *
 * *Why is this function called on the client to generate derived colors at
 * runtime instead of being called on the server so the colors can be baked
 * into the app manifest?*
 *
 * As a rule of thumb derived values shouldn't be saved in the manifest. This is
 * both for performance reasons (the manifest is already very large) and for
 * maintainability (we don't yet have a good versioning system for the manifest
 * so once a field is put into the manifest it is very difficult to change).
 */
function getAppThemeColors({ accentColorLight, accentColorDark, }) {
    const onAccentColors = ["#ffffff", "#000000"];
    const onAccentColorLight = (0, color_1.getBestColorAgainst)(accentColorLight, onAccentColors);
    const onAccentColorDark = (0, color_1.getBestColorAgainst)(accentColorDark, onAccentColors);
    return {
        accentColorLight,
        accentColorDark,
        onAccentColorLight,
        onAccentColorDark,
    };
}
exports.getAppThemeColors = getAppThemeColors;
