import { Q } from "@nozbe/watermelondb"
import { map } from "rxjs"

import { PROGRESS_VERSION } from "@treefort/constants"
import { IKeyValueStore } from "@treefort/lib/types/settings"
import { keyToId } from "@treefort/lib/watermelon-key-to-id"

import { getClient } from "../db"
import { Progress } from "../models/progress"
import { KeyValueStore } from "./key-value"

type ProgressOptions = {
  profileId: string | null
}

const CURRENT_AUDIO_PROGRESS_KEY = `currentProgressKey.${PROGRESS_VERSION}`

const getProfileCurrentAudioKey = (profileId: string | null) =>
  `${CURRENT_AUDIO_PROGRESS_KEY}:${profileId || ""}`

class ProgressStore extends KeyValueStore<Progress> implements IKeyValueStore {
  constructor() {
    super(Progress.table)
  }

  async getCurrentAudioProgress({ profileId }: ProgressOptions) {
    return await getClient().localStorage.get<string>(
      getProfileCurrentAudioKey(profileId),
    )
  }

  async saveCurrentAudioProgress({
    profileId,
    value,
  }: {
    profileId: string | null
    value: string
  }) {
    await getClient().localStorage.set(
      getProfileCurrentAudioKey(profileId),
      value,
    )
  }

  async clearCurrentAudioProgress({ profileId }: ProgressOptions) {
    await getClient().localStorage.remove(getProfileCurrentAudioKey(profileId))
  }

  async getAllByCurrentUser<TProgressItemData>({ profileId }: ProgressOptions) {
    const userId = this.getUserId()

    const progressRecords = await getClient()
      .get<Progress>(Progress.table)
      .query(Q.where("userId", userId), Q.where("profileId", profileId || null))

    return progressRecords.map((p) => {
      try {
        return {
          timestamp: p.updatedAtDate,
          value: JSON.parse(p.data) as TProgressItemData,
        }
      } catch {
        return null
      }
    })
  }

  observeSingle(
    key: string,
    { userId, profileId }: { userId: string; profileId: string | null },
  ) {
    return (
      getClient()
        .get<Progress>(Progress.table)
        // Note: we're not using .findAndObserve here because that throws
        // an error if the item does not already exist (which can happen
        // if the item is removed from library then re-added).
        .query(
          Q.where(
            "id",
            keyToId({
              collection: Progress.table,
              key,
              userId,
              profileId,
            }),
          ),
          Q.take(1),
        )
        .observeWithColumns(["updatedAtDate"])
        .pipe(map((arr) => (arr.length ? arr[0] : undefined)))
    )
  }

  observeCount() {
    return getClient().get<Progress>(Progress.table).query().observeCount(true)
  }
}

export const progressStore = new ProgressStore()
