import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import bytes from "bytes"
import styled from "styled-components/native"

import { DisplayableError } from "@treefort/lib/displayable-error"
import { useWillUnmount } from "@treefort/lib/use-will-unmount"

import { useAskBeforeUsingCellular } from "../hooks/use-ask-before-using-cellular"
import confirm from "../lib/confirm"
import DownloadItem from "../lib/download-item"
import downloadManager from "../lib/download-manager"
import { logError } from "../lib/logging"
import ActivityIndicator from "./activity-indicator"
import SettingsFieldset from "./settings-fieldset"
import SettingsRow from "./settings-row"
import Switch from "./switch"
import Text from "./text"
import Touchable from "./touchable"

const DeleteAllDownloadsTouchable = styled(Touchable)`
  display: flex;
  justify-content: center;
  height: ${(props) => props.theme.button.height.medium}px;
`

export default function SettingsDownloads(): JSX.Element {
  const [totalSizeOfDownloads, setTotalSizeOfDownloads] = useState<number>()
  const [askBeforeUsingCellular, setAskBeforeUsingCellular] =
    useAskBeforeUsingCellular()
  const willUnmount = useWillUnmount()
  const { t } = useTranslation()

  const updateTotalSizeOfDownloads = useCallback(
    () =>
      downloadManager
        .getTotalSizeOfDownloads()
        .then((size) => !willUnmount.current && setTotalSizeOfDownloads(size))
        .catch(logError),
    [willUnmount],
  )

  const onPressDeleteAllDownloads = useCallback(async () => {
    const confirmed = await confirm({
      title: t("Delete all downloads?"),
      message: t(
        "Are you sure you want to delete all downloads from your device?",
      ),
      confirmLabel: t("Delete all"),
      cancelLabel: t("Cancel"),
      danger: true,
    })
    if (confirmed) {
      if (!willUnmount.current) {
        setTotalSizeOfDownloads(undefined)
      }
      DownloadItem.deleteAll()
        .then(updateTotalSizeOfDownloads)
        .catch(async (error) => {
          logError(
            new DisplayableError(
              t(
                "An error occurred while deleting all downloads. Please try again.",
              ),
              error,
            ),
          )
        })
    }
  }, [updateTotalSizeOfDownloads, willUnmount, t])

  useEffect(() => {
    updateTotalSizeOfDownloads()
  }, [updateTotalSizeOfDownloads])

  return (
    <SettingsFieldset title={t("Downloads")}>
      <SettingsRow label={t("Total storage used")}>
        {totalSizeOfDownloads === undefined ? (
          <ActivityIndicator size="medium" />
        ) : (
          <Text textStyle="strong">
            {totalSizeOfDownloads > 0
              ? bytes(totalSizeOfDownloads, { decimalPlaces: 1 })
              : t("None")}
          </Text>
        )}
      </SettingsRow>
      <SettingsRow label={t("Ask before using cellular")} showBorder>
        <Switch
          value={askBeforeUsingCellular}
          onValueChange={setAskBeforeUsingCellular}
        />
      </SettingsRow>
      <SettingsRow justifyContent="center" paddingVertical="none" showBorder>
        <DeleteAllDownloadsTouchable
          id="delete-all-downloads"
          feedback="opacity"
          onPress={onPressDeleteAllDownloads}
        >
          <Text textStyle="button" color="danger">
            {t("Delete all downloads")}
          </Text>
        </DeleteAllDownloadsTouchable>
      </SettingsRow>
    </SettingsFieldset>
  )
}
