import React from "react"
import { useTranslation } from "react-i18next"
import { Platform, View } from "react-native"

import { IntegrationProvider } from "@treefort/api-spec"
import {
  SubscriptionPlanResponse,
  UserSubscription,
  UserSubscriptionsResponse,
} from "@treefort/api-spec"
import { useAuth } from "@treefort/lib/auth-provider"

import { ActionLink, AsyncActionLink } from "../../../components/action-link"
import Box from "../../../components/box"
import Column from "../../../components/column"
import { Heading } from "../../../components/heading"
import { RestorePurchasesLink } from "../../../components/restore-purchases-link"
import { SubscriptionCard } from "../../../components/subscription-card"
import Text from "../../../components/text"
import config from "../../../config"
import { useUserSubscriptions } from "../../../hooks/subscriptions"
import { useSubscriptionPlan } from "../../../hooks/subscriptions"
import { useAsyncViewPropsForQueries } from "../../../hooks/use-async-view-props-for-queries"
import { useOpenCheckoutPage } from "../../../hooks/use-open-checkout-page"
import { useSignUpOptionsData } from "../../../hooks/use-sign-up-options-data"
import useUserInfo from "../../../hooks/use-user-info"
import { kebabCase } from "../../../lib/kebab-case"
import {
  canCancelPlanFromProvider,
  canOpenBillingPortalForProvider,
  canPurchasePlanFromProvider,
  openBillingPortal,
} from "../../../lib/subscription-plans"
import {
  canCancelSubscription,
  getCurrentSubscription,
  subscriptionWillRenew,
} from "../../../lib/subscriptions"
import MenuLayout from "../../layouts/menu"

export function SubscriptionScreen(): JSX.Element {
  const auth = useAuth()
  const userSubscriptions = useUserSubscriptions()
  const subscription = getCurrentSubscription(userSubscriptions.data)
  const signUpOptionsData = useSignUpOptionsData()

  const pageState =
    !auth.loading && auth.user === null
      ? "UNAUTHENTICATED"
      : userSubscriptions.data?.redundantSubscriptionIds.length
        ? "DOUBLE_SUBSCRIBED"
        : subscription
          ? "MANAGE_SUBSCRIPTION"
          : "NO_SUBSCRIPTION"

  const asyncViewProps = useAsyncViewPropsForQueries(
    [userSubscriptions, signUpOptionsData],
    { forceLoading: auth.loading || pageState === "UNAUTHENTICATED" },
  )

  const subscriptionCard = userSubscriptions.data ? (
    <SubscriptionCard userSubscriptions={userSubscriptions.data} />
  ) : null

  return (
    <MenuLayout asyncViewProps={asyncViewProps}>
      <Column gap="large" alignItems="stretch">
        {pageState === "DOUBLE_SUBSCRIBED" ? (
          <DoubleSubscriptionMessage />
        ) : pageState === "MANAGE_SUBSCRIPTION" && userSubscriptions.data ? (
          <>
            {subscriptionCard}
            <ManageSubscription userSubscriptions={userSubscriptions.data} />
          </>
        ) : (
          <NoSubscriptionMessage />
        )}
      </Column>
    </MenuLayout>
  )
}

/**
 * Message to show users with no subscription. There's no way to end up on this
 * page without a subscription except through a deep link, so there's no reason
 * to get fancy with what we display.
 */
function NoSubscriptionMessage() {
  const { t } = useTranslation()
  return (
    <Heading textStyle="headingMedium" alignment="center" level={3}>
      {t("No active subscription found.")}
    </Heading>
  )
}

/**
 * Message to show users who have more than one subscription. Really we should
 * update this with details about each subscription.
 */
function DoubleSubscriptionMessage(): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      <Heading textStyle="headingMedium" alignment="center" level={3}>
        {t("You have multiple subscriptions.")}
      </Heading>
      <Text color="secondary" alignment="center" textStyle="body">
        {t(
          "There are multiple active subscriptions associated with your account.",
        )}{" "}
        {t("Please contact us for help resolving this.")}
      </Text>
      <ActionLink
        id="contact-us-multiple-subs"
        to={{ type: "path", path: "/menu/contact" }}
      >
        {t("Contact us")}
      </ActionLink>
    </>
  )
}

export function ManageSubscription({
  userSubscriptions,
}: {
  userSubscriptions: UserSubscriptionsResponse
}): JSX.Element {
  const { t } = useTranslation()
  const subscription = getCurrentSubscription(userSubscriptions)
  const { data: subscriptionPlan } = useSubscriptionPlan(
    subscription?.subscriptionPlanId,
  )
  const manageElsewhereMessage = getManageElsewhereMessage({
    provider: subscription?.provider,
    t,
  })
  return (
    <View>
      {manageElsewhereMessage ? (
        <Box paddingBottom="small" paddingHorizontal="medium">
          <Text color="secondary" textStyle="body">
            {manageElsewhereMessage}
          </Text>
        </Box>
      ) : null}
      <BillingPortalLink
        subscription={subscription}
        subscriptionPlan={subscriptionPlan}
      />
      <ChangePlansLink subscription={subscription} />
      <CancelLink subscription={subscription} />
      <ActionLink
        id="contact-us-menu-link"
        to={{ type: "path", path: "/menu/contact" }}
      >
        {t("Contact us")}
      </ActionLink>
      <RestorePurchasesLink paddingTop="small" />
    </View>
  )
}

/**
 * Render a button that opens the billing portal for the user's plan's provider.
 */
function BillingPortalLink({
  subscription,
  subscriptionPlan,
}: {
  subscription: UserSubscription | undefined
  subscriptionPlan: SubscriptionPlanResponse | undefined
}) {
  const { t, i18n } = useTranslation()

  if (!subscriptionPlan) {
    return null
  }

  const { provider } = subscriptionPlan

  return canOpenBillingPortalForProvider(provider, subscription) ? (
    <AsyncActionLink
      id={`open-billing-portal-${provider}`}
      onPress={() =>
        openBillingPortal({ subscription, subscriptionPlan, i18n })
      }
    >
      {
        // If we can render a cancel button in the app then the main purpose of
        // the billing portal is billing. Otherwise the billing portal must be
        // more of a full-fledged subscription management page.
        canCancelPlanFromProvider(provider)
          ? t("Manage billing")
          : t("Manage subscription")
      }
    </AsyncActionLink>
  ) : null
}

/**
 * Render a button that takes the user to the checkout page to pick a new plan.
 */
function ChangePlansLink({
  subscription,
}: {
  subscription: UserSubscription | undefined
}) {
  const userInfo = useUserInfo()
  const signUpOptionsData = useSignUpOptionsData()
  const openCheckoutPage = useOpenCheckoutPage()
  const isSubscribed =
    typeof userInfo.data?.subscription.subscribed === "number"
  const isCanceledWithTimeLeft =
    isSubscribed && userInfo.data?.subscription.canceledAt
  const loaded = userInfo.data && signUpOptionsData.data

  const { t } = useTranslation()

  const linkText =
    !isSubscribed || isCanceledWithTimeLeft
      ? t("View plans")
      : t("Change plans")

  return loaded &&
    signUpOptionsData.data?.availableSubscriptionPlans.length &&
    // Show the link if the user doesn't have a renewing subscription _or_ if
    // the user's subscription was purchased on the current platform (in which
    // case we can rely on the platform to handle things like proration).
    (!subscriptionWillRenew(subscription) ||
      canPurchasePlanFromProvider(subscription.provider)) ? (
    <ActionLink
      id={kebabCase(
        `${linkText}-${subscription?.provider || "no-sub-provider"}`,
      )}
      onPress={openCheckoutPage}
    >
      {linkText}
    </ActionLink>
  ) : null
}

/**
 * Render a cancel button if we've got an a renewing plan and the plan's
 * provider supports cancellation
 */
function CancelLink({
  subscription,
}: {
  subscription: UserSubscription | undefined
}) {
  const { t } = useTranslation()

  return subscription && canCancelSubscription(subscription) ? (
    <ActionLink
      id={kebabCase(`cancel-sub-${subscription.provider}`)}
      to={{ type: "path", path: "/menu/subscription/cancel" }}
    >
      {t("Cancel subscription")}
    </ActionLink>
  ) : null
}

/**
 * Message to show users who subscribed on a different platform and have to go
 * elsewhere to manage their subscription.
 */
function getManageElsewhereMessage({
  provider,
  t,
}: {
  provider?: IntegrationProvider
  t: ReturnType<typeof useTranslation>["t"]
}) {
  switch (provider) {
    case "appStore":
      // Google doesn't allow us to link to Apple Subscriptions on Android and
      // Apple doesn't allow us to show a cancel button *anywhere* so an
      // explanation is helpful on both Android and the web.
      return Platform.OS === "android" || Platform.OS === "web"
        ? t(
            "Your subscription was purchased from Apple and can only be managed via Apple Subscriptions. If you have questions or need help, please contact us.",
          )
        : null
    case "playStore":
      // We can show an in-app option to cancel the subscription on the web, so
      // really an explanation is only needed on iOS.
      return Platform.OS === "ios"
        ? t(
            "Your subscription was purchased from Google and can only be managed via the Play Store. If you have questions or need help, please contact us.",
          )
        : null
    case "webPayment":
    case "stripe":
      // We're not allowed to present management options on iOS or Android for
      // web-based subscriptions.
      return Platform.OS === "ios" || Platform.OS === "android"
        ? t(
            "Your subscription was purchased on the web and can only be managed via the {{appName}} web app. If you have questions or need help, please contact us.",
            { appName: config.APP_NAME },
          )
        : null
    default:
      return null
  }
}
