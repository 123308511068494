"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBestColorAgainst = exports.getContrast = exports.mixColors = exports.hexToRgba = exports.colorWithAlpha = void 0;
const clamp_1 = require("./clamp");
/*
 * Converts a base 10 number to a two-digit hexadecimal (clamped from 0 to 255)
 */
function toHex(num) {
    return (0, clamp_1.clamp)(Math.round(num), 0, 255).toString(16).padStart(2, "0");
}
/*
 * Converts a hexadecimal number to a base 10 number
 */
function fromHex(num) {
    return parseInt(num, 16);
}
/*
 * Converts a numeric alpha percentage (0-1) to its hex equivalent (00-ff)
 */
function alphaToHex(alpha) {
    return toHex(alpha * 255);
}
/**
 * Adds alpha to a 6-digit hex color. Alpha is specified as a numeric percentage
 * from 0-1. This function does not work with shorthand hex colors.
 */
function colorWithAlpha(color, alpha) {
    return color + alphaToHex(alpha);
}
exports.colorWithAlpha = colorWithAlpha;
/**
 * Parse a hex color into it's red, green, blue, and alpha components. Defaults
 * to opaque black if bits of the color are missing.
 */
function hexToRgba(color) {
    const [r = 0, g = 0, b = 0, a = 255] = (color.match(/[0-9a-f]{2}/gi) || []).map(fromHex);
    return [r, g, b, a];
}
exports.hexToRgba = hexToRgba;
/**
 * Mixes two numbers according to the amount (if amount is 0 then color a is
 * returned, if amount is 1 then color b is returned, anything in-between
 * results in a mix of the two colors that varies by the amount).
 * See: https://stackoverflow.com/a/56348573/2747759
 */
function mixColors(a, b, amount = 0.5) {
    const [rA, gA, bA] = hexToRgba(a);
    const [rB, gB, bB] = hexToRgba(b);
    const rMix = toHex(rA + (rB - rA) * amount);
    const gMix = toHex(gA + (gB - gA) * amount);
    const bMix = toHex(bA + (bB - bA) * amount);
    return "#" + rMix + gMix + bMix;
}
exports.mixColors = mixColors;
/**
 * Returns the relative luminance of a hex color. See:
 * https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-procedure
 */
function getLuminance(color) {
    const [r, g, b] = hexToRgba(color)
        .slice(0, 3)
        .map((v) => {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return r * 0.2126 + g * 0.7152 + b * 0.0722;
}
/**
 * Returns the contrast between two hex colors. See:
 * https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-procedure
 */
function getContrast(a, b) {
    const luminanceA = getLuminance(a);
    const luminanceB = getLuminance(b);
    const brightest = Math.max(luminanceA, luminanceB);
    const darkest = Math.min(luminanceA, luminanceB);
    return (brightest + 0.05) / (darkest + 0.05);
}
exports.getContrast = getContrast;
/**
 * Choose the best foreground color from a list of options based on each color's
 * contrast against a background color. In the event that 1+ colors meet minimum
 * contrast requirements, the first matching color in the list will be chosen.
 * In the event that no colors meet requirements, the color with the maximum
 * contrast will be chosen.
 */
function getBestColorAgainst(backgroundColor, foregroundColors) {
    var _a;
    const contrasts = foregroundColors.map((choice) => [choice, getContrast(choice, backgroundColor)]);
    // The best color is the first color in the list with a contrast >= 4.5
    const bestColor = (_a = contrasts.find((c) => c[1] >= 4.5)) === null || _a === void 0 ? void 0 : _a[0];
    if (bestColor) {
        return bestColor;
    }
    // The least worst color is the most contrasty color in the list regardless of
    // it's order in the list
    const leastWorstColor = contrasts.sort((a, b) => b[1] - a[1])[0][0];
    return leastWorstColor;
}
exports.getBestColorAgainst = getBestColorAgainst;
