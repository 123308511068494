import React from "react"

import { UserSubscriptionsResponse } from "@treefort/api-spec"
import { useAuth } from "@treefort/lib/auth-provider"

import { useSubscriptionPlan } from "../hooks/subscriptions"
import { kebabCase } from "../lib/kebab-case"
import { getCurrentSubscription } from "../lib/subscriptions"
import { ActionCard } from "./action-card"
import { SubscriptionStatusSummary } from "./subscription-status-summary"
import Text from "./text"

export function SubscriptionCard({
  userSubscriptions,
}: {
  userSubscriptions: UserSubscriptionsResponse
}): JSX.Element | null {
  const auth = useAuth()
  const { data: subscriptionPlan } = useSubscriptionPlan(
    getCurrentSubscription(userSubscriptions)?.subscriptionPlanId,
  )

  return auth.user && subscriptionPlan ? (
    <ActionCard
      id={kebabCase(`sub-card-${subscriptionPlan.id}-${subscriptionPlan.name}`)}
      title={subscriptionPlan.name}
      titleTextStyle="headingMedium"
      childrenGap="xsmall"
    >
      {subscriptionPlan.provider === "groupMembership" ? (
        <Text color="secondary" textStyle="body" numberOfLines={2}>
          {subscriptionPlan.description}
        </Text>
      ) : null}
      <SubscriptionStatusSummary
        userSubscriptions={userSubscriptions}
        subscriptionPlan={subscriptionPlan}
        textStyle="strong"
        textColor="secondary"
        showSecondaryLabel
      />
    </ActionCard>
  ) : null
}
