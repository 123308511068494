import React, { useCallback, useEffect, useState } from "react"

import icons from "@treefort/tokens/app/icons"

import { usePlaybackState } from "../hooks/audio"
import audioPlayer, { Event, PlaybackState } from "../lib/audio-player"
import IconButton, { IconButtonProps } from "./icon-button"

export default function AudioPlayerPlayButton({
  iconSize,
}: {
  iconSize: IconButtonProps["iconSize"]
}): JSX.Element {
  const playbackState = usePlaybackState()
  const [optimisticState, setOptimisticState] = useState<
    PlaybackState | undefined
  >()
  const state = optimisticState || playbackState

  const onPress = useCallback(() => {
    if (state === PlaybackState.Playing || state === PlaybackState.Buffering) {
      setOptimisticState(PlaybackState.Paused)
      audioPlayer.pause()
    } else if (
      state === PlaybackState.Paused ||
      state === PlaybackState.Idle ||
      state === PlaybackState.Error
    ) {
      setOptimisticState(PlaybackState.Buffering)
      audioPlayer.play()
    }
  }, [state])

  // Clear the optimistic state once the audio player's actual state catches up
  useEffect(() => {
    setOptimisticState(undefined)
  }, [playbackState])

  // Clear the optimistic state on any error from the audio player
  useEffect(
    () => audioPlayer.on(Event.Error, () => setOptimisticState(undefined)),
    [],
  )

  return (
    <IconButton
      id="audio-player-play"
      onPress={onPress}
      iconSize={iconSize}
      state={
        state === PlaybackState.Loading || state === PlaybackState.Buffering
          ? "loading"
          : "default"
      }
      source={state === PlaybackState.Playing ? icons.pause : icons.play}
      label={state === PlaybackState.Playing ? "Pause" : "Play"}
    />
  )
}
