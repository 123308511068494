import React from "react"

import { t } from "i18next"

import {
  AppModulePodcast,
  PodcastEpisodeResponse,
  PodcastResponse,
} from "@treefort/api-spec"

import { AsyncButton } from "../../../../components/async-button"
import Box from "../../../../components/box"
import CenteredContent from "../../../../components/centered-content"
import Column from "../../../../components/column"
import {
  PodcastModuleSummary,
  PodcastModuleEpisode,
  PodcastModuleEpisodeSeparator,
  PodcastModuleEpisodesHeading,
  PodcastModulePresentation,
} from "../../../../components/modules/podcast"
import Row from "../../../../components/row"
import Text from "../../../../components/text"
import { getContentQueryKey } from "../../../../hooks/use-content"
import authenticator from "../../../../lib/authenticator"
import { shouldShowLockIcon } from "../../../../lib/content"
import { queryClient } from "../../../../lib/query-client"
import { getAbsoluteLineHeight } from "../../../../lib/text-style"
import { ListViewModule, ItemArgs } from "../list-view-module"

type Item =
  | { type: "summary"; data: PodcastResponse }
  | { type: "episode"; data: PodcastEpisodeResponse }
  | { type: "errorMessage" }

export class PodcastListViewModule
  implements ListViewModule<AppModulePodcast, Item>
{
  readonly appModule: AppModulePodcast
  readonly appHeaderPresentation = undefined
  readonly appHeaderState = undefined
  readonly gapSize = 0
  readonly maxWidth = undefined

  private podcastResponse: PodcastResponse

  static episodeSeparatorHeight = 1

  static episodeActionsHeight = 48

  static errorMessageMaxHeight = 300

  constructor(appModule: AppModulePodcast, podcastResponse: PodcastResponse) {
    this.appModule = appModule
    this.podcastResponse = podcastResponse
  }

  private getPresentation = ({ tokens }: ItemArgs<Item>) =>
    tokens.podcastModule.presentation as PodcastModulePresentation

  private getPaddingHorizontal = (args: ItemArgs<Item>) =>
    this.getPresentation(args) === "wide" ? "pagePaddingHorizontal" : "none"

  getItems = () => [
    { type: "summary" as const, data: this.podcastResponse },
    ...this.podcastResponse.details.episodes.map((episode) => ({
      type: "episode" as const,
      data: episode,
    })),
    ...(this.podcastResponse.details.episodes.length === 0
      ? [{ type: "errorMessage" as const }]
      : []),
  ]

  renderItem = (args: ItemArgs<Item>) => {
    switch (args.item.type) {
      case "summary":
        return (
          <CenteredContent paddingHorizontal={this.getPaddingHorizontal(args)}>
            <Column
              alignItems="stretch"
              justifyContent="space-between"
              height={this.getItemSize(args)}
              paddingTop={args.safeAreaInsets.top}
            >
              {
                // We use a "modal" text toggle for the summary description so
                // that the summary height doesn't change after rendering (which
                // wreaks havoc on the list view. Worse UX, but overall worth
                // it.
              }
              <PodcastModuleSummary
                presentation={this.getPresentation(args)}
                podcast={args.item.data}
                showLockIcon={shouldShowLockIcon(args.item.data.availability)}
              />
              <PodcastModuleEpisodesHeading />
            </Column>
          </CenteredContent>
        )
      case "episode":
        return (
          <CenteredContent paddingHorizontal={this.getPaddingHorizontal(args)}>
            <PodcastModuleEpisodeSeparator />
            <PodcastModuleEpisode
              presentation={this.getPresentation(args)}
              podcast={this.podcastResponse}
              episode={args.item.data}
              height={this.getItemSize(args)}
            />
          </CenteredContent>
        )
      case "errorMessage":
        return (
          <CenteredContent paddingHorizontal="pagePaddingHorizontal">
            <Box
              borderTopColor="primary"
              paddingTop="medium"
              paddingHorizontal={
                this.getPresentation(args) === "wide" ? "large" : undefined
              }
            >
              <Text textStyle="body">
                {t(
                  "Something went wrong fetching episodes for this podcast. Please try again.",
                )}
              </Text>
              <Row paddingTop="large">
                <AsyncButton
                  id={`refetch-podcast-${this.appModule.config.contentId}`}
                  onPress={() =>
                    queryClient.refetchQueries(
                      getContentQueryKey(
                        authenticator.getUser(),
                        this.appModule.config.contentId,
                      ),
                      { exact: true },
                    )
                  }
                >
                  {t("Refresh")}
                </AsyncButton>
              </Row>
            </Box>
          </CenteredContent>
        )
      default:
        return <></>
    }
  }

  getItemKey = ({ pageKey, indexInPage }: ItemArgs<Item>) =>
    pageKey + indexInPage + this.appModule.id

  getItemSize = (args: ItemArgs<Item>) => {
    const { tokens, safeAreaInsets } = args
    const { spacing } = tokens

    switch (args.item.type) {
      case "summary": {
        switch (this.getPresentation(args)) {
          case "wide":
            return (
              safeAreaInsets.top + // Spacing for the app header
              tokens.podcastModule.artwork.size + // The artwork/title/description lockup
              spacing.jumbo + // Spacing
              getAbsoluteLineHeight("headingMedium", tokens) + // "Episodes" title
              spacing.medium // Spacing
            )
          case "narrow":
            return (
              safeAreaInsets.top + // Spacing for the app header
              tokens.podcastModule.artwork.size + // The artwork/title lockup
              (args.item.data.description
                ? spacing.large + getAbsoluteLineHeight("body", tokens) * 3
                : 0) + // The podcast description
              spacing.large + // Spacing
              getAbsoluteLineHeight("headingMedium", tokens) + // The "Episodes" title
              spacing.medium // Spacing
            )
          default:
            return 0
        }
      }

      case "episode": {
        switch (this.getPresentation(args)) {
          case "wide":
            return (
              spacing.large + // Top padding
              PodcastListViewModule.episodeSeparatorHeight +
              getAbsoluteLineHeight("captionStrong", tokens) + // Date height
              spacing.tiny + // Date/title spacing
              getAbsoluteLineHeight("strong", tokens) + // Title height
              (args.item.data.description
                ? spacing.tiny + getAbsoluteLineHeight("caption", tokens) * 2
                : 0) + // Description height
              spacing.large // Bottom padding
            )
          case "narrow":
            return (
              spacing.medium + // Top padding
              PodcastListViewModule.episodeSeparatorHeight +
              getAbsoluteLineHeight("captionStrong", tokens) + // Date height
              spacing.tiny + // Date/title spacing
              getAbsoluteLineHeight("strong", tokens) + // Title height
              (args.item.data.description
                ? spacing.tiny + getAbsoluteLineHeight("caption", tokens) * 2
                : 0) + // Description height
              spacing.xsmall + // Spacing
              (args.item.data.audioMedia?.status === "available"
                ? PodcastListViewModule.episodeActionsHeight
                : 0) + // Actions
              spacing.small // Bottom padding
            )
          default:
            return 0
        }
      }

      case "errorMessage":
        return PodcastListViewModule.errorMessageMaxHeight
    }
  }
}
