import React from "react"

import styled from "styled-components/native"

import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"
import icons from "@treefort/tokens/app/icons"

import { Spacing } from "../lib/spacing"
import { AppLink, AppLinkProps } from "./app-link"
import Column from "./column"
import Icon from "./icon"
import Image from "./image"
import Row from "./row"
import Text, { TextStyle } from "./text"
import Touchable from "./touchable"

export function ActionCard({
  id,
  title,
  titleTextStyle = "strong",
  children,
  childrenGap = "tiny",
  imageUrl,
  imageSize = 42,
  onPress,
  to,
}: {
  id: string
  title: string
  titleTextStyle?: TextStyle
  children: React.ReactNode
  childrenGap?: Spacing
  imageSize?: number
  imageUrl?: string
  onPress?: () => unknown
  to?: AppLinkProps["to"]
}) {
  const card = (
    <Row
      backgroundColor="tertiary"
      borderRadius="roundedMedium"
      paddingVertical="small"
      paddingHorizontal="medium"
      gap="small"
    >
      {imageUrl ? (
        <ActionCardImage
          source={{ uri: getOptimizedImageSource(imageUrl, imageSize) }}
          resizeMode="cover"
          size={imageSize}
        />
      ) : null}
      <ActionCardChildren alignItems="flex-start" gap={childrenGap} flex={1}>
        <Text textStyle={titleTextStyle} numberOfLines={1}>
          {title}
        </Text>
        {children}
      </ActionCardChildren>
      {onPress || to ? (
        <Icon size="small" color="secondary" source={icons.chevronRight} />
      ) : null}
    </Row>
  )

  return to ? (
    <AppLink id={id} to={to} aria-label={title} onPress={onPress}>
      {card}
    </AppLink>
  ) : onPress ? (
    <Touchable id={id} role="button" aria-label={title} onPress={onPress}>
      {card}
    </Touchable>
  ) : (
    card
  )
}

const ActionCardChildren = styled(Column)`
  margin-right: auto;
`

const ActionCardImage = styled(Image)<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.theme.borderRadius.roundedFull}px;
  background-color: ${(props) => props.theme.colors.loading.image};
`
