import { useCallback } from "react"
import { Platform, GestureResponderEvent } from "react-native"

import { AppLink } from "@treefort/api-spec"

import analytics from "../lib/analytics"
import {
  getUrlFromAppLink,
  getParamsFromAppLink,
  normalizeAppLink,
} from "../lib/app-link"
import openUrl from "../lib/open-url"
import { isParent } from "../lib/parental-gateway"
import { setUrlParams } from "../lib/url"
import {
  Params,
  SEARCH_TAB,
  getPathFromAppLink,
  getPathFromTab,
} from "../navigation/routes"
import useAppManifest from "./use-app-manifest"
import { useAppTab } from "./use-app-tab"
import { useMenu } from "./use-menu"
import { useNavigate } from "./use-navigate"
import { useParentalGateway } from "./use-parental-gateway"
import { useSearch } from "./use-search"

type PressEvent =
  | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  | GestureResponderEvent
  | null

const noop = () => {}

/**
 * Returns helpers for working with app links in the current context.
 */
export function useAppLinkHelpers() {
  const tab = useAppTab()
  const manifest = useAppManifest()
  const parentalGateway = useParentalGateway()
  const menu = useMenu()
  const search = useSearch()
  const navigate = useNavigate()

  const open = useCallback(
    ({ appLink, params }: { appLink: AppLink; params?: Params }) => {
      const link = normalizeAppLink(appLink)
      // Our "url" link type needs some special attention. On the web we eschew
      // a click/press handler to get the benefits of vanilla html links. We
      // also set rel="noopener noreferrer" for security and target="_blank" to
      // ensure that the app can keep playing media in the background when the
      // user visits the link.
      if (link.type === "url") {
        openUrl(setUrlParams(link.url, params), { parentalGateway })
      } else {
        const path = getPathFromAppLink(link, tab, manifest)
        const allParams = getParamsFromAppLink(link, params)

        // If the path opens the menu things are a bit more complicated and
        // need to be delegated to the menu helper, otherwise we can call
        // `navigate` directly.
        if (path.startsWith("/menu")) {
          menu.open(path.replace(/^\/menu/, ""), allParams)
        } else if (path === getPathFromTab(SEARCH_TAB)) {
          search.open(allParams)
        } else {
          navigate(path, allParams)
        }
      }
    },
    [tab, manifest, parentalGateway, menu, search, navigate],
  )

  const getProps = useCallback(
    ({
      id,
      appLink: appLinkProp,
      label,
      onPress: onPressProp = noop,
      params,
    }: {
      id: string
      appLink: AppLink
      label: string
      onPress?: (event?: PressEvent) => unknown
      params?: Params
    }) => {
      const appLink = normalizeAppLink(appLinkProp)
      const url = getUrlFromAppLink(appLink, tab, manifest, params)

      const platformSpecificProps = Platform.select({
        web: {
          hrefAttrs:
            // Open external links in a new tab
            appLink.type === "url"
              ? {
                  rel: "noopener noreferrer",
                  target: "_blank",
                }
              : undefined,
          // Use the native onClick handler on the web so that the click
          // event propagates normally and can be handled by libraries like
          // GA4. The onPress handler from react-native-web attempts to
          // mimick react-native behavior which involves stopping event
          // propagation.
          onClick: async (event?: PressEvent) => {
            analytics.logPress(id)

            // Protect young'uns from external links
            if (
              appLink.type === "url" &&
              parentalGateway &&
              !(await isParent())
            ) {
              event?.preventDefault()
              return
            }

            // If we're handling an internal link, prevent the default link
            // behavior and handle it with HTML5 history
            if (appLink.type !== "url") {
              event?.preventDefault()
              open({ appLink, params })
            }

            onPressProp(event)
          },
        },
        default: {
          onPress: async (event?: PressEvent) => {
            open({ appLink, params })
            onPressProp(event)
          },
        },
      })
      return {
        href: url,
        role: "link" as const,
        "aria-label": label,
        ...platformSpecificProps,
      }
    },
    [open, tab, manifest, parentalGateway],
  )

  return { open, getProps }
}
