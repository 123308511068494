import React, { ReactNode } from "react"
import { View } from "react-native"

import styled from "styled-components/native"

import { AppLink as AppLinkType } from "@treefort/api-spec"
import { getOptimizedImageSource } from "@treefort/lib/get-optimized-image-source"
import rawTokens from "@treefort/tokens/app"

import { IS_BOT } from "../lib/is-bot"
import { kebabCase } from "../lib/kebab-case"
import { AppLink } from "./app-link"
import { ImageWithFade, ImageWithFadeProps } from "./image-with-fade"
import LockedContentIndicator from "./locked-content-indicator"
import Text from "./text"
import TextPlaceholder from "./text-placeholder"
import { useTokens } from "./tokens-provider"

interface ArtworkListItemProps {
  data: ItemData
  artworkShape: ArtworkShape
  // If dimensions is defined, artworkShape will still be
  // used for border radius
  artworkDimensions?: Dimensions
  titleNumberOfLines?: number
  subtitleNumberOfLines?: number
  withoutBottomSpacing?: boolean
  withoutRightSpacing?: boolean
  height?: number
  progressBar?: JSX.Element
}

type ItemData =
  | {
      isLoading: false
      artwork?: string
      link?: AppLinkType
      title: string
      subtitle?: string
      showLockIcon?: boolean
      linkParams?: Record<string, string>
    }
  | {
      isLoading: true
    }

export type ArtworkShape = keyof typeof rawTokens.artworkListItem.shape

type Dimensions = {
  width: number
  height: number
}

export const TEXT_MARGIN_VERTICAL = rawTokens.spacing.tiny * 2

export const TEXT_GAP_VERTICAL = rawTokens.spacing.tiny

export const TEXT_STYLE_TITLE = "headingSmall"

export const TEXT_STYLE_SUBTITLE = "caption"

const ItemContainer = styled.View<{
  withoutBottomSpacing?: boolean
  withoutRightSpacing?: boolean
  width?: number
  height?: number
}>`
  ${(props) => (props.width ? `width: ${props.width}px;` : "flex: 1")};
  ${(props) => (props.height ? `height: ${props.height}px` : "")};
  margin-right: ${({ theme, withoutRightSpacing }) =>
    withoutRightSpacing ? 0 : theme.artworkListItem.spacing}px;
  margin-bottom: ${({ theme, withoutBottomSpacing }) =>
    withoutBottomSpacing ? 0 : theme.artworkListItem.spacing}px;
`

const ItemArtworkImage = styled(ImageWithFade)<{
  height?: number
  radius: number
  shape: ArtworkShape
}>`
  background-color: ${(props) => props.theme.colors.loading.image};
  width: 100%;
  ${({ theme, shape, height }) =>
    height
      ? `height: ${height}px`
      : `aspect-ratio: ${theme.artworkListItem.shape[shape].aspectRatio}`};
  border-radius: ${(props) => props.radius}px;
`

const ItemText = styled(Text)<{
  textAlign: "left" | "center"
  marginTop: number
  marginBottom: number
}>`
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  width: 100%;
  text-align: ${(props) => props.textAlign};
  flex-direction: row;
`

const ArtworkView = styled(View)`
  position: relative;
`

const ArtworkPlaceholder = styled.View<{
  height?: number
  borderRadius: number
  shape: ArtworkShape
}>`
  width: 100%;
  ${({ theme, shape, height }) =>
    height
      ? `height: ${height}px`
      : `aspect-ratio: ${theme.artworkListItem.shape[shape].aspectRatio}`};
  border-radius: ${(props) => props.borderRadius}px;
  background-color: ${(props) => props.theme.colors.loading.image};
`

const ItemTextPlaceholder = styled(TextPlaceholder)<{
  align: "left" | "center"
  width: string
  marginTop: number
  marginBottom: number
}>`
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  width: ${(props) => props.width};
  ${(props) =>
    props.align === "center" ? `margin-right: auto; margin-left: auto` : ""};
`

function ItemArtwork({
  showLockIcon = false,
  artwork,
  progressBar = undefined,
  ...imageProps
}: {
  height?: number
  radius: number
  shape: ArtworkShape
  showLockIcon?: boolean
  artwork?: string
  progressBar?: JSX.Element
} & Omit<ImageWithFadeProps, "source">): JSX.Element {
  return (
    <ArtworkView>
      <ItemArtworkImage
        {...imageProps}
        source={
          // Don't load artwork for bots (waste of time and bandwidth)
          IS_BOT
            ? undefined
            : {
                uri: getOptimizedImageSource(
                  artwork,
                  // Use a fixed maximum size instead of the exact width/height props
                  // to increase the chances of a cache hit. If we used the exact
                  // width/height props then the same image would likely be requested
                  // several different times at different sizes. Using a fixed size
                  // (that is still in the ballpark of the sizes we are actually
                  // displaying) costs a little bit more bandwidth upfront but saves
                  // more in the long run as the user taps around and the same image
                  // is re-used.
                  rawTokens.artworkListItem.maxSourceSize,
                ),
              }
        }
      />
      {showLockIcon && imageProps.shape !== "circle" ? (
        <LockedContentIndicator />
      ) : null}
      {progressBar}
    </ArtworkView>
  )
}

function ItemLink({
  data,
  children,
}: {
  data: ItemData
  children: ReactNode
}): JSX.Element {
  return !data.isLoading && data.link ? (
    <AppLink
      id={kebabCase(`item-link-${data.link.type}-${data.title}`)}
      to={data.link}
      params={data.linkParams}
      aria-label={data.title}
    >
      {children}
    </AppLink>
  ) : (
    <>{children}</>
  )
}

export default function ArtworkListItem({
  data,
  artworkDimensions,
  artworkShape,
  titleNumberOfLines,
  subtitleNumberOfLines,
  withoutBottomSpacing,
  withoutRightSpacing,
  height,
  progressBar,
}: ArtworkListItemProps): JSX.Element {
  const { tokens } = useTokens()
  const showTitle = Boolean(titleNumberOfLines)
  const showSubtitle = Boolean(subtitleNumberOfLines)
  return (
    <ItemContainer
      withoutRightSpacing={withoutRightSpacing}
      withoutBottomSpacing={withoutBottomSpacing}
      width={artworkDimensions?.width}
      height={height}
    >
      {data?.isLoading ? (
        <>
          <ArtworkPlaceholder
            height={artworkDimensions?.height}
            borderRadius={
              tokens.artworkListItem.shape[artworkShape].borderRadius
            }
            shape={artworkShape}
          />
          {showTitle ? (
            <ItemTextPlaceholder
              marginTop={TEXT_MARGIN_VERTICAL}
              marginBottom={showSubtitle ? 0 : TEXT_MARGIN_VERTICAL}
              textStyle={TEXT_STYLE_TITLE}
              align={artworkShape === "circle" ? "center" : "left"}
              width="75%"
            />
          ) : null}
          {showSubtitle ? (
            <ItemTextPlaceholder
              marginTop={showTitle ? TEXT_GAP_VERTICAL : TEXT_MARGIN_VERTICAL}
              marginBottom={TEXT_MARGIN_VERTICAL}
              textStyle={TEXT_STYLE_SUBTITLE}
              align={artworkShape === "circle" ? "center" : "left"}
              width="50%"
            />
          ) : null}
        </>
      ) : (
        <ItemLink data={data}>
          <ItemArtwork
            resizeMode="cover"
            artwork={data.artwork}
            height={artworkDimensions?.height}
            radius={tokens.artworkListItem.shape[artworkShape].borderRadius}
            shape={artworkShape}
            showLockIcon={data.showLockIcon}
            progressBar={progressBar}
          />
          {showTitle ? (
            <ItemText
              marginTop={TEXT_MARGIN_VERTICAL}
              marginBottom={showSubtitle ? 0 : TEXT_MARGIN_VERTICAL}
              textAlign={artworkShape === "circle" ? "center" : "left"}
              numberOfLines={titleNumberOfLines}
              textStyle={TEXT_STYLE_TITLE}
            >
              {data.title}
            </ItemText>
          ) : null}
          {showSubtitle ? (
            <ItemText
              marginTop={showTitle ? TEXT_GAP_VERTICAL : TEXT_MARGIN_VERTICAL}
              marginBottom={TEXT_MARGIN_VERTICAL}
              textAlign={artworkShape === "circle" ? "center" : "left"}
              numberOfLines={1}
              textStyle={TEXT_STYLE_SUBTITLE}
              color="secondary"
            >
              {data.subtitle}
            </ItemText>
          ) : null}
        </ItemLink>
      )}
    </ItemContainer>
  )
}
