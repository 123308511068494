import React from "react"
import { useTranslation } from "react-i18next"

import styled from "styled-components/native"

import icons from "@treefort/tokens/app/icons"

import { getTextStyleString, TextStyle } from "../lib/text-style"
import Column from "./column"
import Icon from "./icon"
import { SIGN_UP_CARD_MAX_WIDTH_PX } from "./sign-up-option-card"
import Text from "./text"
import TextInput from "./text-input"
import { useTokens } from "./tokens-provider"

export type CheckoutCodeInputState =
  | { type: "idle" }
  | { type: "loading" }
  | { type: "success"; message?: string }
  | { type: "error"; message: string }

const Caption = styled(Text).attrs(() => ({ textStyle: "caption" }))`
  position: absolute;
  top: calc(100% + ${(props) => props.theme.spacing.xtiny}px);
  left: 0;
`

const TextInputContainer = styled.View<{
  layout: "wide" | "narrow"
  type: CheckoutCodeInputState["type"]
}>`
  position: relative;
  width: 100%;
`

const StyledTextInput = styled(TextInput)<{
  textStyle: TextStyle
  type: CheckoutCodeInputState["type"]
}>`
  height: ${({ theme }) => theme.promoCodeInput.height}px;
  border-radius: ${({ theme }) => theme.borderRadius.roundedMedium}px;
  border: ${({ theme }) => theme.spacing.xtiny}px solid
    ${({ theme, type }) =>
      theme.promoCodeInput.borderColor[
        type === "success" ? "success" : "default"
      ]};
  background-color: ${({ theme, type }) =>
    theme.promoCodeInput.backgroundColor[
      type === "success" ? "success" : "default"
    ]};
  padding-left: ${({ theme }) => theme.promoCodeInput.paddingLeft}px;
  padding-right: ${({ theme }) => theme.promoCodeInput.paddingRight}px;
  ${({ theme, textStyle }) => getTextStyleString(textStyle, theme)};
  color: ${({ theme, type }) =>
    type === "success"
      ? theme.colors.text.success
      : theme.promoCodeInput.textColor.default};
`

const TagIconContainer = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  justify-content: center;
  padding-left: ${({ theme }) =>
    theme.promoCodeInput.tagIconContainer.paddingLeft}px;
  pointer-events: none;
`

export function CheckoutCodeInput({
  layout,
  state,
  value,
  autoFocus,
  onChange,
}: {
  layout: "wide" | "narrow"
  state: CheckoutCodeInputState
  value: string
  autoFocus?: boolean
  onChange: (value: string) => void
}) {
  const { tokens } = useTokens()
  const { t } = useTranslation()

  return (
    <Column
      maxWidth={SIGN_UP_CARD_MAX_WIDTH_PX}
      width="100%"
      gap="xtiny"
      alignItems="stretch"
    >
      <TextInputContainer layout={layout} type={state.type}>
        <TagIconContainer>
          <Icon
            source={icons.priceTag}
            size="mediumsmall"
            color={
              state.type === "success"
                ? tokens.colors.success
                : tokens.promoCodeInput.placeholderTextColor
            }
          />
        </TagIconContainer>
        <StyledTextInput
          value={value}
          onChangeText={onChange}
          type={state.type}
          textStyle={value.length ? "captionStrong" : "caption"}
          placeholderTextColor={tokens.promoCodeInput.placeholderTextColor}
          placeholder={t("If you have a code, enter it here...")}
          autoFocus={autoFocus}
        />
      </TextInputContainer>
      {state.type === "success" ? (
        <Caption color="success">{state.message}</Caption>
      ) : state.type === "error" ? (
        <Caption color="danger">{state.message}</Caption>
      ) : state.type === "loading" ? (
        <Caption color="secondary">{t("Verifying...")}</Caption>
      ) : null}
    </Column>
  )
}
