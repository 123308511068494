import React, { useCallback, useRef, useEffect, useState } from "react"

import throttle from "lodash/throttle"
import queryString from "query-string"

import { useCancellable } from "@treefort/lib/cancellable"
import { addEventListeners, triggerAndWaitForEvent } from "@treefort/lib/dom"
import { VideoError } from "@treefort/lib/errors"
import tokens from "@treefort/tokens/app"

import { useEventEmitterValue } from "../../hooks/use-event-emitter-value"
import {
  importHlsJs,
  attachHlsJs,
  isHlsSupportedNatively,
  isHlsUrl,
} from "../../lib/av/hls.web"
import { addPlaybackStateListener, addSeekableListener } from "../../lib/av/web"
import { logWarning } from "../../lib/logging"
import { PlaybackState, VideoPlayer, Event } from "../../lib/video-player"
import PlayerOverlay from "./player-overlay"
import Poster from "./poster"

const noop = () => {}

const videoStyle = { backgroundColor: tokens.colors.black, outline: "none" }

export default function VideoPlayerInlineWeb({
  videoPlayer,
  dimensions,
  autoPlay,
  poster,
  onPressPoster,
}: {
  videoPlayer: VideoPlayer
  dimensions: { width: number; height: number }
  autoPlay?: boolean
  poster?: string
  onPressPoster?: () => void
}): JSX.Element {
  const cleanupHls = useRef(noop)
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null,
  )
  const [seekable, setSeekable] = useState(false)
  const track = useEventEmitterValue(
    videoPlayer,
    Event.Track,
    videoPlayer.getTrack,
  )

  const handleError = useCallback(
    (error: unknown) => {
      videoPlayer.setPlaybackState(PlaybackState.Error)
      const cause = videoElement?.error || error
      logWarning(new VideoError("Playback failed", { cause }))
    },
    [videoPlayer, videoElement],
  )

  const getProgress = useCallback(() => {
    const { currentTime, duration } = videoElement || {}
    return {
      position: currentTime === undefined ? 0 : currentTime * 1000,
      duration: duration === undefined ? 0 : duration * 1000,
    }
  }, [videoElement])

  const [load, loading] = useCancellable(
    async function* () {
      if (videoElement && track) {
        videoPlayer.setPlaybackState(PlaybackState.Loading)
        cleanupHls.current()

        // Load the video
        if (isHlsUrl(track.url) && !isHlsSupportedNatively) {
          const hls = await attachHlsJs(videoElement, handleError)
          cleanupHls.current = () => hls.destroy()
          yield // Allow cancellation _after_ initializing a cleanup function
          yield triggerAndWaitForEvent(
            () => hls.loadSource(queryString.stringifyUrl(track)),
            videoElement,
            "canplay",
          )
        } else {
          yield triggerAndWaitForEvent(
            () => videoElement.load(),
            videoElement,
            "canplay",
          )
        }

        // Set the playback rate
        videoElement.playbackRate = videoPlayer.getPlaybackRate()
      }
    },
    [videoElement, videoPlayer, track, handleError],
  )

  // Load the track any time it changes
  useEffect(() => {
    if (track) {
      loading.current?.cancel()
      load()
    }
  }, [track, load, loading])

  // Jump to the initial position once a video becomes seekable
  useEffect(() => {
    const initialPosition = videoPlayer.getInitialPosition()
    if (seekable && videoElement && initialPosition) {
      videoElement.currentTime = Math.round(initialPosition) / 1000
    }
  }, [videoElement, videoPlayer, seekable])

  // Listen to playback state changes
  useEffect(() => {
    if (videoElement) {
      return addPlaybackStateListener(
        videoElement,
        videoPlayer.setPlaybackState,
      )
    }
  }, [videoElement, videoPlayer])

  // Listen to seekability
  useEffect(() => {
    if (videoElement) {
      return addSeekableListener(videoElement, setSeekable)
    }
  }, [videoElement])

  // Add event listeners for errors and progress updates
  useEffect(() => {
    if (videoElement) {
      return addEventListeners(videoElement, {
        durationchange: () => videoPlayer.publishProgress(getProgress()),
        timeupdate: throttle(() => {
          if (videoElement.currentTime !== undefined) {
            videoPlayer.publishProgress(getProgress())
          }
        }, VideoPlayer.PROGRESS_UPDATE_INTERVAL),
        ended: () => {
          videoElement.pause()
          videoPlayer.publishFinished()
        },
        ratechange: () => {
          if (videoElement) {
            videoPlayer.setPlaybackRate(videoElement.playbackRate)
          }
        },
        seeked: () => videoPlayer.publishSeeked(getProgress().position),
        error: handleError,
      })
    }
  }, [videoElement, videoPlayer, handleError, getProgress])

  // Lazy load hls.js if HLS is not supported natively on this client
  useEffect(() => {
    if (!isHlsSupportedNatively) {
      importHlsJs()
    }
  }, [])

  // Handle requests to play/pause (likely from the lock screen controls managed
  // by the MediaSession class).
  useEffect(() =>
    videoPlayer.on(Event.PlayRequest, () => {
      videoElement?.play()
    }),
  )
  useEffect(() =>
    videoPlayer.on(Event.PauseRequest, () => {
      videoElement?.pause()
    }),
  )

  // Burn the video element to the ground before it's removed from the dom to
  // prevent disembodied audio playback
  useEffect(() => {
    return videoPlayer.on(Event.WillSuspend, async () => {
      if (videoElement) {
        videoElement.pause()
        videoElement.removeAttribute("autoplay")
        videoElement.removeAttribute("src")
        videoElement.load()
      }
    })
  }, [videoElement, videoPlayer])

  return track ? (
    <PlayerOverlay videoPlayer={videoPlayer} dimensions={dimensions}>
      <video
        ref={setVideoElement}
        controls
        src={
          // If we're playing an Hls stream and the client doesn't support it
          // natively then we'll use load the uri dynamically with hls.js
          isHlsUrl(track.url) && !isHlsSupportedNatively
            ? undefined
            : queryString.stringifyUrl(track)
        }
        playsInline
        autoPlay={autoPlay}
        width={dimensions.width}
        height={dimensions.height}
        style={videoStyle}
        crossOrigin={track.cors}
      />
    </PlayerOverlay>
  ) : (
    <Poster source={{ uri: poster }} onPress={onPressPoster} />
  )
}
