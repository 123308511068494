"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const color_1 = require("@treefort/lib/color");
exports.default = {
    colors: {
        transparent: "transparent",
        white: "#ffffff",
        black: "#000000",
        gray50: "#eeeeee",
        gray100: "#dddddd",
        gray200: "#d2d2d2",
        gray300: "#bbbbbb",
        gray400: "#a4a4a4",
        gray500: "#8d8d8d",
        gray600: "#757575",
        gray700: "#606060",
        gray800: "#494949",
        gray900: "#323232",
        gray920: "#272727",
        gray950: "#131313",
        red500: "#ef4444",
        green100: "#eaffe9",
        green500: "#5aac52",
        blue100: "#dae9f4",
        blue500: "#0d99ff",
        treefort: "#728c7d",
    },
    spacing: {
        none: 0,
        xtiny: 2,
        tiny: 4,
        xsmall: 8,
        small: 12,
        medium: 16,
        large: 24,
        xlarge: 36,
        jumbo: 48,
    },
    borderRadius: {
        square: 0,
        rounded: 4,
        roundedMedium: 6,
        roundedLarge: 8,
        roundedFull: 999,
    },
    shadows: {
        text: {
            color: (0, color_1.colorWithAlpha)("#000000", 0.5),
            radius: 8,
            offsetX: 1,
            offsetY: 2,
        },
    },
    breakpoints: {
        desktop: 1024,
    },
    aspectRatios: {
        tall: 0.7,
        wide: 1.75,
        circle: 1,
        square: 1,
    },
    minTapTarget: 48,
    maxPageWidth: 1200,
};
